import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CallsHorizontalSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M16.71 16.916a2.715 2.715 0 0 1-2.718 2.712H6.29a2.715 2.715 0 0 1-2.718-2.712V4.712h10.42a2.715 2.715 0 0 1 2.718 2.712v9.492zM51.635 4.775H48.81v13.54h2.824V4.775zM28.988 13.42c0 1.36 1.001 2.397 2.343 2.397v-.001c.941 0 1.763-.559 1.963-1.319h2.863c-.22 2.338-2.323 4.016-4.826 4.016-2.904 0-5.107-2.178-5.107-5.094 0-2.917 2.203-5.114 5.107-5.114 2.463 0 4.546 1.678 4.806 3.935h-2.864c-.2-.718-1-1.238-1.942-1.238-1.342 0-2.343 1.059-2.343 2.418zm12.295-5.115c1.38 0 2.442.579 3.123 1.598V8.506h2.824v9.809h-2.824v-1.398c-.68 1.019-1.742 1.598-3.123 1.598-2.544 0-4.566-2.297-4.566-5.114 0-2.817 2.022-5.095 4.566-5.095zM39.48 13.4c0 1.359 1.1 2.417 2.463 2.417 1.38 0 2.462-1.058 2.462-2.417 0-1.36-1.102-2.418-2.462-2.418-1.361 0-2.463 1.059-2.463 2.418zm13.755-8.625h2.824v13.54h-2.824V4.775zm6.789 6.548c0-.46.48-.68 1.12-.68.901 0 1.442.44 1.483.94h2.843c-.24-2.177-2.143-3.277-4.265-3.277-2.423 0-3.906 1.399-3.906 3.217 0 2.195 1.993 2.554 3.585 2.84 1.06.191 1.942.35 1.942.997 0 .459-.48.739-1.322.739-.94 0-1.641-.439-1.702-1.1h-2.704c.2 2.219 1.982 3.517 4.546 3.517 2.222 0 3.924-1.378 3.924-3.176 0-2.546-2.143-2.892-3.765-3.154-.988-.16-1.782-.288-1.782-.862h.003v-.001zM21.694 8.554a3.62 3.62 0 0 0-3.625 3.616 3.62 3.62 0 0 0 3.625 3.616V8.554z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 69, height: 24 },
);
const CallsHorizontal = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CallsHorizontalSvg} {...props} />
));
CallsHorizontal.displayName = 'CallsHorizontal';
export default CallsHorizontal;
