import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ApplicationsFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m3.019 5.955.404.2.024-.05L12 10.383l8.553-4.276.016.033.392-.194a20 20 0 0 0-17.942.01zM22 7.662l-9 4.459V22.5l9-4.5V7.662zM11 22.5V12.122L2 7.683V18l9 4.5z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ApplicationsFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ApplicationsFilledSvg} {...props} />
));
ApplicationsFilled.displayName = 'ApplicationsFilled';
export default ApplicationsFilled;
