import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrEngageSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g fillRule="evenodd" clipPath="url(#br-en-a)" clipRule="evenodd">
        <path d="M21 21H3v-1.5h18V21z" />
        <path d="M19 3H3v13a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm-7.544 10.496L12 14l.544-.5.014-.013c1.924-1.778 3.192-2.95 3.192-4.389C15.75 7.923 14.842 7 13.687 7A2.24 2.24 0 0 0 12 7.808 2.24 2.24 0 0 0 10.312 7C9.159 7 8.25 7.923 8.25 9.098c0 1.44 1.273 2.615 3.201 4.394l.005.004z" />
      </g>
      <defs>
        <clipPath id="br-en-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrEngage = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrEngageSvg} {...props} />
));
BrEngage.displayName = 'BrEngage';
export default BrEngage;
