import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const SketchColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="#EA6C00" d="M4.86 9.845 12 24 0 9.845h4.86zM19.14 9.845 12 24 24 9.845h-4.86z" />
      <path fill="#FDAD00" d="M4.86 9.845h14.28L12 24 4.86 9.845z" />
      <path fill="#FDD231" d="m12 2-6.764.724-.376 7.12L12 2zM12 2l6.764.724.376 7.12L12 2z" />
      <path fill="#FDAD00" d="m24 9.845-5.236-7.12.375 7.12H24zM0 9.845l5.236-7.12-.375 7.12H0z" />
      <path fill="#FEEEB7" d="M12 2 4.86 9.845h14.28L12 2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const SketchColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={SketchColoredSvg} {...props} />
));
SketchColored.displayName = 'SketchColored';
export default SketchColored;
