import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CheckboxSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 3H3v18h15.5a2.5 2.5 0 002.5-2.5V3zM10.727 15.834l6.962-6.614-1.378-1.45-5.669 5.386-3-2.511-1.284 1.534 4.369 3.655z"
        fill="#0D0D0D"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Checkbox = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CheckboxSvg} {...props} />
));

Checkbox.displayName = 'Checkbox';
export default Checkbox;
