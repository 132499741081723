import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrGlobalCitizenshipSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-gl-a)">
        <path d="M5.575 2.58 4.995 2l-1.06 1.06.655.656c-3.08 4.113-2.75 9.97.988 13.71a10.452 10.452 0 0 0 5.675 2.93v1.141h-3.25v1.5h8v-1.5h-3.25v-.999a10.475 10.475 0 0 0 6.54-2.088l.65.65 1.061-1.061-1.628-1.629-.002.002-.007-.007A9 9 0 1 1 6.639 3.637l-1.06-1.06-.004.003z" />
        <path d="M18.306 15.305A7.5 7.5 0 1 1 7.7 4.698a7.5 7.5 0 0 1 10.606 10.607z" />
      </g>
      <defs>
        <clipPath id="br-gl-a">
          <path d="M0 0H24V24H0z" transform="translate(.003)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrGlobalCitizenship = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrGlobalCitizenshipSvg} {...props} />
));
BrGlobalCitizenship.displayName = 'BrGlobalCitizenship';
export default BrGlobalCitizenship;
