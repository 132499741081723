import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ListDescendingSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M8.793 15.793 7 17.586V4H5v13.586l-1.793-1.793-1.414 1.414L6 21.414l4.207-4.207-1.414-1.414zM12 8h10V6H12v2zM19 13h-7v-2h7v2zM12 18h4v-2h-4v2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ListDescending = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ListDescendingSvg} {...props} />
));
ListDescending.displayName = 'ListDescending';
export default ListDescending;
