import type { ButtonHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

import * as Icons from '@feather/components/icons';
import cssVariables from '@feather/theme/cssVariables';
import type { DropdownItem, DropdownProps, DropdownSize, DropdownToggleTheme, DropdownVariant } from '@feather/types';

import { Tooltip } from '../tooltip';
import { DropdownToggleIcon } from './DropdownToggleIcon';
import { defaultTransition } from './constants';
import { generateToggleArrowStyle, generateToggleContainerSizeStyle, getToggleColors } from './styleGenerators';

type ToggleContainerProps = {
  size: DropdownSize;
  width?: DropdownProps<DropdownItem>['width'];
  variant: DropdownVariant;
  hasError?: boolean;
  readOnly?: boolean;

  styles?: SimpleInterpolation;
  toggleTheme?: DropdownToggleTheme;

  isPlaceholder?: boolean;
};

type Props = Pick<DropdownProps<any>, 'showArrow' | 'tooltipProps'> &
  ToggleContainerProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

const getWidthCss = (width: Props['width']) => {
  if (width) {
    if (typeof width === 'number') {
      return css`
        width: ${width}px;
      `;
    }
    return css`
      width: ${width};
    `;
  }
  return null;
};

const InlineTooltip = styled(Tooltip)<{ $width?: Props['width'] }>`
  display: inline-block;
  ${(props) => getWidthCss(props.$width)};
`;

const ToggleArrow = styled(DropdownToggleIcon)<{
  dropdownSize: DropdownSize;
  variant: DropdownVariant;
}>`
  margin-left: 4px;
  ${generateToggleArrowStyle};
`;

const ToggleButton = styled.button<
  { $width?: Props['width'] } & Pick<
    Props,
    'variant' | 'toggleTheme' | 'isPlaceholder' | 'readOnly' | 'size' | 'hasError' | 'styles'
  >
>`
  ${(props) => getWidthCss(props.$width)};

  ${(props) => generateToggleContainerSizeStyle(props)};

  ${({ variant, toggleTheme, isPlaceholder = false, readOnly = false }) => {
    const {
      contentColor,
      hoverContentColor = contentColor,
      activeContentColor = contentColor,
      pressedContentColor = contentColor,
      disabledContentColor,
      bgColor,
      hoverBgColor = bgColor,
      activeBgColor,
      pressedBgColor = activeBgColor,
      disabledBgColor,
      borderColor,
      hoverBorderColor = borderColor,
      activeBorderColor = borderColor,
      pressedBorderColor = borderColor,
      disabledBorderColor,
      readOnlyBgColor,
      readOnlyContentColor,
      readOnlyArrowColor,
      focusOutlineColor,
    } = getToggleColors(variant, toggleTheme);
    return css`
      position: relative;
      -webkit-appearance: none;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      ${isPlaceholder
        ? css`
            color: ${cssVariables('neutral-6')};
          `
        : css`
            color: ${contentColor};
          `};
      background-color: ${bgColor};
      border: 1px solid ${borderColor};
      border-radius: 4px;

      cursor: pointer;
      user-select: none;
      outline: 0;
      transition: ${defaultTransition.css};
      transition-property: color, background-color, border, box-shadow;

      ${DropdownToggleIcon} {
        fill: ${contentColor};
      }

      &:not([data-is-loading='true']) {
        &[aria-pressed='true'] {
          color: ${pressedContentColor};
          background-color: ${pressedBgColor};
          border: 1px solid ${pressedBorderColor};
          ${DropdownToggleIcon} {
            fill: ${pressedContentColor};
          }
        }

        &:hover:not([aria-pressed='true']):not(:disabled) {
          color: ${hoverContentColor};
          background-color: ${hoverBgColor};
          border: 1px solid ${hoverBorderColor};
          ${DropdownToggleIcon} {
            fill: ${hoverContentColor};
          }
        }

        &:active:not(:disabled) {
          color: ${activeContentColor};
          background-color: ${activeBgColor};
          border: 1px solid ${activeBorderColor};
        }

        &:focus:not(:disabled):not(:active) {
          box-shadow: 0 0 0 2px ${focusOutlineColor};
        }
      }

      &:disabled {
        background-color: ${disabledBgColor};
        border: 1px solid ${disabledBorderColor};
        color: ${disabledContentColor};
        cursor: not-allowed;
        ${DropdownToggleIcon} {
          fill: ${disabledContentColor};
        }
      }
      ${readOnly &&
      css`
        &:read-only {
          background-color: ${readOnlyBgColor};
          border: 1px solid ${readOnlyBgColor};
          color: ${readOnlyContentColor};
          cursor: text;
          ${DropdownToggleIcon} {
            fill: ${readOnlyArrowColor};
          }
        }
      `}
    `;
  }};

  ${(props) =>
    props.hasError &&
    css`
      border-color: ${cssVariables('border-negative')};
    `}
  &[aria-pressed='true'] {
    ${ToggleArrow} {
      transform: rotate(180deg);
    }
  }

  ${(props) => props.styles}
`;

export const DropdownToggleButton = forwardRef<HTMLButtonElement, Props>(
  ({ children: childrenProp, showArrow = true, tooltipProps, width, type = 'button', ...props }, ref) => {
    const { size, variant } = props;
    const children = (
      <>
        {childrenProp}
        {showArrow && <ToggleArrow icon={Icons.InputArrowDown} size={20} dropdownSize={size} variant={variant} />}
      </>
    );

    const buttonProps = { ref, type, ...props };

    if (tooltipProps) {
      return (
        <InlineTooltip popperProps={{ modifiers: { offset: { offset: '0, 8' } } }} $width={width} {...tooltipProps}>
          <ToggleButton $width="100%" {...buttonProps}>
            {children}
          </ToggleButton>
        </InlineTooltip>
      );
    }
    return (
      <ToggleButton $width={width} {...buttonProps}>
        {children}
      </ToggleButton>
    );
  },
);
