import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TogglesColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#6210CC"
        fillRule="evenodd"
        d="M7 13a5 5 0 0 0 0 10h10a5 5 0 0 0 0-10H7zm10 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
        clipRule="evenodd"
      />
      <path
        fill="#0D0D0D"
        fillRule="evenodd"
        d="M17 2H7a4 4 0 1 0 0 8h10a4 4 0 0 0 0-8zM7 1a5 5 0 0 0 0 10h10a5 5 0 0 0 0-10H7z"
        clipRule="evenodd"
      />
      <path fill="#0D0D0D" d="M10 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const TogglesColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TogglesColoredSvg} {...props} />
));
TogglesColored.displayName = 'TogglesColored';
export default TogglesColored;
