import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CopyToSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g>
        <path
          fillRule="evenodd"
          d="M22.499 2.3v16.8a2.4 2.4 0 0 1-2.4 2.4h-11.4a2.4 2.4 0 0 1-2.4-2.4V15h1.8v4.1a.6.6 0 0 0 .6.6h11.4a.6.6 0 0 0 .6-.6v-15h-12a.6.6 0 0 0-.6.6V9h-1.8V4.7a2.4 2.4 0 0 1 2.4-2.4h13.8zm-5.5 9.702L16.997 12l.002-.002-4.242-4.248-1.415 1.416 1.828 1.83H2v2.002h11.177l-1.834 1.836 1.415 1.416 4.242-4.248z"
          clipRule="evenodd"
        />
      </g>
    </svg>
  )),
  { width: 24, height: 24 },
);
const CopyTo = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CopyToSvg} {...props} />
));
CopyTo.displayName = 'CopyTo';
export default CopyTo;
