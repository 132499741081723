import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const WhatsappSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m8.789 7.456.647.035c.127.006.24.084.29.2l.943 2.182c.05.117.03.252-.055.347l-.82.94a.243.243 0 0 0-.032.273c1.081 2.06 3.051 2.875 3.64 3.08.1.035.21.002.276-.082l.858-1.1a.338.338 0 0 1 .406-.1l2.188.998a.33.33 0 0 1 .187.37l-.103.478a2.067 2.067 0 0 1-.91 1.312c-.592.375-1.36.559-2.336.286-3.029-.847-4.742-2.83-5.66-4.07-.92-1.24-1.275-2.48-1.045-3.492.154-.679.656-1.2.97-1.47a.788.788 0 0 1 .556-.187z" />
      <path
        fillRule="evenodd"
        d="m2 22 1.4-4.97a9.937 9.937 0 0 1-1.374-5.054c0-5.5 4.48-9.976 9.987-9.976S22 6.475 22 11.976c0 5.501-4.48 9.977-9.987 9.977A9.983 9.983 0 0 1 7.13 20.68L2 22zm5.393-3.134.305.186a8.283 8.283 0 0 0 4.315 1.208c4.573 0 8.293-3.716 8.293-8.284 0-4.567-3.72-8.284-8.293-8.284-4.573 0-8.293 3.717-8.293 8.284 0 1.592.453 3.137 1.31 4.47l.205.32-.806 2.862 2.964-.762z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Whatsapp = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={WhatsappSvg} {...props} />
));
Whatsapp.displayName = 'Whatsapp';
export default Whatsapp;
