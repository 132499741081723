import type { DependencyList, MutableRefObject, ReactNode, Ref } from 'react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import type { DownshiftProps } from 'downshift';
import Downshift from 'downshift';
import type { PopperChildrenProps } from 'react-popper';
import { Manager, Popper, Reference } from 'react-popper';
import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

import { transitionDefault, transitions } from '@feather/animation';
import { IconButton } from '@feather/components/button';
import { DropdownToggleIcon } from '@feather/components/dropdown';
import { getToggleColors } from '@feather/components/dropdown/styleGenerators';
import * as Icons from '@feather/components/icons';
import { Tree, type TreeData, type TreeHandlerRef, type TreeNodeItem, type TreeProps } from '@feather/components/tree';
import { checkIsAllNodesSelected, constructTreeNodes } from '@feather/components/tree/utils';
import { elevation } from '@feather/elevation';
import { cssVariables } from '@feather/theme';
import type { DropdownSize, DropdownToggleTheme, DropdownVariant, ScrollBarRef } from '@feather/types';
import { Typography } from '@feather/typography';
import { ZIndexes } from '@feather/zIndexes';

import { ScrollBar } from '../ScrollBar';
import { SelectedNodes } from './selectedNodes';

type ToggleButtonProps = {
  size: DropdownSize;
  variant: DropdownVariant;
  toggleTheme?: DropdownToggleTheme;
  width?: string | number;
  styles?: SimpleInterpolation;
  hasError?: boolean;
};

type DownshiftStateReducer = DownshiftProps<TreeNodeItem>['stateReducer'];
type DownshiftItemToString = DownshiftProps<TreeNodeItem>['itemToString'];

export type TreeSelectProps = Omit<TreeProps, 'minWidth' | 'getItemProps' | 'getMenuProps'> & {
  width?: string | number;
  toggleButtonStyles?: SimpleInterpolation;
  prefixItem?: ReactNode;
  suffixItem?: ReactNode;
  disabled?: boolean;
  hideClearButton?: boolean;
  toggleRenderer?: (params: { isOpen: boolean }) => ReactNode;
  className?: string;
  selectedNodeMaxWidth?: number;
  buttonRef?: Ref<HTMLButtonElement>;
  menuRef?: Ref<HTMLDivElement>;
  error?: string;
  maxSelectedNodes?: number;
  poperScheduleUpdateDeps?: DependencyList;
};

const passRef = <T,>(node: T | null, ref?: Ref<T>) => {
  if (typeof ref === 'function') {
    (ref as Function)(node);
  } else if (ref) {
    (ref as MutableRefObject<T | null>).current = node;
  }
};

const stateReducer: DownshiftStateReducer = (_, changes) => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.clickItem: {
      const defaultIsOpenValue = true;

      return {
        ...changes,
        isOpen: defaultIsOpenValue,
      };
    }
    default:
      return changes;
  }
};

const itemToString: DownshiftItemToString = (item) => item?.label || '';

const ToggleButton = styled.div.attrs({ role: 'button', tabIndex: 0 })<ToggleButtonProps>`
  width: ${({ width }) => {
    if (width) {
      if (typeof width === 'number') {
        return `${width}px`;
      }
      return width;
    }
    return null;
  }};

  ${({ variant, toggleTheme }) => {
    const {
      contentColor,
      hoverContentColor = contentColor,
      activeContentColor = contentColor,
      pressedContentColor = contentColor,
      disabledContentColor,
      bgColor,
      hoverBgColor = bgColor,
      activeBgColor,
      pressedBgColor = activeBgColor,
      disabledBgColor,
      borderColor,
      hoverBorderColor = borderColor,
      activeBorderColor = borderColor,
      pressedBorderColor = borderColor,
      disabledBorderColor,
      focusOutlineColor,
    } = getToggleColors(variant, toggleTheme);

    return css`
      position: relative;
      -webkit-appearance: none;
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      background-color: ${bgColor};
      border: 1px solid ${borderColor};
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
      outline: 0;
      transition: ${transitions({
        duration: 0.2,
        properties: ['color', 'background-color', 'border', 'box-shadow'],
      })};

      ${DropdownToggleIcon} {
        fill: ${contentColor};
      }

      &:not([data-is-loading='true']) {
        &[aria-pressed='true'] {
          color: ${pressedContentColor};
          background-color: ${pressedBgColor};
          border: 1px solid ${pressedBorderColor};
          ${DropdownToggleIcon} {
            fill: ${pressedContentColor};
          }
        }

        &:hover:not([aria-pressed='true']):not(:disabled) {
          color: ${hoverContentColor};
          background-color: ${hoverBgColor};
          border: 1px solid ${hoverBorderColor};
          ${DropdownToggleIcon} {
            fill: ${hoverContentColor};
          }
        }

        &:active:not(:disabled) {
          color: ${activeContentColor};
          background-color: ${activeBgColor};
          border: 1px solid ${activeBorderColor};
        }

        &:focus:not(:disabled):not(:active) {
          box-shadow: 0 0 0 2px ${focusOutlineColor};
        }
      }

      &:disabled {
        background-color: ${disabledBgColor};
        border: 1px solid ${disabledBorderColor};
        color: ${disabledContentColor};
        cursor: not-allowed;
        ${DropdownToggleIcon} {
          fill: ${disabledContentColor};
        }
      }
    `;
  }}

  ${(props) =>
    props.hasError &&
    css`
      border-color: ${cssVariables('border-negative')};
    `}

  ${({ styles }) => styles}
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  min-height: 30px;
  padding: 0 8px;
`;

const ToggleContentWrapper = styled.div`
  flex: 1; // stretch horizontally
`;

const ToggleScrollBar = styled(ScrollBar)`
  width: 100%;
  max-height: 80px;
`;

const ToggleArrow = styled(DropdownToggleIcon)`
  padding-top: 2px;
  transition: 0.2s ${transitionDefault};
  transition-property: fill, transform;
`;

const ToggleActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
`;

const TreeMenu = styled.div<{ isOpen: boolean }>`
  position: relative;
  z-index: ${ZIndexes.dropdownMenu};
  max-height: 320px; // FIXME: 320 is a magic number
  overflow: hidden;
  background: white;
  border-radius: 4px;
  display: ${({ isOpen }) => !isOpen && 'none'};

  ${elevation.popover}
`;

const TreeMenuScrollBar = styled(ScrollBar)`
  max-height: 272px; // FIXME: 272 is a magic number
  padding: 8px 0;
`;

const ErrorMessage = styled.div`
  margin-top: 4px;
  color: ${cssVariables('content-negative')};
  ${Typography['caption-01']};
`;

export const TreeSelect = ({
  treeData,
  selectedNodes,
  treeExpandedKeys,
  disabled = false,
  error,
  treeDefaultExpandAll = false,
  initialExpandAll = false,
  toggleRenderer,
  prefixItem,
  suffixItem,
  width,
  toggleButtonStyles,
  onSelect,
  onTreeExpand,
  nodeToElement,
  className,
  selectedNodeMaxWidth,
  buttonRef: buttonRefProp,
  menuRef,
  hideClearButton,
  maxSelectedNodes,
  poperScheduleUpdateDeps = [],
}: TreeSelectProps) => {
  const constructedTreeNodes = useRef(constructTreeNodes(treeData));
  const treeHandlerRef = useRef<TreeHandlerRef>(null);
  const toggleContentScrollBarRef = useRef<ScrollBarRef>(null);
  const treeMenuScrollBarRef = useRef<ScrollBarRef>(null);
  const popperScheduleUpdateRef = useRef<PopperChildrenProps['scheduleUpdate'] | null>(null);

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [buttonWidth, setButtonWidth] = useState<number>(0);

  useLayoutEffect(() => {
    // when selectedNodes change, update the button width to adjust the width of menu items (Tree component)
    if (buttonWidth !== buttonRef.current?.clientWidth) {
      setButtonWidth(buttonRef.current?.clientWidth ?? 0);
    }
  }, [selectedNodes, buttonWidth]);

  const handleSelectedNodeRemove = (selectedNode: TreeData) => {
    const targetNode = constructedTreeNodes.current.find((node) => node.value === selectedNode.value);

    if (targetNode) {
      treeHandlerRef?.current?.handleSelect(targetNode);
    }
  };

  const handleClearButtonClick = (event) => {
    event.stopPropagation();
    onSelect([]);
  };

  const handleTreeExpand = (treeExpandKeys?: string[]) => {
    treeMenuScrollBarRef.current?.update();
    onTreeExpand?.(treeExpandKeys);
  };

  useEffect(() => {
    popperScheduleUpdateRef?.current?.();
  }, [selectedNodes, ...poperScheduleUpdateDeps]);

  useEffect(() => {
    toggleContentScrollBarRef?.current?.update();
  }, [selectedNodes]);

  useEffect(() => {
    constructedTreeNodes.current = constructTreeNodes(treeData);
  }, [treeData]);

  const hasError = !!error;

  return (
    <Manager>
      <Downshift itemToString={itemToString} stateReducer={stateReducer}>
        {({ isOpen, getItemProps, getMenuProps, getToggleButtonProps }) => (
          <div className={className}>
            <Reference
              innerRef={(node) => {
                passRef(node, buttonRefProp);
                buttonRef.current = node;
              }}
            >
              {({ ref }) => {
                // ToggleButton renders a div, so we need to remove type="button" attribute.
                const toggleButtonProps = getToggleButtonProps({ disabled });
                delete toggleButtonProps.type;

                return (
                  <ToggleButton
                    ref={ref}
                    size="small"
                    variant="default"
                    width={width}
                    aria-pressed={!!toggleRenderer && isOpen}
                    styles={toggleButtonStyles}
                    hasError={hasError}
                    {...toggleButtonProps}
                  >
                    <ToggleContainer>
                      <ToggleContentWrapper>
                        {toggleRenderer?.({ isOpen }) ?? (
                          <ToggleScrollBar ref={toggleContentScrollBarRef}>
                            <SelectedNodes
                              selectedNodes={selectedNodes}
                              onRemove={handleSelectedNodeRemove}
                              tagMaxWidth={selectedNodeMaxWidth}
                              disabled={disabled}
                            />
                          </ToggleScrollBar>
                        )}
                      </ToggleContentWrapper>
                      <ToggleActionsWrapper>
                        {!checkIsAllNodesSelected(constructedTreeNodes.current, selectedNodes) &&
                          selectedNodes.length > 0 &&
                          !disabled &&
                          !hideClearButton && (
                            <IconButton
                              icon={Icons.Close}
                              size="xsmall"
                              buttonType="tertiary"
                              iconSize={16}
                              onClick={handleClearButtonClick}
                            />
                          )}
                        <ToggleArrow icon={isOpen ? Icons.InputArrowUp : Icons.InputArrowDown} size={20} />
                      </ToggleActionsWrapper>
                    </ToggleContainer>
                  </ToggleButton>
                );
              }}
            </Reference>
            <Popper
              placement="bottom-start"
              innerRef={(node) => {
                passRef(node, menuRef);
              }}
            >
              {({ ref, style, scheduleUpdate }) => {
                popperScheduleUpdateRef.current = scheduleUpdate;
                return (
                  <TreeMenu ref={ref} style={style} isOpen={isOpen}>
                    {prefixItem}
                    <TreeMenuScrollBar>
                      <Tree
                        ref={treeHandlerRef}
                        treeData={treeData}
                        selectedNodes={selectedNodes}
                        treeExpandedKeys={treeExpandedKeys}
                        treeDefaultExpandAll={treeDefaultExpandAll}
                        initialExpandAll={initialExpandAll}
                        constructedTreeNodes={constructedTreeNodes.current}
                        minWidth={buttonWidth}
                        onSelect={onSelect}
                        maxSelectedNodes={maxSelectedNodes}
                        onTreeExpand={handleTreeExpand}
                        nodeToElement={nodeToElement}
                        getItemProps={getItemProps}
                        getMenuProps={getMenuProps}
                      />
                    </TreeMenuScrollBar>
                    {suffixItem}
                  </TreeMenu>
                );
              }}
            </Popper>
            {typeof error === 'string' && error && <ErrorMessage className="error">{error}</ErrorMessage>}
          </div>
        )}
      </Downshift>
    </Manager>
  );
};
