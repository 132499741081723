import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const MobileApplicationSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M4 19.5A2.5 2.5 0 0 0 6.5 22h11a2.5 2.5 0 0 0 2.5-2.5v-15A2.5 2.5 0 0 0 17.5 2H4v17.5zM6 8v8h12V8H6zm12-2V5a1 1 0 0 0-1-1H6v2h12zm0 13.001a1 1 0 0 1-.998.999H7a1 1 0 0 1-1-1v-1h12v1.001z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const MobileApplication = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={MobileApplicationSvg} {...props} />
));
MobileApplication.displayName = 'MobileApplication';
export default MobileApplication;
