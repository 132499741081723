import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AvatarDottedSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M10.695 2.084a10.085 10.085 0 0 1 2.61 0l-.259 1.984a8.085 8.085 0 0 0-2.092 0l-.259-1.984zm5.133.675a9.989 9.989 0 0 1 2.26 1.307L16.87 5.652a7.99 7.99 0 0 0-1.808-1.045l.766-1.848zM5.912 4.066a9.99 9.99 0 0 1 2.26-1.307l.766 1.848A7.99 7.99 0 0 0 7.13 5.652L5.912 4.066zM2.759 8.172a9.99 9.99 0 0 1 1.307-2.26L5.652 7.13a7.99 7.99 0 0 0-1.045 1.808l-1.848-.766zm17.174-2.26a9.99 9.99 0 0 1 1.308 2.26l-1.848.766a7.987 7.987 0 0 0-1.045-1.808l1.585-1.218zM2 12c0-.442.029-.877.084-1.305l1.984.259a8.085 8.085 0 0 0 0 2.092l-1.984.259A10.085 10.085 0 0 1 2 12zm19.916-1.305a10.077 10.077 0 0 1 0 2.61l-1.984-.259a8.085 8.085 0 0 0 0-2.092l1.984-.259zm-17.85 7.393a9.989 9.989 0 0 1-1.307-2.26l1.848-.766a7.99 7.99 0 0 0 1.045 1.808l-1.586 1.218zm17.175-2.26a9.989 9.989 0 0 1-1.308 2.26l-1.585-1.218a7.985 7.985 0 0 0 1.045-1.808l1.848.766zM8.17 21.241a9.99 9.99 0 0 1-2.26-1.308l1.22-1.585c.55.423 1.157.776 1.807 1.045l-.766 1.848zm9.917-1.308a9.989 9.989 0 0 1-2.26 1.308l-.766-1.848a7.985 7.985 0 0 0 1.808-1.045l1.218 1.585zM12 22c-.442 0-.877-.029-1.305-.084l.259-1.984a8.085 8.085 0 0 0 2.092 0l.259 1.984c-.428.055-.863.084-1.305.084z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const AvatarDotted = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AvatarDottedSvg} {...props} />
));
AvatarDotted.displayName = 'AvatarDotted';
export default AvatarDotted;
