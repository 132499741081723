import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const Delete10MinsTwotoneSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M6 4.5A2.5 2.5 0 0 1 8.5 2H11v2H8.5a.5.5 0 0 0-.5.5V6H6V4.5zM11 7.5H4v.017H2v2h2V19.5A2.5 2.5 0 0 0 6.5 22h11a2.5 2.5 0 0 0 2.5-2.5V13h-2v6.5a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5V9.517h5V7.5z" />
      <path d="M11 12H9v6h2v-6zM13 13v5h2v-5h-2z" />
      <path
        fillRule="evenodd"
        d="M23 1H13v10h10V1zm-7.118 2.2h.896v5.6h-1.344V5.056l-1.232.92V4.368l1.68-1.168zM21.843 6c0 1.712-.832 2.88-2.24 2.88-1.408 0-2.24-1.168-2.24-2.88s.832-2.88 2.24-2.88c1.408 0 2.24 1.168 2.24 2.88zm-3.088 0c0 1.104.32 1.56.848 1.56s.848-.456.848-1.56-.32-1.56-.848-1.56-.848.456-.848 1.56z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Delete10MinsTwotone = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={Delete10MinsTwotoneSvg} {...props} />
));
Delete10MinsTwotone.displayName = 'Delete10MinsTwotone';
export default Delete10MinsTwotone;
