import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const PlatformApiSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2 3.122C2 2.226 2.73 1.5 3.631 1.5H20.37c.9 0 1.631.726 1.631 1.622v3.251c0 .896-.73 1.623-1.631 1.623H3.63C2.731 7.996 2 7.269 2 6.373v-3.25zm0 14.616c0-.896.73-1.623 1.631-1.623H20.37c.9 0 1.631.727 1.631 1.623v3.25c0 .897-.73 1.623-1.631 1.623H3.63c-.9 0-1.631-.726-1.631-1.622v-3.251zm1.631-8.93C2.73 8.808 2 9.534 2 10.43v3.251c0 .896.73 1.622 1.631 1.622H20.37c.902 0 1.631-.726 1.631-1.622v-3.25c0-.897-.73-1.623-1.631-1.623H3.63zm15.036-2.705c-.614 0-1.111-.533-1.111-1.19 0-.658.497-1.19 1.11-1.19.614 0 1.112.532 1.112 1.19 0 .657-.498 1.19-1.111 1.19zm0 7.143c-.614 0-1.111-.533-1.111-1.19 0-.658.497-1.19 1.11-1.19.614 0 1.112.532 1.112 1.19 0 .657-.498 1.19-1.111 1.19zm-1.111 5.952c0 .658.497 1.19 1.11 1.19.614 0 1.112-.532 1.112-1.19 0-.657-.498-1.19-1.111-1.19-.614 0-1.111.533-1.111 1.19z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const PlatformApi = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={PlatformApiSvg} {...props} />
));
PlatformApi.displayName = 'PlatformApi';
export default PlatformApi;
