import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const JavascriptSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M22 22H2V2h20v20zm-4.668-1.566c-1.745 0-2.874-.832-3.425-1.922l1.526-.887c.405.656.93 1.14 1.853 1.14.781 0 1.28-.39 1.28-.927 0-.645-.513-.872-1.37-1.246l-.47-.202c-1.356-.578-2.257-1.302-2.257-2.832 0-1.41 1.074-2.483 2.752-2.483 1.195 0 2.055.415 2.674 1.506l-1.463.939c-.323-.578-.67-.805-1.21-.805-.55 0-.899.348-.899.805 0 .564.35.793 1.156 1.141l.469.202c1.597.684 2.498 1.383 2.498 2.953 0 1.692-1.33 2.618-3.114 2.618zm-4.44-2.837c0 1.947-1.142 2.833-2.808 2.833-1.506 0-2.374-.78-2.819-1.717l1.53-.924c.296.522.564.965 1.21.965.618 0 1.006-.242 1.006-1.18v-6.392h1.88v6.415z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Javascript = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={JavascriptSvg} {...props} />
));
Javascript.displayName = 'Javascript';
export default Javascript;
