import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BotFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 .974a2.505 2.505 0 0 0-1 4.803v1.702h2V5.777a2.506 2.506 0 0 0-1-4.803zM.252 16.579v-6h2v6h-2zm21.5-6v6h2v-6h-2zm-3.5-3.1h-15v11.5a2.5 2.5 0 0 0 2.5 2.5h12.5a2.5 2.5 0 0 0 2.5-2.5v-9a2.5 2.5 0 0 0-2.5-2.5zm-8.467 4.475v3.25a1 1 0 1 1-2 0v-3.25a1 1 0 1 1 2 0zm6.484 0v3.25a1 1 0 1 1-2 0v-3.25a1 1 0 1 1 2 0z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const BotFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BotFilledSvg} {...props} />
));
BotFilled.displayName = 'BotFilled';
export default BotFilled;
