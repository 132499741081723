import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const SparkleColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="url(#spark-a)"
        d="m15.922 6.666.658-1.458 1.459-.658a.417.417 0 0 0 0-.759l-1.459-.658-.658-1.467a.417.417 0 0 0-.758 0l-.659 1.459-1.466.658a.417.417 0 0 0 0 .758l1.458.659.658 1.466c.15.325.617.325.767 0zm-6.633 1.25L7.964 5c-.292-.65-1.225-.65-1.517 0L5.122 7.916 2.205 9.241c-.65.3-.65 1.225 0 1.517l2.917 1.325L6.447 15c.3.65 1.225.65 1.517 0l1.325-2.917 2.916-1.325c.65-.3.65-1.225 0-1.517L9.29 7.916zm5.866 5.417-.658 1.458-1.458.659a.417.417 0 0 0 0 .758l1.458.658.658 1.467c.15.325.609.325.759 0l.658-1.458 1.467-.659a.417.417 0 0 0 0-.758L16.58 14.8l-.658-1.467a.423.423 0 0 0-.767 0z"
      />
      <defs>
        <linearGradient id="spark-a" x1={10} x2={10} y1={1.423} y2={18.577} gradientUnits="userSpaceOnUse">
          <stop stopColor="#6210CC" />
          <stop offset={1} stopColor="#7090FB" />
        </linearGradient>
      </defs>
    </svg>
  )),
  { width: 20, height: 20 },
);
const SparkleColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={SparkleColoredSvg} {...props} />
));
SparkleColored.displayName = 'SparkleColored';
export default SparkleColored;
