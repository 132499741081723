import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TextButtonSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M7 7h10v2h-4v8h-2V9H7V7z" />
      <path fillRule="evenodd" d="M21 3H3v18h15a3 3 0 0 0 3-3V3zM5 19V5h14v13a1 1 0 0 1-1 1H5z" clipRule="evenodd" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const TextButton = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TextButtonSvg} {...props} />
));
TextButton.displayName = 'TextButton';
export default TextButton;
