import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const NoAutomationSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M8.83 4a3.001 3.001 0 1 0 0 2h8.825a2.346 2.346 0 1 1 0 4.691H17v2h.655a4.346 4.346 0 0 0 0-8.691H8.83zM6 4a1 1 0 1 1 0 2 1 1 0 0 1 0-2zM7 12.691v-2h-.348a4.654 4.654 0 1 0 0 9.309h8.52A3.001 3.001 0 0 0 21 19a3 3 0 0 0-5.829-1H6.652a2.654 2.654 0 1 1 0-5.309H7zM19 19a1 1 0 1 0-2 0 1 1 0 0 0 2 0z"
        clipRule="evenodd"
      />
      <path d="m10.586 12-2.293 2.293 1.415 1.414L12 13.414l2.293 2.293 1.414-1.414L13.415 12l2.293-2.293-1.415-1.414-2.292 2.293-2.293-2.293-1.414 1.414L10.586 12z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const NoAutomation = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={NoAutomationSvg} {...props} />
));
NoAutomation.displayName = 'NoAutomation';
export default NoAutomation;
