import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CallsColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#6210CC"
        fillRule="evenodd"
        d="M18.6 12c0-2.41 1.97-4.364 4.4-4.364V12h-4.4zM18.6 12c0 2.41 1.97 4.364 4.4 4.364V12h-4.4z"
        clipRule="evenodd"
      />
      <path
        fill="#6210CC"
        d="M13.65 21c1.822 0 3.3-1.465 3.3-3.273V6.273C16.95 4.465 15.473 3 13.65 3H1v14.727C1 19.535 2.477 21 4.3 21h9.35z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const CallsColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CallsColoredSvg} {...props} />
));
CallsColored.displayName = 'CallsColored';
export default CallsColored;
