import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LocationSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g fillRule="evenodd" strokeWidth={1}>
        <g>
          <path d="M17.226 19.813a31.132 31.132 0 0 1-4.254 3.726c-.195.141-.337.24-.417.293a1 1 0 0 1-1.11 0c-.08-.054-.222-.152-.417-.293a31.132 31.132 0 0 1-4.254-3.726C3.802 16.676 2 13.372 2 10 2 4.477 6.477 0 12 0s10 4.477 10 10c0 3.372-1.802 6.676-4.774 9.813zm-4.443 1.358a29.155 29.155 0 0 0 2.991-2.734C18.427 15.637 20 12.753 20 10a8 8 0 1 0-16 0c0 2.753 1.573 5.636 4.226 8.437A29.155 29.155 0 0 0 12 21.773c.242-.18.505-.38.783-.602zM12 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
        </g>
      </g>
    </svg>
  )),
  { width: 24, height: 24 },
);
const Location = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LocationSvg} {...props} />
));
Location.displayName = 'Location';
export default Location;
