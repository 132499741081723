import type { FC, MouseEvent, MouseEventHandler } from 'react';
import { useCallback } from 'react';
import { createPortal } from 'react-dom';

import type { PropGetters } from 'downshift';
import Downshift from 'downshift';
import { Manager, Popper, Reference } from 'react-popper';
import styled from 'styled-components';

import { transitions } from '@feather/animation';
import * as Icons from '@feather/components/icons';
import { elevation } from '@feather/elevation';
import type { OverflowMenuDivider, OverflowMenuItemInfo, OverflowMenuProps } from '@feather/types';
import { ZIndexes } from '@feather/zIndexes';

import { IconButton } from '../button';
import { Tooltip } from '../tooltip';
import { OverflowMenuItem } from './OverflowMenuItem';

const MenuList = styled.ul<{ isOpen: boolean }>`
  margin: 0;
  padding: 0;
  min-width: 128px;
  padding: ${({ isOpen }) => (isOpen ? '8px 0' : 0)};
  border-radius: 4px;
  background-color: white;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  ${elevation.popover}
  z-index: ${ZIndexes.dropdownMenu};
  list-style: none;
  transition: ${transitions({ duration: 0.2, properties: ['opacity'] })};
`;

const onDownshiftSelect = (selectedItem: OverflowMenuItemInfo | null) => {
  if (selectedItem?.onClick) {
    selectedItem.onClick();
  }
};

type OverflowMenu = FC<OverflowMenuProps> & { divider: OverflowMenuDivider };

type OverflowPopperProps = {
  isOpen: boolean;
  highlightedIndex: number | null;
  portalId?: string;
} & Pick<PropGetters<OverflowMenuItemInfo>, 'getMenuProps' | 'getItemProps'>;

const defaultIconButtonProps: NonNullable<OverflowMenuProps['iconButtonProps']> = {
  icon: Icons.More,
  size: 'small',
  buttonType: 'tertiary',
};

export const OverflowMenu: OverflowMenu = ({
  items,
  iconButtonProps,
  popperPortalId: portalId,
  popperProps,
  onStateChange,
  className,
  stopClickEventPropagation = false,
  'aria-label': ariaLabel,
}) => {
  const { onClick: iconButtonPropsOnClick, ...restIconButtonProps } = iconButtonProps || {};
  const handleMenuListClick = useCallback(
    (e: MouseEvent<HTMLUListElement>) => {
      stopClickEventPropagation && e.stopPropagation();
    },
    [stopClickEventPropagation],
  );

  const getPopper = useCallback(
    ({ isOpen, highlightedIndex, getItemProps, getMenuProps }: OverflowPopperProps) => {
      if (!isOpen) {
        return null;
      }

      const { ref: menuPropsRef, ...menuProps } = getMenuProps();

      return (
        <Popper placement="bottom-end" innerRef={menuPropsRef} {...popperProps}>
          {({ ref, style }) => {
            return (
              <MenuList isOpen={isOpen} {...menuProps} ref={ref} style={style} onClick={handleMenuListClick}>
                {isOpen &&
                  items.map((item, index) => {
                    if ('isDivider' in item) {
                      return null;
                    }

                    const { label, variant, href, target, useReactRouterLink, disabled } = item;
                    const key = `menu#${index}`;
                    const isPreviousItemDivider = index > 0 && 'isDivider' in items[index - 1];

                    const { onClick: originalOnClick, ...itemProps } = getItemProps({
                      key,
                      index,
                      item,
                    });
                    const onClick: MouseEventHandler = stopClickEventPropagation
                      ? (event) => {
                          event.stopPropagation();
                          originalOnClick(event);
                        }
                      : originalOnClick;

                    const menuItem = (
                      <OverflowMenuItem
                        key={key}
                        variant={variant}
                        href={href}
                        target={target}
                        useReactRouterLink={useReactRouterLink}
                        disabled={disabled}
                        itemProps={itemProps}
                        $isHighlighted={highlightedIndex === index}
                        onClick={onClick}
                        $isPreviousItemDivider={isPreviousItemDivider}
                      >
                        {label}
                      </OverflowMenuItem>
                    );

                    if (item.tooltip) {
                      return (
                        <Tooltip key={key} {...item.tooltip}>
                          {menuItem}
                        </Tooltip>
                      );
                    }
                    return menuItem;
                  })}
              </MenuList>
            );
          }}
        </Popper>
      );
    },
    [handleMenuListClick, items, popperProps, stopClickEventPropagation],
  );

  const renderPopper = useCallback(
    (props: OverflowPopperProps) => {
      const popper = getPopper(props);
      if (!portalId) {
        return popper;
      }

      const portalContainer = document.getElementById(portalId);
      return portalContainer ? createPortal(popper, portalContainer) : popper;
    },
    [getPopper, portalId],
  );

  return (
    <Downshift
      itemToString={(item) => (item ? item.label : '')}
      onSelect={onDownshiftSelect}
      onStateChange={onStateChange}
    >
      {({ getItemProps, getMenuProps, isOpen, highlightedIndex, getToggleButtonProps }) => {
        return (
          <div className={className}>
            <Manager>
              <Reference>
                {({ ref }) => {
                  const { onClick: originalOnClick, ...toggleButtonProps } = getToggleButtonProps(
                    ariaLabel ? { 'aria-label': ariaLabel } : undefined,
                  );
                  const onClick: MouseEventHandler<HTMLButtonElement> = (event) => {
                    if (stopClickEventPropagation) {
                      event.stopPropagation();
                    }
                    originalOnClick(event);
                    iconButtonPropsOnClick?.(event);
                  };

                  return (
                    <IconButton
                      ref={ref}
                      aria-pressed={isOpen}
                      {...defaultIconButtonProps}
                      {...restIconButtonProps}
                      onClick={onClick}
                      {...toggleButtonProps}
                    />
                  );
                }}
              </Reference>
              {renderPopper({ isOpen, highlightedIndex, getMenuProps, getItemProps })}
            </Manager>
          </div>
        );
      }}
    </Downshift>
  );
};

OverflowMenu.divider = Object.freeze({ isDivider: true }) as OverflowMenuDivider;
