import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TeamsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M9 12a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"
        clipRule="evenodd"
      />
      <path d="M8 22a4 4 0 0 1 8 0h2a6 6 0 0 0-12 0h2z" />
      <path
        fillRule="evenodd"
        d="M13 5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"
        clipRule="evenodd"
      />
      <path d="M15.382 9.031c.49.558.844 1.238 1.01 1.988A4 4 0 0 1 20 15h2a6 6 0 0 0-6.618-5.969z" />
      <path
        fillRule="evenodd"
        d="M5 5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"
        clipRule="evenodd"
      />
      <path d="M7.607 11.019c.167-.75.521-1.43 1.011-1.988A6 6 0 0 0 2 15h2a4 4 0 0 1 3.607-3.981z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Teams = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TeamsSvg} {...props} />
));
Teams.displayName = 'Teams';
export default Teams;
