import type { CSSVariableKey, FeatherColorSet, FeatherThemeCSSVariables } from '@feather/types';

import getCssVariable from './getCssVariable';
import { walkObject } from './walkObject';

interface CreateThemeVariablesOptions {
  cssVariablePrefix?: string;
  excludedKeys?: string[];
}

type ThemeVariables = {
  cssVariables: FeatherThemeCSSVariables;
  cssColors: any;
};

const createThemeVariables: (params: {
  target: Partial<FeatherColorSet>;
  options?: CreateThemeVariablesOptions;
}) => ThemeVariables = (params) => {
  const { target, options = {} } = params;
  const { excludedKeys = [], cssVariablePrefix = '' } = options;
  const context = {
    cssVariables: {},
    cssColors: {},
  };

  walkObject({
    target,
    predicate: (value, path) => {
      const colorKey = path.filter((key) => !excludedKeys.includes(key)).join('-') as CSSVariableKey;
      const { reference } = getCssVariable({ name: colorKey, cssVariablePrefix });
      context.cssVariables[colorKey] = reference;
      context.cssColors[colorKey] = value;
    },
  });

  return context as ThemeVariables;
};

export default createThemeVariables;
