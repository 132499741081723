import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const RemoveTwotoneSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <circle id="Color_02" cx={12} cy={12} r={10} />
      <path
        id="Color_01"
        d="m10.46 12.313-2.292 2.292 1.414 1.415 2.293-2.293 2.293 2.293 1.414-1.415-2.293-2.293 2.293-2.292-1.414-1.415-2.293 2.293-2.293-2.293-1.414 1.415 2.293 2.293z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const RemoveTwotone = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={RemoveTwotoneSvg} {...props} />
));
RemoveTwotone.displayName = 'RemoveTwotone';
export default RemoveTwotone;
