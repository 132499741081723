import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AdminMessageFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M5 2a3 3 0 0 0-3 3v17.414L6.414 18H14v-8h8V5a3 3 0 0 0-3-3H5z" />
      <path
        fillRule="evenodd"
        d="M23 17.034c0 1.06-1.03 2.054-1.893 2.701-.775.58-1.56.992-1.857 1.14a12.737 12.737 0 0 1-1.857-1.14c-.863-.647-1.893-1.64-1.893-2.7v-4.373l3.75-1.162L23 12.662v4.372zm-4.852.436.21-1.227-.891-.87 1.232-.178.551-1.117.551 1.117 1.232.179-.891.869.21 1.227-1.102-.58-1.102.58z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const AdminMessageFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AdminMessageFilledSvg} {...props} />
));
AdminMessageFilled.displayName = 'AdminMessageFilled';
export default AdminMessageFilled;
