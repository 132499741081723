import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const NotificationFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M13 3a1 1 0 1 0-2 0v1.082A5.994 5.994 0 0 0 6.01 9.996c0 1.861-.502 3.689-1.453 5.29L3.242 17.5h17.52l-1.326-2.22a10.306 10.306 0 0 1-1.458-5.284 5.994 5.994 0 0 0-4.979-5.912V3zM11 19a1 1 0 1 0 2 0h2a3 3 0 1 1-6 0h2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const NotificationFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={NotificationFilledSvg} {...props} />
));
NotificationFilled.displayName = 'NotificationFilled';
export default NotificationFilled;
