import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const GroupCallSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M21 7a4 4 0 1 1-8 0 4 4 0 0 1 8 0zm-6 0a2 2 0 1 1 4 0 2 2 0 0 1-4 0zM3 17a4 4 0 1 1 8 0 4 4 0 0 1-8 0zm4-2a2 2 0 1 1 0 4 2 2 0 0 1 0-4zM11 11V3H3v8h8zM5 9h4V5H5v4zM13 21h8v-8h-8v8zm2-2h4v-4h-4v4z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const GroupCall = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={GroupCallSvg} {...props} />
));
GroupCall.displayName = 'GroupCall';
export default GroupCall;
