import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const VideoTwotoneSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path id="Color_02" fillRule="evenodd" d="M17 4H2v16h15v-4.92l5 4V4.92l-5 4V4z" clipRule="evenodd" />
      <path id="Color_01" d="m12.5 12-5 3V9l5 3z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const VideoTwotone = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={VideoTwotoneSvg} {...props} />
));
VideoTwotone.displayName = 'VideoTwotone';
export default VideoTwotone;
