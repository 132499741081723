import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DeleteSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M8.5 2A2.5 2.5 0 0 0 6 4.5V6h2V4.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5V6h2V4.5A2.5 2.5 0 0 0 15.5 2h-7zM13 18v-6h2v6h-2zM9 12v6h2v-6H9z" />
      <path
        fillRule="evenodd"
        d="M4 7.5h16v.016h2v2h-2V19.5a2.5 2.5 0 0 1-2.5 2.5h-11A2.5 2.5 0 0 1 4 19.5V9.516H2v-2h2V7.5zm14 2.016H6V19.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V9.516z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Delete = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DeleteSvg} {...props} />
));
Delete.displayName = 'Delete';
export default Delete;
