import type { ButtonHTMLAttributes } from 'react';
import { forwardRef, useEffect, useRef, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { nanoid } from 'nanoid/non-secure';
import styled, { css } from 'styled-components';

import { transitionDefault } from '@feather/animation';
import cssVariables from '@feather/theme/cssVariables';
import { FeatherThemeEnum, useFeatherTheme } from '@feather/themes';
import type { ButtonContainerColorSet, ButtonType, IconButtonProps, IconButtonSize } from '@feather/types';
import mergeEventHandlers from '@feather/utils/mergeEventHandlers';

import { Spinner } from '../spinner';
import type { TooltipRef } from '../tooltip';
import { Tooltip, TooltipTrigger, TooltipVariant } from '../tooltip';
import * as hideOutlineEventListeners from './hideOutlineEventListeners';
import { generateButtonContainerStyle } from './styleGenerators';

type StyledButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isLoading: boolean;
  size: IconButtonProps['size'];
  buttonType: IconButtonProps['buttonType'];
  theme: FeatherThemeEnum;
};

const getSpinnerColor = (theme: FeatherThemeEnum, buttonType: ButtonType): string => {
  if (theme === FeatherThemeEnum.Gray) {
    return buttonType === 'tertiary' ? cssVariables('neutral-6') : cssVariables('neutral-9');
  }
  if (theme === FeatherThemeEnum.Neutral || theme === FeatherThemeEnum.Purple) {
    return 'white';
  }
  return cssVariables('purple-7');
};

let tooltipPortalId: string | undefined;

const getContainerStyleGeneratorOptions = (
  buttonType: ButtonType,
  theme: FeatherThemeEnum,
): ButtonContainerColorSet => {
  if (theme === FeatherThemeEnum.Gray) {
    const sharedStyle = {
      activeBgColor: cssVariables('neutral-5'),
      bgColor: 'transparent',
      borderColor: 'transparent',
      disabledBgColor: 'transparent',
      disabledBorderColor: 'transparent',
      hoverBgColor: cssVariables('neutral-3'),
      pressedBgColor: cssVariables('purple-3'),
      pressedContentColor: cssVariables('purple-7'),
    };

    switch (buttonType) {
      case 'tertiary':
        return {
          ...sharedStyle,
          activeContentColor: cssVariables('purple-9'),
          contentColor: cssVariables('neutral-7'),
          disabledContentColor: cssVariables('neutral-6'),
          hoverContentColor: cssVariables('neutral-7'),
        };
      default:
        return {
          ...sharedStyle,
          activeContentColor: cssVariables('purple-9'),
          contentColor: cssVariables('neutral-9'),
          disabledContentColor: cssVariables('neutral-5'),
          hoverContentColor: cssVariables('neutral-9'),
        };
    }
  }

  if (theme === FeatherThemeEnum.Neutral) {
    return {
      activeBgColor: cssVariables('neutral-7'),
      activeContentColor: 'white',
      bgColor: 'transparent',
      borderColor: 'transparent',
      contentColor: 'white',
      disabledBgColor: 'transparent',
      disabledBorderColor: 'transparent',
      disabledContentColor: cssVariables('neutral-6'),
      hoverBgColor: cssVariables('neutral-8'),
      hoverContentColor: 'white',
      pressedBgColor: cssVariables('neutral-10'),
      pressedContentColor: 'white',
    };
  }

  if (theme === FeatherThemeEnum.Purple) {
    return {
      activeBgColor: cssVariables('purple-9'),
      activeContentColor: 'white',
      bgColor: 'transparent',
      borderColor: 'transparent',
      contentColor: cssVariables('purple-3'),
      disabledBgColor: 'transparent',
      disabledBorderColor: 'transparent',
      disabledContentColor: cssVariables('purple-4'),
      hoverBgColor: cssVariables('purple-8'),
      hoverContentColor: 'white',
      pressedBgColor: cssVariables('purple-8'),
      pressedContentColor: 'white',
      focusOutlineColor: 'white',
    };
  }

  const sharedStyle = {
    disabledContentColor: cssVariables('content-disabled'),
    bgColor: 'transparent',
    hoverBgColor: cssVariables('bg-overlay-1'),
    activeBgColor: cssVariables('bg-overlay-2'),
    pressedBgColor: cssVariables('bg-primary-light'),
    disabledBgColor: 'transparent',
    borderColor: 'transparent',
    disabledBorderColor: 'transparent',
  };

  const contentColor =
    {
      primary: cssVariables('primary'),
      secondary: cssVariables('content-1'),
      tertiary: cssVariables('content-2'),
    }[buttonType] || cssVariables('content-2');

  return {
    ...sharedStyle,
    contentColor,
    pressedContentColor: contentColor,
    hoverContentColor: contentColor,
    activeContentColor: contentColor,
  };
};

const getIconSize = (iconSize: IconButtonSize | number) => {
  if (typeof iconSize === 'number') {
    return iconSize;
  }

  return {
    small: 20,
    xsmall: 16,
  }[iconSize];
};

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled.button<StyledButtonProps>`
  :disabled {
    cursor: ${(props) => (props.disabled && !props.isLoading ? 'not-allowed' : 'progress')};
  }

  ${({ size }) => {
    switch (size) {
      case 'xsmall':
        return css`
          width: 24px;
          height: 24px;
        `;
      case 'small':
      default:
        return css`
          width: 32px;
          height: 32px;
        `;
    }
  }}

  ${({ buttonType, theme }) => {
    const containerStyleGeneratorOptions = getContainerStyleGeneratorOptions(buttonType, theme);
    const { hoverContentColor, activeContentColor, pressedContentColor, disabledContentColor } =
      containerStyleGeneratorOptions;

    return [
      generateButtonContainerStyle(containerStyleGeneratorOptions),
      css`
        svg {
          transition: fill 0.2s ${transitionDefault};
        }

        :hover svg {
          fill: ${hoverContentColor};
        }

        :active svg {
          fill: ${activeContentColor};
        }

        &[aria-pressed='true'] svg {
          fill: ${pressedContentColor};
        }

        :disabled svg {
          fill: ${disabledContentColor};
        }
      `,
    ];
  }}

  ${(props) =>
    props.isLoading &&
    css`
      svg {
        fill: transparent !important;
      }
    `}
`;

const InlineBlockTooltip = styled(Tooltip)`
  display: inline-block;
`;

const IconButtonStyleable = Object.assign(
  forwardRef<HTMLButtonElement, IconButtonProps>(
    (
      {
        buttonType,
        size,
        icon: Icon,
        iconSize,
        isLoading = false,
        title,
        className,
        tooltipPlacement = 'top',
        type = 'button',
        isTooltipHoverable,
        onFocus,
        onBlur,
        onMouseDown,
        onMouseUp,
        onMouseEnter,
        onMouseLeave,
        ...rest
      },
      ref,
    ) => {
      const theme = useFeatherTheme();
      const buttonRef = useRef<HTMLButtonElement | null>(null);
      const tooltipRef = useRef<TooltipRef>(null);

      const showTooltip = () => {
        tooltipRef.current?.show();
      };
      const hideTooltip = () => {
        tooltipRef.current?.hide();
      };

      const handleFocus = mergeEventHandlers(onFocus, hideOutlineEventListeners.onFocus);
      const handleBlur = mergeEventHandlers(onBlur, hideTooltip);
      const handleMouseDown = mergeEventHandlers(onMouseDown, hideOutlineEventListeners.onMouseDown);
      const handleMouseUp = mergeEventHandlers(onMouseUp, hideOutlineEventListeners.onMouseUp);
      const handleMouseEnter = mergeEventHandlers(onMouseEnter, showTooltip);
      const handleMouseLeave = mergeEventHandlers(onMouseLeave, hideTooltip);

      useEffect(() => {
        const keyUpHandler = (event: KeyboardEvent) => {
          if (event.key === 'Tab' && buttonRef.current === document.activeElement) {
            // If the button is focused because the user pressed Tab, show the tooltip.
            showTooltip();
          }
        };

        window.addEventListener('keyup', keyUpHandler);
        return () => {
          window.removeEventListener('keyup', keyUpHandler);
        };
      }, []);

      const buttonProps: StyledButtonProps = {
        children: (
          <>
            {isLoading && (
              <SpinnerWrapper>
                <Spinner size={16} stroke={getSpinnerColor(theme, buttonType)} />
              </SpinnerWrapper>
            )}
            <Icon
              size={getIconSize(iconSize || size)}
              color={getContainerStyleGeneratorOptions(buttonType, theme).contentColor}
            />
          </>
        ),
        size,
        buttonType,
        isLoading,
        theme,
        type,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onMouseDown: handleMouseDown,
        onMouseUp: handleMouseUp,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        'aria-label': typeof title === 'string' ? title : undefined,
        ...rest,
      };

      const [tooltipId] = useState(() => `IconButton-${nanoid()}-tooltip`);

      const refCallback = (node: HTMLButtonElement | null) => {
        buttonRef.current = node;

        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          ref.current = node;
        }
      };

      if (isEmpty(title)) {
        return <StyledButton ref={refCallback} className={className} {...buttonProps} />;
      }

      return (
        <InlineBlockTooltip
          ref={tooltipRef}
          className={className}
          variant={TooltipVariant.Dark}
          content={title}
          placement={tooltipPlacement}
          portalId={tooltipPortalId}
          popperProps={{ modifiers: { offset: { offset: '0, 4' } } }}
          trigger={TooltipTrigger.Manual}
          tooltipId={tooltipId}
          isTooltipHoverable={isTooltipHoverable}
          tooltipContentStyle={
            isTooltipHoverable === false
              ? css`
                  pointer-events: none; // This option makes the tooltip not participate in event capturing and bubbling.
                `
              : {}
          }
        >
          <StyledButton ref={refCallback} aria-labelledby={tooltipId} {...buttonProps} />
        </InlineBlockTooltip>
      );
    },
  ),
  {
    /**
     * Call this function with the ID of a portal element before rendering any IconButton (for example, from the root
     * component module of the React app) to render tooltips of IconButtons in the portal element.
     */
    setTooltipPortalId: (id: string) => {
      tooltipPortalId = id || undefined;
    },
  },
);

export const IconButton = styled(IconButtonStyleable)``;
