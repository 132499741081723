import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const WarningSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M11 14.5V9h2v5.5h-2zM12 18.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5z" />
      <path fillRule="evenodd" d="M12 2 1 21h22L12 2zm0 3.992L4.469 19H19.53L12 5.992z" clipRule="evenodd" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Warning = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={WarningSvg} {...props} />
));
Warning.displayName = 'Warning';
export default Warning;
