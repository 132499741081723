import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const Delete5MinsTwotoneSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M6 4.5A2.5 2.5 0 0 1 8.5 2H11v2H8.5a.5.5 0 0 0-.5.5V6H6V4.5zM11 7.5H4v.017H2v2h2V19.5A2.5 2.5 0 0 0 6.5 22h11a2.5 2.5 0 0 0 2.5-2.5V13h-2v6.5a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5V9.517h5V7.5z" />
      <path d="M11 12H9v6h2v-6zM13 13v5h2v-5h-2z" />
      <path
        fillRule="evenodd"
        d="M23 1H13v10h10V1zm-2.959 5.896c0 1.104-.928 1.984-2.088 1.984-1.088 0-2.024-.632-2.08-1.808h1.352c.088.296.336.488.728.488.4 0 .696-.312.696-.696 0-.432-.296-.712-.696-.712-.288 0-.544.112-.688.288h-1.184l.248-3.24h3.328v1.272h-2.112l-.08.76c.168-.112.456-.192.696-.192 1.08 0 1.88.664 1.88 1.856z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Delete5MinsTwotone = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={Delete5MinsTwotoneSvg} {...props} />
));
Delete5MinsTwotone.displayName = 'Delete5MinsTwotone';
export default Delete5MinsTwotone;
