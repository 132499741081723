import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const MessagesSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#messa-a)">
        <path d="M6 16.5v-1h2v1a.5.5 0 0 0 .5.5h10.914l.586.586V9a.5.5 0 0 0-.5-.5H19v-2h.5A2.5 2.5 0 0 1 22 9v13.414L18.586 19H8.5A2.5 2.5 0 0 1 6 16.5z" />
        <path
          fillRule="evenodd"
          d="M2 4.5A2.5 2.5 0 0 1 4.5 2h11A2.5 2.5 0 0 1 18 4.5V12a2.5 2.5 0 0 1-2.5 2.5H5.414L2 17.914V4.5zM4.5 4a.5.5 0 0 0-.5.5v8.586l.586-.586H15.5a.5.5 0 0 0 .5-.5V4.5a.5.5 0 0 0-.5-.5h-11z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="messa-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const Messages = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={MessagesSvg} {...props} />
));
Messages.displayName = 'Messages';
export default Messages;
