import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const GoogleSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M15.939 7.342c-.726-.676-2-1.485-3.887-1.485-2.658 0-4.914 1.74-5.73 4.145a6.408 6.408 0 0 0-.324 1.973c0 .687.123 1.352.335 1.973.805 2.405 3.06 4.145 5.719 4.145 1.497 0 2.636-.41 3.485-.998 1.34-.93 1.943-2.316 2.055-3.236h-5.54v-3.702h9.438c.145.62.212 1.22.212 2.04 0 3.036-1.095 5.597-2.993 7.337-1.665 1.529-3.943 2.416-6.657 2.416a10.053 10.053 0 0 1-8.98-5.498A9.921 9.921 0 0 1 2 11.975c0-1.607.39-3.126 1.072-4.478A10.053 10.053 0 0 1 12.052 2c2.714 0 4.981.986 6.724 2.593l-2.837 2.75z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Google = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={GoogleSvg} {...props} />
));
Google.displayName = 'Google';
export default Google;
