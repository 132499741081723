import type { ReactElement } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { CalendarDate } from '@internationalized/date';
import { DateFormatter, endOfMonth, getLocalTimeZone, isSameMonth, startOfMonth, today } from '@internationalized/date';
import { useLocale } from '@react-aria/i18n';
import type { UseSelectState, UseSelectStateChange } from 'downshift';
import { useSelect } from 'downshift';
import type { PopperChildrenProps, PopperProps } from 'react-popper';
import { Manager, Popper, Reference } from 'react-popper';
import styled, { css } from 'styled-components';

import { Button } from '@feather/components/button';
import * as Icon from '@feather/components/icons';
import { cssVariables } from '@feather/theme';
import { Body, Typography } from '@feather/typography';
import { ZIndexes } from '@feather/zIndexes';

import type { DropdownSize } from '../../types/components/dropdown';
import { Calendar } from './calendar';
import { MonthIcon, MonthTarget, MonthTargetStart, MonthWrapper } from './components';

const MonthTargetEnd = styled.div`
  ${Body['body-short-01']};
`;

const MonthTargetDivider = styled.div`
  text-align: center;
`;

const MonthCalendars = styled.div`
  display: flex;
`;

const MonthHeader = styled.div`
  display: flex;
  border-top: 1px solid ${cssVariables('neutral-3')};
  border-bottom: 1px solid ${cssVariables('border-3')};
  height: 60px;
`;

const MonthHeaderItem = styled.div<{ isActive: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  padding-left: 16px;
  width: 152px;
  height: 100%;

  span {
    ${Typography['label-02']};
    padding-top: 12px;
    padding-bottom: 4px;
    color: ${cssVariables('content-2')};
  }

  time {
    ${Body['body-short-01']};
    display: block;
    height: 100%;
    color: ${cssVariables('content-3')};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      time {
        color: ${cssVariables('content-1')};
      }
      &:after {
        position: absolute;
        bottom: -1px;
        left: 0;
        background: ${cssVariables('primary')};
        width: 100%;
        height: 2px;
        content: '';
      }
    `}
`;

const MonthFooter = styled.div`
  display: flex;
  border-top: 1px solid ${cssVariables('neutral-3')};
  padding: 16px;
`;

const MonthFooterButtons = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  button + button {
    margin-left: 4px;
  }
`;

const MenuItemsUnOrderedList = styled.ul`
  padding-top: 8px;
  padding-bottom: 8px;
  width: 224px;

  li {
    ${Body['body-short-01']};
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0 16px;
    height: 32px;
    list-style: none;
    color: ${cssVariables('content-1')};

    &:hover {
      background: ${cssVariables('neutral-1')};
    }
  }
`;

const ItemsGroup = styled.div`
  & + & {
    margin-top: 8px;
    border-top: 1px solid ${cssVariables('border-3')};
    padding-top: 8px;
  }
`;

enum ViewState {
  last3Months = 'last3Months',
  last6Months = 'last6Months',
  last12Months = 'last12Months',
  custom = 'custom',
}

type LastMonth = {
  key: Exclude<ViewState, ViewState.custom>;
  diffMonthsFromToday: number;
  text: string;
};

const lastMonthsData: LastMonth[] = [
  {
    key: ViewState.last3Months,
    diffMonthsFromToday: 2,
    text: 'Last 3 months',
  },
  {
    key: ViewState.last6Months,
    diffMonthsFromToday: 5,
    text: 'Last 6 months',
  },
  {
    key: ViewState.last12Months,
    diffMonthsFromToday: 11,
    text: 'Last 12 months',
  },
];

const customMonthData = {
  key: ViewState.custom,
  text: 'Custom',
};

enum ActiveTab {
  start = 'start',
  end = 'end',
}

export type MonthRange = { start: CalendarDate | null; end: CalendarDate | null };
type MonthRangePickerProps = {
  placement?: PopperProps['placement'];
  offset?: string;
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onChange?: (dates: MonthRange) => void;
  onApply?: (dates: MonthRange) => void;
  size?: DropdownSize;
  start: CalendarDate | null;
  end: CalendarDate | null;
  disabled?: boolean;
  className?: string;
  wrapperStyles?: any;
  targetStyles?: any;
  popperProps?: Partial<PopperProps>;
  useCustom?: boolean;
  defaultSelectedStartYear?: number;
  defaultSelectedEndYear?: number;
  timeZone?: string;
  isApplyButtonDisabled?: boolean;
  selectedItemPrefix?: ReactElement;
};

const lastMonthsOptions = [ViewState.last3Months, ViewState.last6Months, ViewState.last12Months];

const getSelectedItem = (start: CalendarDate | null, end: CalendarDate | null, timeZone: string) => {
  if (!end || !start || !isSameMonth(end, today(timeZone))) return null;

  const item =
    lastMonthsData.find(({ diffMonthsFromToday }) => isSameMonth(end, start.add({ months: diffMonthsFromToday }))) ??
    null;

  return item;
};

const getStartOfMonthOrNull = (date: CalendarDate | null) => (date ? startOfMonth(date) : null);
const getEndOfMonthOrNull = (date: CalendarDate | null) => (date ? endOfMonth(date) : null);

export const MonthRangePicker = ({
  placement = 'bottom-start',
  disabled,
  className,
  onClose,
  onChange,
  onApply,
  size = 'small',
  start: startProp = null,
  end: endProp = null,
  targetStyles,
  popperProps,
  useCustom = true,
  defaultSelectedStartYear: defaultSelectedStartYearProp,
  defaultSelectedEndYear: defaultSelectedEndYearProp,
  timeZone = getLocalTimeZone(),
  isApplyButtonDisabled,
  selectedItemPrefix,
}: MonthRangePickerProps) => {
  const { locale } = useLocale();
  const todayCalendarDate = today(timeZone);
  const start = useMemo(() => getStartOfMonthOrNull(startProp), [startProp]);
  const end = useMemo(() => getEndOfMonthOrNull(endProp), [endProp]);
  const defaultSelectedStartYear = defaultSelectedStartYearProp ?? todayCalendarDate.year;
  const defaultSelectedEndYear = defaultSelectedEndYearProp ?? todayCalendarDate.year;
  const formatter = useMemo(
    () => new DateFormatter(locale, { year: 'numeric', month: 'long', timeZone }),
    [locale, timeZone],
  );

  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.start);
  const [selectedStartYear, setSelectedStartYear] = useState<number>(defaultSelectedStartYear);
  const [selectedEndYear, setSelectedEndYear] = useState<number>(defaultSelectedEndYear);
  const [selectedStartDate, setSelectedStartDate] = useState<CalendarDate | null>(start);
  const [selectedEndDate, setSelectedEndDate] = useState<CalendarDate | null>(end);
  const [hoveredDate, setHoveredDate] = useState<CalendarDate | null>(null);
  const scheduleUpdateRef = useRef<PopperChildrenProps['scheduleUpdate']>();

  const items = [...lastMonthsOptions, ...(useCustom ? [ViewState.custom] : [])];

  const handleClickLastMonthItem = useCallback(
    (selectedItem: ViewState.last3Months | ViewState.last6Months | ViewState.last12Months) => {
      const { diffMonthsFromToday } = lastMonthsData.find(({ key }) => key === selectedItem) as LastMonth;

      const startDate = startOfMonth(todayCalendarDate.subtract({ months: diffMonthsFromToday }));
      const endDate = endOfMonth(todayCalendarDate);
      setSelectedEndDate(endDate);
      setSelectedStartDate(startDate);
      onApply?.({ start: startDate, end: endDate });
    },
    [onApply, todayCalendarDate],
  );

  const stateReducer = useCallback(
    (state: UseSelectState<ViewState>, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      /**
       * __item_click__ : when clicking item of dropdown.
       * __menu_keydown_enter__ : when selecting item using Enter key.
       * __menu_blur__ : when clicking outside to close dropdown.
       *  __togglebutton_click__ : when clicking toggle button to open or close dropdown.
       */

      if (
        [useSelect.stateChangeTypes.ItemClick, useSelect.stateChangeTypes.MenuKeyDownEnter].includes(type) &&
        changes.selectedItem === ViewState.custom
      )
        return { ...changes, isOpen: true };

      if ([useSelect.stateChangeTypes.MenuBlur].includes(type) && changes.selectedItem === ViewState.custom) {
        setSelectedStartDate(getStartOfMonthOrNull(start));
        setSelectedEndDate(getEndOfMonthOrNull(end));
        const item = getSelectedItem(start, end, timeZone);

        return { ...changes, selectedItem: item?.key };
      }

      if (type === useSelect.stateChangeTypes.ToggleButtonClick && changes.isOpen === true) {
        setSelectedStartDate(getStartOfMonthOrNull(start));
        setSelectedEndDate(getEndOfMonthOrNull(end));
        const item = getSelectedItem(start, end, timeZone);

        return { ...changes, selectedItem: item?.key };
      }

      return changes;
    },
    [end, start, timeZone],
  );

  const { getToggleButtonProps, isOpen, closeMenu, getMenuProps, getItemProps, selectedItem } = useSelect({
    items,
    stateReducer,
    onSelectedItemChange: (changes: UseSelectStateChange<ViewState>) => {
      const { selectedItem } = changes;
      if (!selectedItem) return changes;
      if (selectedItem === ViewState.custom) {
        scheduleUpdateRef.current?.();
        setSelectedStartYear(selectedEndYear - 1);
        return { ...changes, isOpen: true };
      }
      handleClickLastMonthItem(selectedItem);
    },
    onIsOpenChange: (changes: UseSelectStateChange<ViewState>) => {
      if (changes.type === useSelect.stateChangeTypes.ToggleButtonClick) {
        if (selectedStartDate?.year !== selectedStartYear) {
          setSelectedStartYear(selectedStartDate?.year || defaultSelectedStartYear);
        }
        if (selectedEndDate?.year !== selectedEndYear) {
          setSelectedEndYear(selectedEndDate?.year || defaultSelectedEndYear);
        }
      }
      return changes;
    },
  });

  useEffect(() => {
    if (isOpen) {
      scheduleUpdateRef.current?.();
    }
  }, [isOpen]);

  const handleStartDateChange = useCallback(
    (newDate: CalendarDate) => {
      const start = startOfMonth(newDate);
      setSelectedStartDate(start);
      setSelectedEndDate(null);
      onChange?.({ start, end: null });
      setActiveTab(ActiveTab.end);
    },
    [onChange],
  );

  const handleEndDateChange = useCallback(
    (newDate: CalendarDate) => {
      const end = endOfMonth(newDate);
      setSelectedEndDate(end);
      onChange?.({ start: selectedStartDate, end });
      setActiveTab(ActiveTab.start);
    },
    [onChange, selectedStartDate],
  );

  const compareTargetDateToStartDate = useCallback(
    ({ targetDate, newEndDate }: { targetDate: CalendarDate; newEndDate: CalendarDate | null }) => {
      if (!selectedStartDate) return;
      if (targetDate.compare(selectedStartDate) < 0) {
        handleStartDateChange(targetDate);
        if (newEndDate) {
          setSelectedEndDate(endOfMonth(newEndDate));
          setActiveTab(ActiveTab.start);
        } else {
          setSelectedEndDate(null);
        }
      } else {
        handleEndDateChange(targetDate);
      }
    },
    [handleEndDateChange, handleStartDateChange, selectedStartDate],
  );

  const handleMonthChange = useCallback(
    (targetDate: CalendarDate) => {
      if (!selectedStartDate) {
        // startDate
        handleStartDateChange(targetDate);
        return;
      }

      if (!selectedEndDate) {
        // endDate
        compareTargetDateToStartDate({ targetDate, newEndDate: selectedStartDate });
        return;
      }

      // if both startDate and endDate were selected already, handle active tab's date
      if (activeTab === ActiveTab.start) {
        // startDate's tab
        handleStartDateChange(targetDate);
      } else {
        // endDate's tab
        compareTargetDateToStartDate({ targetDate, newEndDate: null });
      }
    },
    [activeTab, compareTargetDateToStartDate, handleStartDateChange, selectedEndDate, selectedStartDate],
  );

  const targetDate = useMemo(() => {
    if (!start || !end) return <MonthTargetStart>Select month</MonthTargetStart>;

    const selectedItem = getSelectedItem(start, end, timeZone);
    if (selectedItem) {
      return <MonthTargetStart>{selectedItem.text}</MonthTargetStart>;
    }

    return (
      <>
        <MonthTargetStart>{formatter.format(start.toDate(timeZone))}</MonthTargetStart>
        <MonthTargetDivider>&nbsp;-&nbsp;</MonthTargetDivider>
        <MonthTargetEnd>{formatter.format(end.toDate(timeZone))}</MonthTargetEnd>
      </>
    );
  }, [end, formatter, start, timeZone]);

  const renderOptionItem = useCallback(
    ({ item, index }) => {
      const itemProps = getItemProps({ item, index });
      return (
        <li key={item.key} {...itemProps}>
          <span>{item.text}</span>
          {item.key === selectedItem && <Icon.Done size={20} color={cssVariables('primary')} />}
        </li>
      );
    },
    [getItemProps, selectedItem],
  );

  const renderRangeOptions = useMemo(() => {
    return (
      <MenuItemsUnOrderedList>
        <ItemsGroup>{lastMonthsData.map((item, index) => renderOptionItem({ item, index }))}</ItemsGroup>
        {useCustom && (
          <ItemsGroup>
            {(() => renderOptionItem({ item: customMonthData, index: lastMonthsOptions.length }))()}
          </ItemsGroup>
        )}
      </MenuItemsUnOrderedList>
    );
  }, [renderOptionItem, useCustom]);

  const handleClose = useCallback(() => {
    onClose?.();
    setActiveTab(ActiveTab.start);
    closeMenu();
  }, [closeMenu, onClose]);

  const onApplyButtonClick = useCallback(() => {
    onApply?.({
      start: selectedStartDate,
      end: selectedEndDate,
    });
    handleClose();
  }, [handleClose, onApply, selectedEndDate, selectedStartDate]);

  const onCloseButtonClick = useCallback(() => {
    setSelectedStartDate(getStartOfMonthOrNull(start));
    setSelectedEndDate(getEndOfMonthOrNull(end));
    handleClose();
  }, [end, handleClose, start]);

  const handleMouseEnter = useCallback(
    (hoveredDate: CalendarDate) => {
      if (selectedStartDate && !selectedEndDate) {
        setHoveredDate(hoveredDate);
      }
    },
    [selectedEndDate, selectedStartDate],
  );

  const hoveredRange = useMemo(() => {
    if (selectedStartDate && hoveredDate) {
      if (hoveredDate.compare(selectedStartDate) < 0) {
        return { from: hoveredDate, to: selectedStartDate };
      }
      return { from: selectedStartDate, to: hoveredDate };
    }
    return null;
  }, [hoveredDate, selectedStartDate]);

  const onClickTabDate = useCallback(
    ({ tabType, selectedTabDate }) => {
      setActiveTab(tabType);

      if (selectedTabDate) {
        if (selectedStartDate && selectedEndDate) {
          const startYear = selectedStartDate.year;
          const endYear = selectedEndDate.year;

          if (endYear - startYear === 1) {
            setSelectedStartYear(startYear);
            setSelectedEndYear(endYear);
            return;
          }
        }

        const thisYear = todayCalendarDate.year;
        const selectedYear = selectedTabDate.year;

        if (thisYear - selectedYear > 0) {
          setSelectedStartYear(selectedYear);
          setSelectedEndYear(selectedYear + 1);
        } else {
          setSelectedStartYear(selectedYear - 1);
          setSelectedEndYear(selectedYear);
        }
      }
    },
    [selectedEndDate, selectedStartDate, todayCalendarDate.year],
  );

  const { ref: menuPropsRef, ...menuProps } = getMenuProps({
    onMouseUp: (event) => {
      // prevent document mouseup event handler (which will close the date picker) from being called.
      event.stopPropagation();
    },
  });

  return (
    <>
      <Manager>
        <Reference>
          {({ ref: popperRef }) => (
            <MonthTarget
              {...getToggleButtonProps({ ref: popperRef })}
              type="button"
              size={size}
              disabled={disabled}
              className={className}
              aria-pressed={isOpen}
              isActive={isOpen}
              styles={targetStyles}
              data-test-id="PopoverTrigger"
            >
              {selectedItemPrefix}
              {targetDate}
              <MonthIcon>
                <Icon.ChevronDown size={18} />
              </MonthIcon>
            </MonthTarget>
          )}
        </Reference>

        <Popper placement={placement} {...popperProps} innerRef={menuPropsRef}>
          {({ ref: setPopperRef, style, scheduleUpdate }) => {
            scheduleUpdateRef.current = scheduleUpdate;
            return (
              <MonthWrapper
                ref={setPopperRef}
                style={{
                  ...style,
                  zIndex: ZIndexes.dropdownMenu,
                  ...(isOpen ? null : { opacity: 0, pointerEvents: 'none' }),
                }}
                data-placement={placement}
                data-test-id="MonthRangePickerPopover"
                onMouseUp={(event) => {
                  // prevent document mouseup event handler (which will close the date picker) from being called.
                  event.stopPropagation();
                }}
                {...menuProps}
              >
                {selectedItem === ViewState.custom ? (
                  <>
                    <MonthHeader>
                      <MonthHeaderItem
                        isActive={activeTab === ActiveTab.start}
                        onClick={() => {
                          onClickTabDate({ tabType: ActiveTab.start, selectedTabDate: selectedStartDate });
                        }}
                      >
                        <span>From</span>
                        {selectedStartDate && (
                          <time dateTime={selectedStartDate.toString()}>
                            {formatter.format(selectedStartDate.toDate(timeZone))}
                          </time>
                        )}
                      </MonthHeaderItem>
                      <MonthHeaderItem
                        isActive={activeTab === 'end'}
                        onClick={() => {
                          onClickTabDate({ tabType: ActiveTab.end, selectedTabDate: selectedEndDate });
                        }}
                      >
                        <span>To</span>
                        {selectedEndDate && (
                          <time dateTime={selectedEndDate.toString()}>
                            {formatter.format(selectedEndDate.toDate(timeZone))}
                          </time>
                        )}
                      </MonthHeaderItem>
                    </MonthHeader>
                    <MonthCalendars>
                      <Calendar
                        handleMonthChange={handleMonthChange}
                        range={{
                          start: selectedStartDate,
                          end: selectedEndDate,
                        }}
                        selectedYear={selectedStartYear}
                        setSelectedYear={setSelectedStartYear}
                        maxDate={todayCalendarDate}
                        showNextArrow={false}
                        onPrevClickCallback={(newYear) => setSelectedEndYear(newYear + 1)}
                        hoveredRange={hoveredRange}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseLeave={() => {
                          setHoveredDate(null);
                        }}
                        timeZone={timeZone}
                      />
                      <Calendar
                        handleMonthChange={handleMonthChange}
                        range={{
                          start: selectedStartDate,
                          end: selectedEndDate,
                        }}
                        selectedYear={selectedEndYear}
                        setSelectedYear={setSelectedEndYear}
                        maxDate={todayCalendarDate}
                        showPrevArrow={false}
                        onNextClickCallback={(newYear) => setSelectedStartYear(newYear - 1)}
                        hoveredRange={hoveredRange}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseLeave={() => {
                          setHoveredDate(null);
                        }}
                        timeZone={timeZone}
                      />
                    </MonthCalendars>
                    <MonthFooter>
                      <MonthFooterButtons>
                        <Button buttonType="tertiary" size="small" onClick={onCloseButtonClick}>
                          Cancel
                        </Button>
                        <Button
                          buttonType="primary"
                          size="small"
                          onClick={onApplyButtonClick}
                          disabled={!selectedEndDate || isApplyButtonDisabled}
                        >
                          Apply
                        </Button>
                      </MonthFooterButtons>
                    </MonthFooter>
                  </>
                ) : (
                  renderRangeOptions
                )}
              </MonthWrapper>
            );
          }}
        </Popper>
      </Manager>
    </>
  );
};
