import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrHealthcareSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g fillRule="evenodd" clipPath="url(#br-he-a)" clipRule="evenodd">
        <path d="M2.992 7h20v12a2 2 0 0 1-2 2h-16a2 2 0 0 1-2-2V7zm10.75 7.75v2.75h-1.5v-2.75h-2.75v-1.5h2.75V10.5h1.5v2.75h2.75v1.5h-2.75z" />
        <path d="M8.742 4.75A2.75 2.75 0 0 1 11.492 2h3a2.75 2.75 0 0 1 2.75 2.75V6h-1.5V4.75c0-.69-.56-1.25-1.25-1.25h-3c-.69 0-1.25.56-1.25 1.25V6h-1.5V4.75z" />
      </g>
      <defs>
        <clipPath id="br-he-a">
          <path d="M0 0H24V24H0z" transform="translate(.992)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrHealthcare = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrHealthcareSvg} {...props} />
));
BrHealthcare.displayName = 'BrHealthcare';
export default BrHealthcare;
