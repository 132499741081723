import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const GoogleAiColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#EA4335"
        d="M7.075 5.345a1.258 1.258 0 1 0 0-2.515 1.258 1.258 0 0 0 0 2.515zM7.075 10.585a1.258 1.258 0 1 0 0-2.515 1.258 1.258 0 0 0 0 2.515zM7.075 15.825a1.258 1.258 0 1 0 0-2.515 1.258 1.258 0 0 0 0 2.515zM7.075 21.066a1.258 1.258 0 1 0 0-2.516 1.258 1.258 0 0 0 0 2.516z"
      />
      <path
        fill="#FBBC04"
        d="M11.582 2.934a1.467 1.467 0 1 0 0-2.934 1.467 1.467 0 0 0 0 2.934zM11.582 8.175a1.467 1.467 0 1 0 0-2.935 1.467 1.467 0 0 0 0 2.935zM11.582 13.415a1.467 1.467 0 1 0 0-2.935 1.467 1.467 0 0 0 0 2.935zM11.582 18.655a1.467 1.467 0 1 0 0-2.934 1.467 1.467 0 0 0 0 2.934zM11.582 24a1.467 1.467 0 1 0 0-2.935 1.467 1.467 0 0 0 0 2.935z"
      />
      <path
        fill="#34A853"
        d="M16.036 5.87a1.73 1.73 0 1 0 0-3.46 1.73 1.73 0 0 0 0 3.46zM16.036 11.11a1.73 1.73 0 1 0 0-3.46 1.73 1.73 0 0 0 0 3.46zM16.036 16.35a1.73 1.73 0 1 0 0-3.46 1.73 1.73 0 0 0 0 3.46zM16.036 21.59a1.73 1.73 0 1 0 0-3.46 1.73 1.73 0 0 0 0 3.46z"
      />
      <path
        fill="#4285F4"
        d="M20.49 8.699a1.991 1.991 0 1 0 0-3.983 1.991 1.991 0 0 0 0 3.983zM2.569 7.756a1.048 1.048 0 1 0 0-2.096 1.048 1.048 0 0 0 0 2.096zM2.569 12.996a1.048 1.048 0 1 0 0-2.096 1.048 1.048 0 0 0 0 2.096zM2.569 18.236a1.048 1.048 0 1 0 0-2.096 1.048 1.048 0 0 0 0 2.096zM20.49 13.94a1.991 1.991 0 1 0 0-3.984 1.991 1.991 0 0 0 0 3.983zM20.49 19.18a1.991 1.991 0 1 0 0-3.983 1.991 1.991 0 0 0 0 3.982z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const GoogleAiColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={GoogleAiColoredSvg} {...props} />
));
GoogleAiColored.displayName = 'GoogleAiColored';
export default GoogleAiColored;
