import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const NoSearchSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M10.5 2c.512 0 1.013.045 1.5.132v2.042a6.5 6.5 0 1 0 5 6.326h2a8.463 8.463 0 0 1-1.824 5.262l4.527 4.526-1.415 1.415-4.526-4.527A8.5 8.5 0 1 1 10.5 2zm4.4 0L17 4.1 19.1 2l1.4 1.4-2.1 2.1 2.1 2.1L19.1 9 17 6.9 14.9 9l-1.4-1.4 2.1-2.1-2.1-2.1L14.9 2z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const NoSearch = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={NoSearchSvg} {...props} />
));
NoSearch.displayName = 'NoSearch';
export default NoSearch;
