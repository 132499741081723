import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const WordpressOrgColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 41 40"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g>
        <g fill="#0D0D0D">
          <path d="M20.667 0c-11.046 0-20 8.954-20 20s8.954 20 20 20c11.045 0 20-8.954 20-20s-8.955-20-20-20zm0 1.2a18.682 18.682 0 0 1 10.51 3.21 18.942 18.942 0 0 1 5.08 5.08A18.679 18.679 0 0 1 39.467 20a18.679 18.679 0 0 1-3.21 10.51 18.936 18.936 0 0 1-5.08 5.08 18.681 18.681 0 0 1-10.51 3.21 18.681 18.681 0 0 1-10.51-3.21 18.937 18.937 0 0 1-5.08-5.08A18.681 18.681 0 0 1 1.867 20a18.681 18.681 0 0 1 3.21-10.51 18.944 18.944 0 0 1 5.08-5.08 18.681 18.681 0 0 1 10.51-3.21z" />
          <path d="M35.293 12.004c.072.53.112 1.1.112 1.714 0 1.69-.316 3.591-1.267 5.97l-5.09 14.717c4.954-2.889 8.287-8.257 8.287-14.406 0-2.897-.741-5.621-2.042-7.995zm-14.332 9.453-5.002 14.53a16.652 16.652 0 0 0 10.244-.265 1.517 1.517 0 0 1-.12-.23l-5.122-14.035zm10.958-2.298c0-2.06-.74-3.487-1.374-4.596-.845-1.374-1.637-2.536-1.637-3.91 0-1.531 1.162-2.957 2.799-2.957.074 0 .144.009.216.013a16.605 16.605 0 0 0-11.255-4.376c-5.823 0-10.945 2.988-13.926 7.512.392.013.76.02 1.073.02 1.743 0 4.442-.211 4.442-.211.898-.053 1.004 1.267.107 1.373 0 0-.904.106-1.908.158l6.07 18.053L20.172 19.3l-2.596-7.115a30.562 30.562 0 0 1-1.748-.158c-.899-.053-.794-1.426.105-1.373 0 0 2.752.211 4.39.211 1.742 0 4.442-.211 4.442-.211.899-.053 1.004 1.267.106 1.373 0 0-.904.106-1.907.158l6.023 17.916 1.72-5.448c.763-2.381 1.211-4.068 1.211-5.494zm-27.918.84c0 6.597 3.834 12.299 9.394 15l-7.95-21.782A16.602 16.602 0 0 0 4 19.999z" />
        </g>
      </g>
    </svg>
  )),
  { width: 41, height: 40 },
);
const WordpressOrgColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={WordpressOrgColoredSvg} {...props} />
));
WordpressOrgColored.displayName = 'WordpressOrgColored';
export default WordpressOrgColored;
