import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrPartnersSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-pa-a)">
        <path d="M16.4 3.584a2 2 0 0 0-2.828 0l-.69.69 4.54 4.539a2.25 2.25 0 1 1-3.183 3.182l-3.54-3.538-.43.43a2.035 2.035 0 0 0-.133.147l-5.843 5.84a1 1 0 1 0 1.415 1.415l4.567-4.566.725.725-4.568 4.565a1 1 0 1 0 1.415 1.415l4.567-4.566.725.725-4.567 4.565a1 1 0 1 0 1.415 1.414l4.566-4.565.725.725-4.567 4.565a1 1 0 1 0 1.415 1.414l5.866-5.863c.04-.035.08-.072.12-.111l4.301-4.302a2 2 0 0 0 0-2.828l-4.725-4.725a2.02 2.02 0 0 0-.076-.08l-1.211-1.212z" />
        <path
          fillRule="evenodd"
          d="m15.303 10.936-6.07-6.07a1.249 1.249 0 0 0-1.767-.002L2.864 9.466a1.25 1.25 0 0 0 .001 1.767l1.486 1.485-1.059 1.06-1.486-1.486a2.748 2.748 0 0 1-.003-3.887l4.602-4.602a2.748 2.748 0 0 1 3.887.003l6.07 6.071a.75.75 0 1 1-1.059 1.059z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="br-pa-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrPartners = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrPartnersSvg} {...props} />
));
BrPartners.displayName = 'BrPartners';
export default BrPartners;
