import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DragHandleSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M8 3a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM16 3a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM6 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0zM16 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM6 19a2 2 0 1 1 4 0 2 2 0 0 1-4 0zM16 17a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const DragHandle = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DragHandleSvg} {...props} />
));
DragHandle.displayName = 'DragHandle';
export default DragHandle;
