import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const FigmaColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#figma-a)">
        <path fill="#1ABCFE" d="M12 12a4 4 0 1 1 8 0 4 4 0 0 1-8 0z" />
        <path fill="#0ACF83" d="M4 20a4 4 0 0 1 4-4h4v4a4 4 0 1 1-8 0z" />
        <path fill="#FF7262" d="M12 0v8h4a4 4 0 1 0 0-8h-4z" />
        <path fill="#F24E1E" d="M4 4a4 4 0 0 0 4 4h4V0H8a4 4 0 0 0-4 4z" />
        <path fill="#A259FF" d="M4 12a4 4 0 0 0 4 4h4V8H8a4 4 0 0 0-4 4z" />
      </g>
      <defs>
        <clipPath id="figma-a">
          <path fill="#fff" d="M0 0H16V24H0z" transform="translate(4)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const FigmaColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={FigmaColoredSvg} {...props} />
));
FigmaColored.displayName = 'FigmaColored';
export default FigmaColored;
