import { useEffect, useRef } from 'react';

import styled, { css } from 'styled-components';

import { transitionDefault } from '../../animation';
import cssVariables from '../../theme/cssVariables';
import type { AlertProps, ContainerProps, StylesProps } from '../../types';
import { AlertMessageAlign, AlertSlideFrom, AlertStatus } from '../../types';

const getTheme = (status: AlertStatus) => {
  switch (status) {
    case AlertStatus.PRIMARY:
      return css`
        color: ${cssVariables('purple-7')};
        background: ${cssVariables('purple-2')};
      `;

    case AlertStatus.SECONDARY:
      return css`
        color: ${cssVariables('neutral-3')};
        background: ${cssVariables('neutral-2')};
      `;

    case AlertStatus.SUCCESS:
      return css`
        color: ${cssVariables('green-5')};
        background: ${cssVariables('green-2')};
      `;

    case AlertStatus.DANGER:
      return css`
        color: ${cssVariables('red-5')};
        background: ${cssVariables('red-2')};
      `;

    case AlertStatus.LIGHT:
      return css`
        color: ${cssVariables('neutral-5')};
        background: ${cssVariables('neutral-1')};
      `;

    case AlertStatus.DARK:
      return css`
        color: ${cssVariables('neutral-8')};
        background: ${cssVariables('neutral-5')};
      `;

    default:
      return css`
        color: ${cssVariables('purple-7')};
        background: ${cssVariables('purple-2')};
      `;
  }
};

const Container = styled.div<ContainerProps & StylesProps>`
  padding: ${(props) => (props.show ? 20 : 0)}px;
  ${(props) =>
    !props.show &&
    css`
      height: 0 !important;
    `}
  opacity: ${(props) => (props.show ? 1 : 0)};
  transform: ${(props) => {
    if (props.slideFrom === AlertSlideFrom.TOP || props.slideFrom === AlertSlideFrom.BOTTOM) {
      if (props.show) {
        return 'translateY(0)';
      }
      return `translateY(${props.slideFrom === AlertSlideFrom.TOP ? -50 : 50}px)`;
    }
    if (props.show) {
      return 'translateX(0)';
    }
    return `translateX(${props.slideFrom === AlertSlideFrom.LEFT ? -50 : 50}px)`;
  }};
  text-align: ${(props) => (props.align ? props.align : AlertMessageAlign.LEFT)};
  border-radius: 2px;
  transition: height 0.2s ${transitionDefault}, opacity 0.2s ${transitionDefault}, transform 0.2s ${transitionDefault};
  will-change: height, opacity, transform;

  ${(props) => getTheme(props.status)}
  ${(props) => props.styles}
`;

const Message = styled.div<StylesProps>`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;

  ${(props) => props.styles}
`;

export const Alert = function ({
  show = false,
  status = AlertStatus.PRIMARY,
  align = AlertMessageAlign.LEFT,
  slideFrom = AlertSlideFrom.TOP,
  message,
  innerHTML,
  styles = {},
}: AlertProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const messageRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const currentHeight = messageRef.current ? messageRef!.current.offsetHeight + 40 : 0;

    if (containerRef.current) {
      containerRef.current.style.height = `${currentHeight}px`;
    }

    // make height auto after height transition is done
    if (show) {
      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.style.height = 'auto';
        }
      }, 200);
    }
  }, [show, message]);

  return (
    <Container
      ref={containerRef}
      show={show}
      status={status}
      align={align}
      slideFrom={slideFrom}
      styles={styles.CONTAINER}
    >
      <Message
        ref={messageRef}
        styles={styles.MESSAGE}
        {...(innerHTML && message == null // message takes precedence over innerHTML
          ? { dangerouslySetInnerHTML: { __html: innerHTML } }
          : null)}
      >
        {message}
      </Message>
    </Container>
  );
};
