import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const HashSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0078 3.85278L11.2507 10.6667H4V13.3334H10.9544L10.3618 18.6667H4V21.3334H10.0655L9.34116 27.8528L11.9915 28.1473L12.7486 21.3334H18.0656L17.3412 27.8528L19.9916 28.1473L20.7487 21.3334H28.0001V18.6667H21.045L21.6375 13.3334H28.0001V10.6667H21.9338L22.6582 4.14727L20.0079 3.85278L19.2508 10.6667H13.9338L14.6582 4.14727L12.0078 3.85278ZM18.3619 18.6667L18.9545 13.3334H13.6375L13.0449 18.6667H18.3619Z"
        fill="#6210CC"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Hash = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={HashSvg} {...props} />
));
HashSvg.displayName = 'Hash';
export default Hash;
