import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ReopenSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M9.92 5.19a5.882 5.882 0 0 1 3.347.114c1.093.362 2.104 1.042 2.932 1.992l.01.011L18.708 10H16v2h6V6h-2v2.452L17.696 5.97c-1.045-1.195-2.35-2.083-3.8-2.564a7.882 7.882 0 0 0-4.48-.151c-1.479.384-2.834 1.188-3.944 2.32-.85.866-1.514 1.89-2.221 2.98-.19.293-.383.59-.583.891l1.664 1.11c.242-.363.46-.699.664-1.013.684-1.054 1.207-1.858 1.903-2.568.872-.889 1.913-1.497 3.02-1.784zM4 15.548l2.303 2.483c1.046 1.195 2.35 2.083 3.8 2.564a7.882 7.882 0 0 0 4.48.151c1.48-.384 2.835-1.188 3.945-2.32.85-.866 1.514-1.89 2.221-2.98.19-.293.382-.59.583-.891l-1.664-1.11c-.242.363-.46.699-.664 1.013-.685 1.054-1.207 1.858-1.904 2.568-.872.889-1.912 1.497-3.02 1.784a5.882 5.882 0 0 1-3.347-.114c-1.093-.362-2.105-1.042-2.932-1.992l-.01-.011L5.29 14H8v-2H2v6h2v-2.452z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Reopen = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ReopenSvg} {...props} />
));
Reopen.displayName = 'Reopen';
export default Reopen;
