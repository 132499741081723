import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ListBackSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M11 6h11V4H11v2zM8.207 15.293 5.914 13H22v-2H5.914l2.293-2.293-1.414-1.414L2.086 12l4.707 4.707 1.414-1.414zM11 20h11v-2H11v2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ListBack = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ListBackSvg} {...props} />
));
ListBack.displayName = 'ListBack';
export default ListBack;
