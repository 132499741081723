import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const NoThumbnailSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M19 4.1 21.1 2l1.4 1.4-2.1 2.1 2.1 2.1L21.1 9 19 6.9 16.9 9l-1.4-1.4 2.1-2.1-2.1-2.1L16.9 2 19 4.1z" />
      <path
        fillRule="evenodd"
        d="M14 3H3v18h15.5c1.38 0 2.5-1.116 2.5-2.498v-8.498h-2v2.803l-3.476-2.023L5.639 19H5V5h9V3zM8.768 19H18.5c.277 0 .5-.224.5-.498V15.12l-3.274-1.905L8.768 19z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M6 8.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zM8.5 8a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const NoThumbnail = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={NoThumbnailSvg} {...props} />
));
NoThumbnail.displayName = 'NoThumbnail';
export default NoThumbnail;
