import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ShiftRightSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m5.293 17.293 1.414 1.414L13.414 12 6.707 5.293 5.293 6.707 10.586 12l-5.293 5.293z" />
      <path d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L16.586 12l-5.293 5.293z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ShiftRight = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ShiftRightSvg} {...props} />
));
ShiftRight.displayName = 'ShiftRight';
export default ShiftRight;
