import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TeamsFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M13 5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zM5 5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zM18 22a6 6 0 0 0-12 0h12zM9 12a3 3 0 1 1 6 0 3 3 0 0 1-6 0zM15.354 15a4.484 4.484 0 0 0 1.146-3 4.483 4.483 0 0 0-1.118-2.969A6 6 0 0 1 22 15h-6.646zM8.618 9.031A4.483 4.483 0 0 0 7.5 12c0 1.152.433 2.204 1.146 3H2a6 6 0 0 1 6.618-5.969z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const TeamsFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TeamsFilledSvg} {...props} />
));
TeamsFilled.displayName = 'TeamsFilled';
export default TeamsFilled;
