import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DoneAllSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M16.293 5.293 9.965 11.62l1.414 1.414 6.328-6.328-1.414-1.414zM7.707 17.293l-6-6-1.414 1.414 6 6 1.414-1.414z" />
      <path d="M21.293 5.293 11 15.586l-4.293-4.293-1.414 1.414L11 18.414 22.707 6.707l-1.414-1.414z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const DoneAll = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DoneAllSvg} {...props} />
));
DoneAll.displayName = 'DoneAll';
export default DoneAll;
