import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrVideoSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-vi-a)">
        <path d="m19.996 15.458 3.998 2.043V6.5l-3.998 2.043v1.684l2.498-1.276v6.099l-2.498-1.276v1.684z" />
        <path
          fillRule="evenodd"
          d="M16.992 3.998h-15v14a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2v-12a2 2 0 0 0-2-2zm-8.496 11 5-3-5-3v6z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="br-vi-a">
          <path d="M0 0H23.996V23.996H0z" transform="translate(.996)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrVideo = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrVideoSvg} {...props} />
));
BrVideo.displayName = 'BrVideo';
export default BrVideo;
