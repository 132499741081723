import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BotSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M9.783 15.202V12.45a1 1 0 1 0-2 0v2.752a1 1 0 1 0 2 0zM16.267 15.202V12.45a1 1 0 1 0-2 0v2.752a1 1 0 1 0 2 0z" />
      <path
        fillRule="evenodd"
        d="M12 .974a2.505 2.505 0 0 0-1 4.803V7.5H3.25V19a2.5 2.5 0 0 0 2.5 2.5h12.5a2.5 2.5 0 0 0 2.5-2.5v-9a2.5 2.5 0 0 0-2.5-2.5H13V5.777a2.506 2.506 0 0 0-1-4.803zM5.25 9.5V19a.5.5 0 0 0 .5.5h12.5a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"
        clipRule="evenodd"
      />
      <path d="M.251 16.579v-6h2v6h-2zM21.751 10.579v6h2v-6h-2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Bot = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BotSvg} {...props} />
));
Bot.displayName = 'Bot';
export default Bot;
