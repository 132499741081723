import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TableViewFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M16.5 3H22v15.5a2.5 2.5 0 0 1-2.5 2.5H1.998V3H7.5v5H2v2h5.5v11h2V10h5v11h2V10H22V8h-5.5V3zm-2 0h-5v5h5V3z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const TableViewFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TableViewFilledSvg} {...props} />
));
TableViewFilled.displayName = 'TableViewFilled';
export default TableViewFilled;
