import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ErrorTwotoneSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path id="Color_02" d="M1 11.9 12.05.85 23.1 11.9 12.05 22.95 1 11.9z" />
      <path id="Color_01" d="M11 7v6h2V7h-2zM12 14a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ErrorTwotone = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ErrorTwotoneSvg} {...props} />
));
ErrorTwotone.displayName = 'ErrorTwotone';
export default ErrorTwotone;
