import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const KakaoSquareSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g fillRule="evenodd" clipPath="url(#kakao-a)" clipRule="evenodd">
        <path d="M18.66 24A5.34 5.34 0 0 0 24 18.66V5.34A5.34 5.34 0 0 0 18.66 0H5.34A5.34 5.34 0 0 0 0 5.34v13.32A5.34 5.34 0 0 0 5.34 24h13.32zM3.293 11.305c0-3.75 3.865-6.786 8.632-6.786 4.768 0 8.633 3.038 8.633 6.786 0 3.747-3.865 6.784-8.633 6.784-.547 0-1.082-.04-1.602-.117l-.958.648-2.364 1.597c-.19.125-.479.131-.362-.335.095-.376.568-2.135.749-2.804-2.459-1.196-4.095-3.334-4.095-5.773z" />
        <path d="M6.79 10.053h-.893c-.52 0-.521-.847 0-.847 0 0 2.08.017 2.703.017.553 0 .553.864 0 .864l-.884-.001-.003 2.966c.002.411-.922.406-.922-.007.005-.566 0-2.992 0-2.992zm3.859 1.697-.494-1.54-.536 1.54h1.03zm-1.678-.607c.261-.71.519-1.412.63-1.654h-.002c.086-.19.29-.266.499-.266.252 0 .513.112.585.266.112.236.577 1.486.928 2.43.145.39.27.727.343.918.175.46-.67.777-.844.318-.215-.566-.16-.591-.16-.591H9.325l-.177.617c-.19.433-1.006.08-.817-.353.128-.296.386-.995.64-1.685zm3.297-1.513c0 .768.005 3.245.005 3.245s-.072.492.353.492c.214 0 .558 0 .9-.002l.883-.002c.418 0 .416-.884-.001-.884s-1.18-.005-1.18-.005.003-2.29.001-2.847c0-.27-.241-.404-.48-.404-.24 0-.483.136-.481.405v.002zm2.66 2.882c.006-.967.016-2.75.01-3.003h-.002c-.005-.187.234-.282.469-.282.22 0 .434.084.439.257.01.457 0 1.284 0 1.284l1.51-1.503c.194-.192.796.41.602.602-.333.329-1.177 1.236-1.177 1.236l1.244 1.63c.236.309-.509.871-.745.56l-1.172-1.588-.267.214v1.199c0 .355-.913.357-.913 0l.003-.606z" />
      </g>
      <defs>
        <clipPath id="kakao-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const KakaoSquare = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={KakaoSquareSvg} {...props} />
));
KakaoSquare.displayName = 'KakaoSquare';
export default KakaoSquare;
