import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const InReviewSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M21 4h-2v3.264a3 3 0 0 1-1.658 2.683L13.236 12l4.106 2.053A3 3 0 0 1 19 16.736V20h2v2H3v-2h2v-3.264a3 3 0 0 1 1.658-2.683L10.764 12 6.658 9.947A3 3 0 0 1 5 7.264V4H3V2h18v2zM7 4h10v3H7V4zm2.236 5L12 10.382 14.764 9H9.236zM17 20v-3.264a1 1 0 0 0-.553-.894L12 13.618l-4.447 2.224a1 1 0 0 0-.553.894V20h10z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const InReview = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={InReviewSvg} {...props} />
));
InReview.displayName = 'InReview';
export default InReview;
