import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LinkedinSquareSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M24 2.4A2.4 2.4 0 0 0 21.6 0H2.4A2.4 2.4 0 0 0 0 2.4v19.2A2.4 2.4 0 0 0 2.4 24h19.2a2.4 2.4 0 0 0 2.4-2.4V2.4zM5.1 8.836h3.224V19.2H5.101V8.836zm11.432-.257c-1.485 0-2.507.77-2.976 1.545l-.073.128h-.044V8.836h-3.093V19.2h3.222v-5.272l.01-.287c.061-1.185.427-2.229 1.925-2.229 1.24 0 1.563.87 1.646 1.812l.018.27.01.404.001 5.302H20.4v-5.684l-.005-.404a15.876 15.876 0 0 0-.005-.198l-.02-.388-.033-.376-.046-.363c-.284-1.9-1.21-3.208-3.759-3.208zM4.8 5.477a1.878 1.878 0 1 1 3.757.003A1.878 1.878 0 0 1 4.8 5.477z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const LinkedinSquare = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LinkedinSquareSvg} {...props} />
));
LinkedinSquare.displayName = 'LinkedinSquare';
export default LinkedinSquare;
