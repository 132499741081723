import styled from 'styled-components';

import { Body as BodyCSS, Headings } from '@feather/typography';

export const ContextualHelpContent = {
  Header: styled.h5`
    ${Headings['heading-01']}
  `,

  Body: styled.p`
    ${BodyCSS['body-short-01']}
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
};
