import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ConfluenceSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 23" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#confl-a)">
        <path
          fill="url(#confl-b)"
          d="M.8 17.053c-.233.366-.498.798-.698 1.13-.2.333-.1.765.233.965l4.551 2.792a.7.7 0 0 0 .964-.233c.166-.299.399-.698.664-1.13 1.794-2.959 3.622-2.626 6.878-1.064l4.518 2.128c.366.166.764 0 .93-.333L21 16.421c.166-.332 0-.764-.332-.93-.964-.433-2.858-1.33-4.519-2.161C10.003 10.37 4.787 10.57.8 17.053z"
        />
        <path
          fill="url(#confl-c)"
          d="M21.2 5.948c.233-.365.499-.797.698-1.13.2-.332.1-.765-.232-.964l-4.552-2.793a.7.7 0 0 0-.963.233c-.166.3-.4.698-.665 1.13-1.794 2.96-3.621 2.627-6.877 1.064L4.09 1.361a.707.707 0 0 0-.93.332L1 6.58c-.165.333 0 .765.333.931.963.432 2.857 1.33 4.518 2.16 6.147 2.927 11.363 2.76 15.35-3.723z"
        />
      </g>
      <defs>
        <linearGradient id="confl-b" x1={20.89} x2={7.143} y1={23.387} y2={15.493} gradientUnits="userSpaceOnUse">
          <stop stopColor="#0052CC" />
          <stop offset={0.918} stopColor="#2380FB" />
          <stop offset={1} stopColor="#2684FF" />
        </linearGradient>
        <linearGradient id="confl-c" x1={1.11} x2={14.858} y1={-0.395} y2={7.5} gradientUnits="userSpaceOnUse">
          <stop stopColor="#0052CC" />
          <stop offset={0.918} stopColor="#2380FB" />
          <stop offset={1} stopColor="#2684FF" />
        </linearGradient>
        <clipPath id="confl-a">
          <path d="M0 0H22V21.083H0z" transform="translate(0 .959)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 22, height: 23 },
);
const Confluence = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ConfluenceSvg} {...props} />
));
Confluence.displayName = 'Confluence';
export default Confluence;
