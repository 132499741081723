import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TicketViewsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path fillRule="evenodd" d="m21 17 3-3h-8l3 3v4h2v-4z" clipRule="evenodd" />
      <path d="M9 6h11v6h2V4H2v6a2 2 0 1 1 0 4v6h15v-2H9v-1H7v1H4v-2.535A3.998 3.998 0 0 0 6 12c0-1.48-.804-2.773-2-3.465V6h3v1h2V6z" />
      <path d="M7 9h2v2H7V9zM9 13H7v2h2v-2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const TicketViews = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TicketViewsSvg} {...props} />
));
TicketViews.displayName = 'TicketViews';
export default TicketViews;
