import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrTutorialSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-tu-a)">
        <path
          fillRule="evenodd"
          d="M11.695 20.85c-4.396-2.053-7.922-.764-9.691.11v-1.698c2.077-.904 5.659-1.866 9.989.03 1.943-.898 5.56-1.79 10.01 0v1.676c-4.496-2.023-8.046-.957-9.667-.13l-.318.162-.323-.15z"
          clipRule="evenodd"
        />
        <path d="M12 4.204c1.713-.89 5.395-2.193 10 0V17.5c-4.605-2.193-8.287-.89-10 0V4.204zM2 4.204c1.712-.89 5.395-2.193 10 0V17.5c-4.605-2.193-8.288-.891-10 0V4.204z" />
      </g>
      <defs>
        <clipPath id="br-tu-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrTutorial = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrTutorialSvg} {...props} />
));
BrTutorial.displayName = 'BrTutorial';
export default BrTutorial;
