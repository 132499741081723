import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const GiftFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2.004 7H4.34a3.5 3.5 0 0 1 3.162-5c2.306 0 3.693 1.662 4.439 3.018a8.3 8.3 0 0 1 .06.113l.062-.113C12.81 3.662 14.196 2 16.502 2a3.5 3.5 0 0 1 3.162 5H22v6.5h-8.998v-5h-2v5H2.004V7zm5.498-3a1.5 1.5 0 0 0 0 3h3.154a8.195 8.195 0 0 0-.468-1.018C9.56 4.838 8.696 4 7.502 4zm10.06 2.56a1.5 1.5 0 0 1-1.06.44h-3.154c.122-.32.276-.67.468-1.018C14.445 4.838 15.308 4 16.502 4a1.5 1.5 0 0 1 1.06 2.56z"
        clipRule="evenodd"
      />
      <path d="M2.004 15.5v4a2.5 2.5 0 0 0 2.5 2.5h6.498v-6.5H2.004zM13.002 22H19.5a2.5 2.5 0 0 0 2.5-2.5v-4h-8.998V22z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const GiftFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={GiftFilledSvg} {...props} />
));
GiftFilled.displayName = 'GiftFilled';
export default GiftFilled;
