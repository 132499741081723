import type { ComponentProps } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import type { PropGetters } from 'downshift';
import styled from 'styled-components';

import type { FeatherIconComponent } from '@feather/components/icons/types';

import { TreeNode } from './treeNode';
import {
  checkIsAllNodesSelected,
  checkIsExpandedNode,
  checkIsSelectedNode,
  constructTreeNodes,
  getCurrentSelectedNodes,
  getSelectedLeafNodes,
} from './utils';

export type TreeData = {
  key?: string;
  label: string;
  value: string;
  icon?: FeatherIconComponent;
  children?: TreeData[];
  tooltipContent?: string;
  notCheckable?: boolean;
};

export interface TreeDataWithLevel extends Omit<TreeData, 'children'> {
  level: number;
  children?: TreeDataWithLevel[];
}

export interface TreeNodeItem extends Omit<TreeDataWithLevel, 'children'> {
  key: string;
  parentKey?: TreeNodeItem['key'];
  children?: TreeNodeItem[];
  notCheckable?: boolean;
}

export type TreeNodeList = TreeNodeItem[];

export type TreeHandlerRef = {
  handleSelect: (selectedItem: TreeNodeItem) => void;
};

export type TreeProps = {
  treeData: TreeData[];
  selectedNodes: TreeData[];
  treeExpandedKeys?: string[];
  treeDefaultExpandAll?: boolean;
  initialExpandAll?: boolean;
  constructedTreeNodes?: TreeNodeList;
  minWidth: number;
  maxSelectedNodes?: number;
  onSelect: (selectedItem: TreeProps['selectedNodes'], isAllNodesSelected?: boolean) => void;
  onTreeExpand?: (treeExpandKeys: TreeProps['treeExpandedKeys']) => void;
  nodeToElement?: ComponentProps<typeof TreeNode>['nodeToElement'];

  // FIXME: these are not general function
  getItemProps?: PropGetters<TreeNodeItem>['getItemProps'];
  getMenuProps?: PropGetters<TreeNodeItem>['getMenuProps'];
};

const TreeNodes = styled.ul<{ minWidth: TreeProps['minWidth'] }>`
  min-width: ${({ minWidth }) => minWidth}px;
`;

export const Tree = forwardRef<TreeHandlerRef, TreeProps>(
  (
    {
      treeData,
      selectedNodes,
      treeExpandedKeys,
      treeDefaultExpandAll = false,
      initialExpandAll = false,
      constructedTreeNodes,
      minWidth,
      maxSelectedNodes,
      onSelect,
      onTreeExpand,
      getItemProps,
      getMenuProps,
      nodeToElement,
    },
    forwardedRef,
  ) => {
    const [nodes, setNodes] = useState<TreeNodeList>([]);
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

    useEffect(() => {
      setNodes(constructedTreeNodes ?? constructTreeNodes(treeData));
    }, [constructedTreeNodes, treeData]);

    const allExpandableNodeKeys = useMemo(() => nodes.filter((node) => node.children).map((node) => node.key), [nodes]);

    useEffect(() => {
      if (initialExpandAll) {
        setExpandedKeys(allExpandableNodeKeys);
        onTreeExpand?.(allExpandableNodeKeys);
      }
    }, [initialExpandAll, allExpandableNodeKeys, onTreeExpand]);

    useEffect(() => {
      if (treeDefaultExpandAll && expandedKeys.length === 0) {
        setExpandedKeys(allExpandableNodeKeys);
        onTreeExpand?.(allExpandableNodeKeys);
      }
    }, [allExpandableNodeKeys, expandedKeys.length, onTreeExpand, treeDefaultExpandAll]);

    const handleSelect = (selectedItem: TreeNodeItem) => {
      let nextSelectedNodes = [...selectedNodes];
      const isAlreadySelected = getCurrentSelectedNodes(nodes, selectedNodes).find(
        (selectedNode) => selectedNode.key === selectedItem.key,
      );
      const isParentAlreadySelected =
        selectedItem.parentKey &&
        !!getCurrentSelectedNodes(nodes, selectedNodes).find(
          (selectedNode) => selectedNode.key === selectedItem.parentKey,
        );
      if (isAlreadySelected || isParentAlreadySelected) {
        nextSelectedNodes = getCurrentSelectedNodes(nodes, selectedNodes).filter((selectedNode) => {
          if (selectedNode.key === selectedItem.key) {
            return false;
          }
          if (selectedNode.parentKey === selectedItem.key) {
            return false;
          }
          if (isParentAlreadySelected && selectedNode.key === selectedItem.parentKey) {
            return false;
          }
          return true;
        });
        const siblings = nodes.filter(
          (node) => node.key !== selectedItem.key && node.parentKey === selectedItem.parentKey,
        );
        nextSelectedNodes = isParentAlreadySelected ? nextSelectedNodes.concat(siblings) : nextSelectedNodes;
      } else {
        let selectedLeafItems = [selectedItem];
        nextSelectedNodes.push(selectedItem);
        if (selectedItem.children) {
          nextSelectedNodes = getCurrentSelectedNodes(nodes, nextSelectedNodes).filter(
            (selectedNode) => selectedNode.parentKey !== selectedItem.key,
          );
          selectedLeafItems = [...selectedItem.children];
        }
        const nextSelectedLeafNodes = getSelectedLeafNodes(nextSelectedNodes);
        if (maxSelectedNodes !== undefined && nextSelectedLeafNodes.length > maxSelectedNodes) {
          const currentSelectedNodes = nextSelectedNodes.filter((node) => node.key !== selectedItem.key);
          nextSelectedNodes = [
            ...currentSelectedNodes,
            ...selectedLeafItems.slice(0, maxSelectedNodes - currentSelectedNodes.length),
          ];
        }
        if (selectedItem.parentKey) {
          const parent = nodes.find((node) => node.key === selectedItem.parentKey);
          const currentSelectedChildren = getCurrentSelectedNodes(nodes, nextSelectedNodes).filter(
            (selectedNode) => selectedNode.parentKey === parent?.key,
          );

          if (parent?.children?.length === currentSelectedChildren.length) {
            nextSelectedNodes = getCurrentSelectedNodes(nodes, nextSelectedNodes).filter(
              (selectedNode) => selectedNode.parentKey !== parent.key,
            );
            nextSelectedNodes.push(parent);
          }
        }
      }
      onSelect(
        nodes
          .filter((node) =>
            getCurrentSelectedNodes(nodes, nextSelectedNodes).find((selectedNode) => selectedNode.key === node.key),
          )
          .map(({ label, value, children, icon, tooltipContent }) => ({
            label,
            value,
            children,
            icon,
            tooltipContent,
          })),
        checkIsAllNodesSelected(nodes, nextSelectedNodes),
      );
    };

    const handleExpand = (selectedItemKey: string) => {
      const nextExpandedKeys = [...(treeExpandedKeys ?? expandedKeys)];

      if (checkIsExpandedNode(nextExpandedKeys, selectedItemKey)) {
        nextExpandedKeys.splice(
          nextExpandedKeys.findIndex((expandedKey) => expandedKey === selectedItemKey),
          1,
        );
      } else {
        nextExpandedKeys.push(selectedItemKey);
      }

      if (treeExpandedKeys) {
        onTreeExpand?.(nextExpandedKeys);
      } else {
        setExpandedKeys(nextExpandedKeys);
      }
    };

    useImperativeHandle(forwardedRef, () => ({ handleSelect }));

    return (
      <TreeNodes minWidth={minWidth} {...getMenuProps?.()}>
        {nodes
          .filter((node) => {
            if (node.parentKey) {
              const childNode = node;
              const parent = nodes.find((node) => node.key === childNode.parentKey);
              const isParentNodeExpanded = expandedKeys.some((expandedKey) => expandedKey === parent?.key);

              return isParentNodeExpanded;
            }
            return true;
          })
          .map((node) => {
            const isSelected = checkIsSelectedNode(getCurrentSelectedNodes(nodes, selectedNodes), node);
            const isIndeterminate =
              !!node.children && !isSelected && getCurrentSelectedNodes(node.children, selectedNodes).length > 0;
            const isExpanded = !!node.children && checkIsExpandedNode(treeExpandedKeys ?? expandedKeys, node.key);
            const selectedLeafItems = getSelectedLeafNodes(selectedNodes);
            return (
              <TreeNode
                key={node.key}
                node={node}
                isSelected={isSelected}
                isIndeterminate={isIndeterminate}
                isExpanded={isExpanded}
                notCheckable={node.notCheckable}
                disabled={maxSelectedNodes !== undefined && selectedLeafItems.length >= maxSelectedNodes && !isSelected}
                onSelect={handleSelect}
                onExpand={handleExpand}
                nodeToElement={nodeToElement}
                getItemProps={getItemProps}
              />
            );
          })}
      </TreeNodes>
    );
  },
);
