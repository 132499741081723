import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrHelpSupportFaqSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-he-a)">
        <path d="M3 19.5H21V21H3z" />
        <path
          fillRule="evenodd"
          d="M3 3h16a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V3zm9.07 3.002c-1.63 0-2.854 1.243-2.82 2.864h1.376c0-.864.536-1.515 1.421-1.515.769 0 1.305.462 1.305 1.219 0 .636-.424.96-.891 1.315-.576.439-1.218.928-1.218 2.117h1.328c.005-.54.409-.87.867-1.248.608-.499 1.312-1.078 1.312-2.326 0-1.385-1.153-2.426-2.68-2.426zM12 15c.558 0 1-.447 1-.999a.987.987 0 0 0-1-.999.98.98 0 0 0-1 .999c0 .552.429.999 1 .999z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="br-he-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrHelpSupportFaq = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrHelpSupportFaqSvg} {...props} />
));
BrHelpSupportFaq.displayName = 'BrHelpSupportFaq';
export default BrHelpSupportFaq;
