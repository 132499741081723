import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const OrganizationSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="m19 10.532-6-5V2h-2v3.532l-6 5v3.518a2.5 2.5 0 0 0-2 2.45V22h2v-5.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5V22h2v-5.5a2.5 2.5 0 0 0-2-2.45v-3.518zM17 14v-2H7v2h10zm-1.762-4L12 7.302 8.762 10h6.476z"
        clipRule="evenodd"
      />
      <path fillRule="evenodd" d="M7 22v-4h2v4H7zM11 22v-4h2v4h-2zM15 22v-4h2v4h-2z" clipRule="evenodd" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Organization = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={OrganizationSvg} {...props} />
));
Organization.displayName = 'Organization';
export default Organization;
