import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TextAlignLeftSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M3 6h18v2H3V6zM3 11h10v2H3v-2zM17 16H3v2h14v-2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const TextAlignLeft = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TextAlignLeftSvg} {...props} />
));
TextAlignLeft.displayName = 'TextAlignLeft';
export default TextAlignLeft;
