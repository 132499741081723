import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ChannelsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M9.006 2.89 8.438 8H3v2h5.216l-.445 4H3v2h4.55l-.544 4.89 1.988.22L9.56 16h3.988l-.543 4.89 1.988.22.568-5.11H21v-2h-5.216l.444-4H21V8h-4.55l.544-4.89-1.988-.22L14.438 8H10.45l.544-4.89-1.988-.22zM13.77 14l.445-4h-3.988l-.444 4h3.987z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Channels = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ChannelsSvg} {...props} />
));
Channels.displayName = 'Channels';
export default Channels;
