import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ControlSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M6 3v7H4V3h2zM6 15v6H4v-6H2v-2h6v2H6zM13 21v-9h-2v9h2zM13 3v4h2v2H9V7h2V3h2zM18 17h-2v-2h6v2h-2v4h-2v-4zM20 3v9h-2V3h2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Control = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ControlSvg} {...props} />
));
Control.displayName = 'Control';
export default Control;
