import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const FacebookSquareSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M21.6 0A2.4 2.4 0 0 1 24 2.4v19.2a2.4 2.4 0 0 1-2.4 2.4h-6v-9.035h3.075l.494-3.389H15.6V10.13c0-1.24.334-1.881 1.443-1.937l.164-.004H19.2v-3.24l-.573-.067-.279-.024-.328-.022-.38-.018-.436-.013-.494-.004c-2.846 0-4.615 1.434-4.706 4.69L12 9.756v1.82H8.4v3.388H12V24H2.4A2.4 2.4 0 0 1 0 21.6V2.4A2.4 2.4 0 0 1 2.4 0h19.2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const FacebookSquare = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={FacebookSquareSvg} {...props} />
));
FacebookSquare.displayName = 'FacebookSquare';
export default FacebookSquare;
