import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const VerticalAlignCenterSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M13 3h-2v5H5v8h6v5h2v-5h4a2 2 0 0 0 2-2V8h-6V3z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const VerticalAlignCenter = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={VerticalAlignCenterSvg} {...props} />
));
VerticalAlignCenter.displayName = 'VerticalAlignCenter';
export default VerticalAlignCenter;
