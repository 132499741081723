import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrFirecrackersSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m11.626 9.422 1.682-6.279-1.449-.388-1.682 6.279 1.449.388zM21.5 11.43l-4.11-1.077-.39 1.422 4.11 1.078.39-1.422z" />
      <path d="M16.5 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM22.002 8.004a4.915 4.915 0 0 0-6.95 0L12.1 10.956l1.06 1.06 2.952-2.951a3.415 3.415 0 0 1 4.83 0l1.06-1.061zM20 15.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM9 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM17 18.447 3.793 21.923c-1.27.334-2.05-.446-1.716-1.716L5.553 7 17 18.447z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrFirecrackers = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrFirecrackersSvg} {...props} />
));
BrFirecrackers.displayName = 'BrFirecrackers';
export default BrFirecrackers;
