import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrTheBuckStopsHereSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g fillRule="evenodd" clipPath="url(#br-th-a)" clipRule="evenodd">
        <path d="M2.003 7a4 4 0 0 0 3.804 3.995l1.88 9.397A2 2 0 0 0 9.646 22h4.716a2 2 0 0 0 1.962-1.608l1.879-9.397A4 4 0 0 0 22.003 7h-20zm7.75 13.5a2.257 2.257 0 0 1 2.25-2.25 2.257 2.257 0 0 1 2.25 2.25h-4.5z" />
        <path d="M3.002 2a2.75 2.75 0 0 0 2.727 2.394h3.775V5.5h1.5V2.894H5.729A1.25 1.25 0 0 1 4.53 2H3.002zM21.005 2a2.75 2.75 0 0 1-2.727 2.394h-3.775V5.5h-1.5V2.894h5.275A1.25 1.25 0 0 0 19.477 2h1.528z" />
      </g>
      <defs>
        <clipPath id="br-th-a">
          <path d="M0 0H24V24H0z" transform="translate(.003)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrTheBuckStopsHere = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrTheBuckStopsHereSvg} {...props} />
));
BrTheBuckStopsHere.displayName = 'BrTheBuckStopsHere';
export default BrTheBuckStopsHere;
