import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BusinessMessagingColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="color(display-p3 0.3843 0.0627 0.8000)"
        d="m2.548 13.34 5.345 1.912 9.746-8.327-8.275 9.063 8.39 4.487a.75.75 0 0 0 1.098-.567l2.162-16.92-18.54 8.97a.75.75 0 0 0 .074 1.382zM8.614 17.488v4.5l4.5-2-4.5-2.5z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const BusinessMessagingColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BusinessMessagingColoredSvg} {...props} />
));
BusinessMessagingColored.displayName = 'BusinessMessagingColored';
export default BusinessMessagingColored;
