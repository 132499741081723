import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TextAlignCenterSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M3 6h18v2H3V6zM7 11h10v2H7v-2zM19 16H5v2h14v-2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const TextAlignCenter = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TextAlignCenterSvg} {...props} />
));
TextAlignCenter.displayName = 'TextAlignCenter';
export default TextAlignCenter;
