import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ModerationSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M11.197 3.5H21v11.018a3 3 0 0 1-1.178 2.383L12.5 22.5l-7.322-5.6A3 3 0 0 1 4 14.519v-2.36A6 6 0 0 0 11.197 3.5z" />
      <path
        fillRule="evenodd"
        d="M6 11a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm1.547-6.623L5.459 6.465 4.426 5.53 3.419 6.642l2.092 1.893 3.097-3.097-1.061-1.06z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Moderation = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ModerationSvg} {...props} />
));
Moderation.displayName = 'Moderation';
export default Moderation;
