import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const FeaturesFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M3.037 6.189 12 1l8.963 5.189-2.269 1.305-.077-.133a2.927 2.927 0 0 0-3.991-1.067 2.913 2.913 0 0 0-1.07 3.986l.074.128-1.63.938-3.363-1.935L7.56 11.27a.927.927 0 0 1-1.263.336.913.913 0 0 1-.337-1.25l1.08-1.864-4.003-2.303z" />
      <path d="M2.5 8.187V17.5l8.5 4.921v-3.638h2.154a.92.92 0 0 0 .923-.916.92.92 0 0 0-.923-.917H11v-3.872l-1.632-.939-.077.133A2.927 2.927 0 0 1 5.3 13.34a2.913 2.913 0 0 1-1.07-3.986l.073-.128L2.5 8.187z" />
      <path d="m13 22.421 8.5-4.921V8.187l-3.537 2.035-1.077-1.858a.927.927 0 0 0-1.262-.336.913.913 0 0 0-.338 1.25l1.08 1.864L13 13.078v1.872h.154a2.92 2.92 0 0 1 2.923 2.917 2.92 2.92 0 0 1-2.923 2.916H13v1.638z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const FeaturesFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={FeaturesFilledSvg} {...props} />
));
FeaturesFilled.displayName = 'FeaturesFilled';
export default FeaturesFilled;
