import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ThemeLightFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path fillRule="evenodd" d="M6 12a6 6 0 1 1 12 0 6 6 0 0 1-12 0z" clipRule="evenodd" />
      <path d="M7.094 18.32a8.048 8.048 0 0 1-1.414-1.414L3.515 19.07l1.414 1.414 2.165-2.165zM11 19.938V23h2v-3.062a8.069 8.069 0 0 1-2 0zM16.906 18.32l2.165 2.165 1.414-1.414-2.165-2.165a8.048 8.048 0 0 1-1.414 1.414zM19.938 13H23v-2h-3.062a8.069 8.069 0 0 1 0 2zM18.32 7.094l2.165-2.165-1.414-1.414-2.165 2.165c.528.41 1.003.886 1.414 1.414zM13 4.062V1h-2v3.062a8.079 8.079 0 0 1 2 0zM7.094 5.68 4.93 3.515 3.515 4.929 5.68 7.094A8.048 8.048 0 0 1 7.094 5.68zM4.062 11H1v2h3.062a8.079 8.079 0 0 1 0-2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ThemeLightFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ThemeLightFilledSvg} {...props} />
));
ThemeLightFilled.displayName = 'ThemeLightFilled';
export default ThemeLightFilled;
