import type { Dispatch, MouseEvent as ReactMouseEvent, ReactNode, SetStateAction } from 'react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import styled, { css } from 'styled-components';

import { transitionDefault } from '@feather/animation';
import type { TooltipProps } from '@feather/components/tooltip';
import { Tooltip } from '@feather/components/tooltip';
import cssVariables from '@feather/theme/cssVariables';
import { Headings } from '@feather/typography';

import * as hideOutlineEventListeners from '../button/hideOutlineEventListeners';

interface TabbedInterfaceRef {
  setActiveTabIndex: Dispatch<SetStateAction<number>>;
}

export enum TabSize {
  Large = 'large',
  Medium = 'medium',
}

type Tab = {
  id: string;
  title: string;
  number?: number | string;
  disabled?: boolean;
  tooltipProps?: Omit<TooltipProps, 'children'>;
};

type TabbedInterfaceProps = {
  tabs: Tab[];
  initialActiveTabIndex?: number;
  activeTabIndex?: number;
  children: (tab: Tab, index: number, isActive: boolean) => ReactNode;
  hasBorder?: boolean;
  className?: string;
  onActiveTabChange?: (params: { tab: Tab; index: number }) => void;
  size?: TabSize;
  /**
   * If it's true, only the active tab is rendered (default: false)
   */
  unmountInactiveTabPanels?: boolean;
};

const getTabId = (id: string) => `tab-${id}`;
const getTabPanelId = (id: string) => `tabpanel-${id}`;

const TabList = styled.ul<{ $hasBorder: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  list-style: none;

  ${({ $hasBorder }) =>
    $hasBorder &&
    css`
      box-shadow: inset 0 -1px 0 0 ${cssVariables('neutral-3')};
    `}

  > * + * {
    margin-left: 24px;
  }
`;

const Tab = styled.a<{ $size: TabSize }>`
  display: block;
  position: relative;
  transition: color 0.2s ${transitionDefault};
  outline: 0;
  padding: 10px 0;
  text-decoration: none !important;
  white-space: nowrap;
  color: ${cssVariables('neutral-7')};

  && {
    // override global style (of dashboard) on anchor elements
    ${({ $size }) => ($size === TabSize.Medium ? Headings['heading-01'] : Headings['heading-03'])}
  }

  &:not(:active):focus {
    box-shadow: 0 0 0 2px ${cssVariables('purple-7')};
  }

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${cssVariables('purple-7')};
    transition: transform 0.2s ${transitionDefault};
    transform: scaleX(0);
    transform-origin: 0 0;
  }

  &:hover {
    color: ${cssVariables('purple-7')};
  }

  &[aria-selected='true'] {
    color: ${cssVariables('purple-7')};

    &::after {
      transform: scaleX(1);
    }
  }

  &[aria-disabled='true'] {
    color: ${cssVariables('neutral-5')};
    cursor: default;

    &::after {
      transform: scaleX(0);
    }
  }
`;

const Number = styled.span`
  margin-left: 8px;
  font-weight: 400;
`;

const TabPanel = styled.section`
  &:focus {
    outline: 0;
  }

  &[hidden] {
    display: none;
  }
`;

export const TabbedInterface = forwardRef<TabbedInterfaceRef, TabbedInterfaceProps>(
  (
    {
      className,
      children,
      tabs,
      initialActiveTabIndex = 0,
      activeTabIndex: controlledActiveTabIndex,
      hasBorder = false,
      onActiveTabChange,
      size = TabSize.Medium,
      unmountInactiveTabPanels = false,
    },
    ref,
  ) => {
    const [localActiveTabIndex, setLocalActiveTabIndex] = useState(initialActiveTabIndex);
    const activeTabIndex =
      typeof controlledActiveTabIndex === 'number' ? controlledActiveTabIndex : localActiveTabIndex;

    const previousActiveTabIndexRef = useRef(activeTabIndex);
    const tabsRef = useRef<(HTMLElement | null)[]>([]);
    const panelsRef = useRef<(HTMLElement | null)[]>([]);

    const isControlled = typeof controlledActiveTabIndex === 'number';

    useEffect(() => {
      tabsRef.current.length = tabs.length;
      panelsRef.current.length = tabs.length;
    }, [tabs.length]);

    useEffect(() => {
      if (previousActiveTabIndexRef.current !== activeTabIndex) {
        // Bypass initial render so that this runs only when activeTabIndex is actually changed.
        tabsRef.current[activeTabIndex]?.focus();
        previousActiveTabIndexRef.current = activeTabIndex;

        /**
         * Only run when the activeTabIndex is not controlled from outside of the component
         */
        if (!isControlled) {
          onActiveTabChange?.({ tab: tabs[activeTabIndex], index: activeTabIndex });
        }
      }
    }, [activeTabIndex, isControlled, onActiveTabChange, tabs]);

    useImperativeHandle(ref, () => ({
      setActiveTabIndex: setLocalActiveTabIndex,
    }));

    const updateActiveTabIndex = (params: { tab: Tab; index: number }) => {
      if (isControlled) {
        onActiveTabChange?.(params);
        return;
      }
      setLocalActiveTabIndex(params.index);
    };

    const handleClick =
      (params: { tab: Tab; index: number }) => (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();

        if (!params.tab.disabled) {
          updateActiveTabIndex(params);
        }
      };

    const renderTabPanel = useCallback(
      (tab: Tab | undefined, index: number) => {
        if (!tab) return null;

        const { id } = tab;
        return (
          <TabPanel
            key={`tabpanel-${id}`}
            id={getTabPanelId(id)}
            role="tabpanel"
            aria-labelledby={getTabId(id)}
            tabIndex={-1}
            hidden={activeTabIndex !== index}
            ref={(node) => {
              panelsRef.current[index] = node;
            }}
            onKeyDown={(event) => {
              switch (event.key) {
                case 'Up': // IE/Edge specific value
                case 'ArrowUp':
                  tabsRef.current[activeTabIndex]?.focus();
                  break;
                default:
                  break;
              }
            }}
          >
            {children(tab, index, activeTabIndex === index)}
          </TabPanel>
        );
      },
      [activeTabIndex, children],
    );

    return (
      <div className={className}>
        <TabList role="tablist" $hasBorder={hasBorder}>
          {tabs.map((tab, index) => {
            const content = (
              <li key={`tab-${tab.id}`} role="presentation">
                <Tab
                  id={getTabId(tab.id)}
                  role="tab"
                  href={getTabPanelId(tab.id)}
                  ref={(node) => {
                    tabsRef.current[index] = node;
                  }}
                  tabIndex={activeTabIndex !== index || tab.disabled ? -1 : undefined}
                  aria-selected={activeTabIndex === index}
                  aria-disabled={tab.disabled}
                  onClick={handleClick({ tab, index })}
                  onKeyDown={(event) => {
                    switch (event.key) {
                      case 'Down': // IE/Edge specific value
                      case 'ArrowDown':
                        panelsRef.current[index]?.focus();
                        break;
                      case 'Left': // IE/Edge specific value
                      case 'Right': // IE/Edge specific value
                      case 'ArrowLeft':
                      case 'ArrowRight':
                        {
                          const direction = event.key.includes('Left') ? -1 : 1;
                          let currentTabIndex = index + direction;
                          while (
                            currentTabIndex >= 0 &&
                            currentTabIndex < tabs.length &&
                            tabs[currentTabIndex].disabled
                          ) {
                            currentTabIndex += direction;
                          }

                          if (currentTabIndex >= 0 && currentTabIndex < tabs.length) {
                            updateActiveTabIndex({ tab: tabs[currentTabIndex], index: currentTabIndex });
                          }
                        }
                        break;
                      default:
                        break;
                    }
                  }}
                  $size={size}
                  {...hideOutlineEventListeners}
                >
                  {tab.title}
                  {(typeof tab.number === 'number' || typeof tab.number === 'string') && (
                    <Number>({String(tab.number)})</Number>
                  )}
                </Tab>
              </li>
            );
            if (tab.tooltipProps) {
              return (
                <Tooltip key={tab.id} {...tab.tooltipProps}>
                  {content}
                </Tooltip>
              );
            }
            return content;
          })}
        </TabList>

        {unmountInactiveTabPanels ? renderTabPanel(tabs[activeTabIndex], activeTabIndex) : tabs.map(renderTabPanel)}
      </div>
    );
  },
);
