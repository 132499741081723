import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const OpenAiColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 20"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#0F0F0F"
        d="M7.222 2.667a3.76 3.76 0 0 0-3.574 2.592 3.716 3.716 0 0 0-2.477 1.798 3.745 3.745 0 0 0 .463 4.39 3.689 3.689 0 0 0 .318 3.035 3.754 3.754 0 0 0 4.037 1.803 3.708 3.708 0 0 0 2.79 1.248 3.76 3.76 0 0 0 3.574-2.593 3.696 3.696 0 0 0 2.472-1.797 3.737 3.737 0 0 0-.458-4.39v-.005a3.704 3.704 0 0 0-.318-3.04 3.755 3.755 0 0 0-4.032-1.798 3.72 3.72 0 0 0-2.795-1.243zm0 .966-.005.005a2.79 2.79 0 0 1 1.788.645c-.02.01-.06.035-.09.05L5.953 6.04a.482.482 0 0 0-.242.423v4.007L4.44 9.735V6.423a2.788 2.788 0 0 1 2.783-2.79zM10.788 4.8A2.787 2.787 0 0 1 13.54 8.06c-.02-.015-.06-.035-.085-.05l-2.96-1.712a.496.496 0 0 0-.488 0L6.537 8.3V6.83l2.865-1.656c.422-.244.9-.373 1.386-.374zM3.467 6.322V9.84c0 .176.09.332.242.423l3.463 1.998-1.279.74-2.86-1.651a2.788 2.788 0 0 1 .434-5.03zm6.635.87 2.865 1.652a2.783 2.783 0 0 1 1.016 3.806l.005.005c-.327.564-.84.997-1.45 1.218v-3.519a.482.482 0 0 0-.241-.423L8.828 7.928l1.274-.735zM7.998 8.407l1.46.846v1.686l-1.46.846-1.46-.846V9.252l1.46-.846zm2.295 1.33 1.274.734v3.308c0 1.54-1.248 2.789-2.784 2.789v-.005c-.65 0-1.284-.227-1.782-.645l.09-.05 2.96-1.707a.471.471 0 0 0 .242-.423V9.735zm-.83 2.164v1.47L6.598 15.02a2.792 2.792 0 0 1-3.806-1.017h.005a2.764 2.764 0 0 1-.332-1.863c.02.015.06.036.085.05l2.96 1.712a.496.496 0 0 0 .489 0L9.463 11.9z"
      />
    </svg>
  )),
  { width: 16, height: 20 },
);
const OpenAiColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={OpenAiColoredSvg} {...props} />
));
OpenAiColored.displayName = 'OpenAiColored';
export default OpenAiColored;
