import { css } from 'styled-components';

// https://css-tricks.com/snippets/css/system-font-stack/

export const typeface = {
  system:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Arial, sans-serif",
  avenir: "'Avenir Next', 'Helvetica Neue', helvetica, Arial, sans-serif",
  robotoMono: "'Roboto Mono', 'Andale Mono', Consolas, Monaco, 'Ubuntu Mono', monospace",
};

export const Typography = {
  'label-01': css`
    font-family: ${typeface.system};
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    line-height: 1.09;
  `,
  'label-02': css`
    font-family: ${typeface.system};
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
  `,
  'label-03': css`
    font-family: ${typeface.system};
    font-size: 13px;
    font-weight: 600;
    line-height: 1.23;
  `,
  'caption-01': css`
    font-family: ${typeface.system};
    font-size: 12px;
    font-weight: 400;
    line-height: 1.33;
  `,
  'code-01': css`
    font-family: ${typeface.robotoMono};
    font-size: 13px;
    font-weight: 400;
    line-height: 1.54;
    letter-spacing: -0.3px;
  `,
  'unit-01': css`
    font-family: ${typeface.system};
    font-size: 10px;
    font-weight: 400;
    line-height: 1.2;
  `,
};

export const Headings = {
  'heading-01': css`
    font-family: ${typeface.system};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    letter-spacing: -0.1px;
  `,
  'heading-02': css`
    font-family: ${typeface.system};
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: -0.15px;
  `,
  'heading-03': css`
    font-family: Gellix, ${typeface.system};
    font-size: 18px;
    font-weight: 700;
    line-height: 1.33;
    letter-spacing: -0.25px;
  `,
  'heading-04': css`
    font-family: Gellix, ${typeface.system};
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.25px;
    &:before {
      content: '';
      display: table;
      margin-bottom: -2px;
    }
    &:after {
      content: '';
      display: table;
      margin-top: 2px;
    }
  `,
  'heading-05': css`
    font-family: Gellix, ${typeface.system};
    font-size: 24px;
    font-weight: 700;
    line-height: 1.33;
    letter-spacing: -0.25px;
    &:before {
      content: '';
      display: table;
      margin-bottom: -1px;
    }
    &:after {
      content: '';
      display: table;
      margin-top: 1px;
    }
  `,
  'heading-06': css`
    font-family: Gellix, ${typeface.system};
    font-size: 36px;
    font-weight: 700;
    line-height: 1.22;
    letter-spacing: -0.5px;
    &:before {
      content: '';
      display: table;
      margin-bottom: -2px;
    }
    &:after {
      content: '';
      display: table;
      margin-top: 2px;
    }
  `,
  'heading-07': css`
    font-family: Gellix, ${typeface.system};
    font-size: 44px;
    font-weight: 600;
    line-height: 1.27;
    letter-spacing: -1px;
    &:before {
      content: '';
      display: table;
      margin-bottom: -3px;
    }
    &:after {
      content: '';
      display: table;
      margin-top: 3px;
    }
  `,
  'heading-08': css`
    font-family: Gellix, ${typeface.system};
    font-size: 56px;
    font-weight: 700;
    line-height: 1.08;
    letter-spacing: -1.5px;
    &:before {
      content: '';
      display: table;
      margin-bottom: -4px;
    }
    &:after {
      content: '';
      display: table;
      margin-top: 4px;
    }
  `,
};

export const Subtitles = {
  'subtitle-01': css`
    font-family: ${typeface.system};
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: -0.1px;
  `,
  'subtitle-02': css`
    font-family: ${typeface.system};
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.15px;
  `,
  'subtitle-03': css`
    font-family: ${typeface.system};
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: -0.25px;
  `,
};

export const Body = {
  'body-short-01': css`
    font-family: ${typeface.system};
    font-size: 14px;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: -0.1px;
  `,
  'body-short-02': css`
    font-family: ${typeface.system};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.15px;
  `,
  'body-long-02': css`
    font-family: ${typeface.system};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.15px;
    line-height: 28px;
  `,
  'body-03': css`
    font-family: ${typeface.system};
    font-size: 18px;
    font-weight: 400;
    line-height: 1.56;
    letter-spacing: -0.25px;
  `,
  'body-04': css`
    font-family: ${typeface.system};
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: -0.25px;
  `,
};
