import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const FlutterColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#54C5F8"
        d="M21.384 11.077H14L7.54 17.54l3.69 3.692 10.154-10.154zM5.693 15.693 2 12 14 0h7.384L5.693 15.693z"
      />
      <path fill="#01579B" d="M11.23 21.23 14 24h7.384l-6.46-6.46-3.694 3.69z" />
      <path fill="url(#flutt-a)" d="m11.23 21.23 5.477-1.895-1.784-1.796-3.693 3.691z" />
      <path fill="#29B6F6" d="M7.538 17.538H12.759V22.759H7.538z" transform="rotate(-45 7.538 17.538)" />
      <defs>
        <linearGradient id="flutt-a" x1={14.348} x2={15.854} y1={22.871} y2={19.555} gradientUnits="userSpaceOnUse">
          <stop stopColor="#1A237E" stopOpacity={0.4} />
          <stop offset={1} stopColor="#1A237E" stopOpacity={0.01} />
        </linearGradient>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const FlutterColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={FlutterColoredSvg} {...props} />
));
FlutterColored.displayName = 'FlutterColored';
export default FlutterColored;
