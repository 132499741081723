import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const StarFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        strokeWidth={2}
        d="M12.518 17.022 12 16.708l-.518.314-4.77 2.892 1.268-5.458.136-.585-.453-.395-4.226-3.678 5.557-.474.602-.051.234-.556L12 3.573l2.17 5.144.234.556.602.051 5.557.474-4.226 3.678-.453.394.136.586 1.267 5.458-4.768-2.892z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const StarFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={StarFilledSvg} {...props} />
));
StarFilled.displayName = 'StarFilled';
export default StarFilled;
