import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const MembersFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M4 6.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zM7 10a5 5 0 0 1 5 5H2a5 5 0 0 1 5-5zM17 15a5 5 0 0 1 5 5H12a5 5 0 0 1 5-5zM17 8.5a3 3 0 1 0 0 6 3 3 0 0 0 0-6z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const MembersFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={MembersFilledSvg} {...props} />
));
MembersFilled.displayName = 'MembersFilled';
export default MembersFilled;
