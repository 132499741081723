import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const PollsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m16.158 14.753-1.315-1.506-2.78 2.426-.905-.79-1.315 1.507 2.22 1.937 4.095-3.574z" />
      <path
        fillRule="evenodd"
        d="M1.976 2h8.473l4.046 4.551A2 2 0 0 1 15 7.881V9h4v11h3v2H4v-2h3v-5.364a5.234 5.234 0 0 1-2.073-1.484l-.233-.274-2.531-3.195L1.976 2zM13 7.88V9H8.45l-.578-1.305-1.828.81.839 1.893 1.039 2.445a3.233 3.233 0 0 1-1.47-.985l-.211-.249-2.095-2.644L4.025 4H9.55l3.45 3.88zm-2.807 5.193L9.312 11H17v9H9v-5.002a1.385 1.385 0 0 0 1.193-1.925z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Polls = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={PollsSvg} {...props} />
));
Polls.displayName = 'Polls';
export default Polls;
