import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrUserConversationsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-us-a)">
        <path d="M10.992 11.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM2.992 21a8 8 0 1 1 16 0h-16z" />
        <path d="M22.992 5.5h-6.5V4h6.5v1.5zM22.992 9h-6.5V7.5h6.5V9zM22.992 12.5h-5V11h5v1.5z" />
      </g>
      <defs>
        <clipPath id="br-us-a">
          <path d="M0 0H24V24H0z" transform="translate(.992)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrUserConversations = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrUserConversationsSvg} {...props} />
));
BrUserConversations.displayName = 'BrUserConversations';
export default BrUserConversations;
