import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DeskNoteSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M22 4H2v13.5A2.5 2.5 0 0 0 4.5 20h15a2.5 2.5 0 0 0 2.5-2.5V15h-2v2.5a.5.5 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5V6h16v1h2V4z" />
      <path d="m15.589 14.646 6.28-6.28a1.248 1.248 0 1 1 1.765 1.765l-6.28 6.28L15 17l.589-2.354zM17 11H6V9h11v2zM6 15h6v-2H6v2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const DeskNote = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DeskNoteSvg} {...props} />
));
DeskNote.displayName = 'DeskNote';
export default DeskNote;
