import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ShareSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12.5 4.584a2.917 2.917 0 1 1 .706 1.903L7.413 9.706c.05.204.08.418.086.637l5.707 3.17a2.917 2.917 0 1 1-.683 1.527l-5.485-3.047a2.917 2.917 0 1 1-.477-3.72l5.963-3.313a2.951 2.951 0 0 1-.024-.376zm2.916-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5zM4.583 9.167a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5zm9.583 6.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 20, height: 20 },
);
const Share = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ShareSvg} {...props} />
));
Share.displayName = 'Share';
export default Share;
