import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const EditSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="m4.003 18.954 6.434-1.367 11.56-11.56-3.3-3.3a2.5 2.5 0 0 0-3.535 0L5.37 12.52l-1.367 6.434zm13.28-14.812 1.885 1.885-9.727 9.727-2.842.604.604-2.843 9.373-9.373a.5.5 0 0 1 .707 0z"
        clipRule="evenodd"
      />
      <path d="M20 20H4v2h16v-2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Edit = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={EditSvg} {...props} />
));
Edit.displayName = 'Edit';
export default Edit;
