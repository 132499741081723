import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AgentsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 4a8 8 0 0 0-8 8v4a3 3 0 1 1 6 0v3a3 3 0 1 1-6 0v-.5H2V12C2 6.477 6.477 2 12 2s10 4.477 10 10v6.5h-2v.5a3 3 0 1 1-6 0v-3a3 3 0 1 1 6 0v-4a8 8 0 0 0-8-8zM8 16v3a1 1 0 1 1-2 0v-3a1 1 0 1 1 2 0zm10 3v-3a1 1 0 1 0-2 0v3a1 1 0 1 0 2 0z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Agents = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AgentsSvg} {...props} />
));
Agents.displayName = 'Agents';
export default Agents;
