import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrazeSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M11.037 13.455c.163-1.564 1.145-2.837 2.29-2.837 1.146 0 1.764 1.273 1.6 2.837-.163 1.564-1.109 2.854-2.182 2.854-1.072 0-1.945-.982-1.71-2.854m.964 7.09a8.527 8.527 0 0 1-6.582-3.09c1.418.454 3 .054 4.237-1.164.13-.13.252-.27.363-.418.564 1.218 1.655 1.837 2.745 1.837 1.855 0 3.418-1.91 3.6-4.273.183-2.363-1.145-4.236-2.909-4.236-.64-.009-1.27.16-1.818.49l.546-3.182a.891.891 0 0 0-.164-.727c-.163-.255-.31-.363-.527-.363H8.945a.309.309 0 0 0-.291.272c-.068.3-.117.604-.145.91-.018.2.127.272.29.272h1.855a485.48 485.48 0 0 1-1.037 6.237 4.499 4.499 0 0 1-1.182 2.237c-1.018 1.018-2.89 1.255-4 .145-.382-.4-.982-1.382-.982-3.49a8.545 8.545 0 1 1 17.09 0 8.545 8.545 0 0 1-8.545 8.545m0-18.545C6.478 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 12 2" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Braze = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrazeSvg} {...props} />
));
Braze.displayName = 'Braze';
export default Braze;
