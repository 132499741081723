import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrMarketplaceSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-ma-a)">
        <path
          fillRule="evenodd"
          d="M22.992 8h-20l1.74 11.304A2 2 0 0 0 6.707 21h12.568a2 2 0 0 0 1.977-1.696L22.993 8zm-15.25 4h1.5v5h-1.5v-5zm6 0h-1.5v5h1.5v-5zm4.5 0h-1.5v5h1.5v-5z"
          clipRule="evenodd"
        />
        <path d="M18.492 6.5V5a1.5 1.5 0 0 0-1.5-1.5h-8a1.5 1.5 0 0 0-1.5 1.5v1.5h-1.5V5a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v1.5h-1.5z" />
      </g>
      <defs>
        <clipPath id="br-ma-a">
          <path d="M0 0H24V24H0z" transform="translate(.992)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrMarketplace = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrMarketplaceSvg} {...props} />
));
BrMarketplace.displayName = 'BrMarketplace';
export default BrMarketplace;
