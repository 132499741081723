import type { HTMLAttributes, MouseEventHandler, MouseEvent as ReactMouseEvent, ReactNode, Ref } from 'react';
import { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import type { Placement } from 'popper.js';
import type { PopperChildrenProps, PopperProps } from 'react-popper';
import { Manager, Popper, Reference } from 'react-popper';
import styled from 'styled-components';

import type { TooltipProps, TooltipRef } from './types';
import { TooltipTrigger } from './types';

enum Direction {
  Top = 0,
  Right,
  Bottom,
  Left,
}

type TooltipWrapperProps = {
  children: (
    props: PopperChildrenProps & {
      onTooltipReferenceMouseLeave: MouseEventHandler<HTMLDivElement>;
    },
  ) => ReactNode;
  target: TooltipProps['children'];
  tooltipRef?: Ref<TooltipRef>;
} & Omit<TooltipProps, 'children'>;

const defaultPopperModifiers: Partial<PopperProps['modifiers']> = {
  preventOverflow: { enabled: true, boundariesElement: 'viewport' },
  offset: { offset: '0, 4' },
};

const getDirection = (ev: ReactMouseEvent, obj: HTMLElement) => {
  const { left, top, width, height } = obj.getBoundingClientRect();
  const x = (ev.clientX - left - width / 2) * (width > height ? height / width : 1);
  const y = (ev.clientY - top - height / 2) * (height > width ? width / height : 1);

  // 0 = Top, 1 = Right, 2 = Bottom, 3 = Left
  const d = Math.round(Math.atan2(y, x) / 1.57079633 + 5) % 4;

  return d as Direction;
};

const getReverseDirection = (direction: Direction) => {
  return ((direction + 2) % 4) as Direction;
};

const getPopperPlacementDirection = (placement: Placement) => {
  if (placement.includes('top')) {
    return Direction.Top;
  }
  if (placement.includes('right')) {
    return Direction.Right;
  }
  if (placement.includes('bottom')) {
    return Direction.Bottom;
  }
  return Direction.Left;
};

export const TooltipReference = styled.div``;

export const TooltipWrapper = ({
  trigger = TooltipTrigger.Hover,
  closeOnClickOutside = true,
  popperProps,
  className,
  target,
  placement = 'bottom',
  tooltipRef,
  content,
  portalId,
  disabled,
  isTooltipHoverable = true,
  unmountOnHide = true,
  children,
}: TooltipWrapperProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const referenceNodeRef = useRef<HTMLDivElement | null>(null);
  const tooltipNodeRef = useRef<HTMLDivElement | null>(null);
  const tooltipPlacementRef = useRef<Placement>();
  const scheduleUpdateRef = useRef<() => void>();

  useEffect(() => {
    scheduleUpdateRef.current && scheduleUpdateRef.current();
  }, [content]);

  const showTooltip = useCallback(() => {
    setIsOpen(true);
  }, []);

  const hideTooltip = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (trigger === TooltipTrigger.Click && closeOnClickOutside) {
      const clickOutsideEventListener = (event: MouseEvent) => {
        if (event.target instanceof Node) {
          if (tooltipNodeRef.current == null || tooltipNodeRef.current.contains(event.target)) {
            return;
          }
          if (referenceNodeRef.current == null || referenceNodeRef.current.contains(event.target)) {
            return;
          }
          hideTooltip();
        }
      };
      document.addEventListener('mousedown', clickOutsideEventListener);
      return () => {
        document.removeEventListener('mousedown', clickOutsideEventListener);
      };
    }
  }, [closeOnClickOutside, hideTooltip, trigger]);

  useImperativeHandle(tooltipRef, () => {
    return {
      show: showTooltip,
      hide: hideTooltip,
    };
  });

  const onReferenceMouseEnter: MouseEventHandler<HTMLDivElement> = () => {
    if (trigger === TooltipTrigger.Hover) {
      showTooltip();
    }
  };

  const onTooltipReferenceMouseLeave: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (trigger !== TooltipTrigger.Hover || tooltipNodeRef.current == null || referenceNodeRef.current == null) {
        return;
      }
      if (tooltipPlacementRef.current == null || !isTooltipHoverable) {
        hideTooltip();
        return;
      }
      const { current: tooltip } = tooltipNodeRef;
      const { current: reference } = referenceNodeRef;
      const { current: placement } = tooltipPlacementRef;

      const placementDirection = getPopperPlacementDirection(placement);
      const eventDirection = getDirection(event, event.currentTarget);

      /**
       * Let's say the area between the reference and the tooltip "bridge".
       * If the event target is the reference, bridgeDirection equals with placementDirection.
       */
      const bridgeDirection =
        event.currentTarget === reference ? placementDirection : getReverseDirection(placementDirection);

      if (eventDirection !== bridgeDirection) {
        hideTooltip();
        return;
      }

      /**
       * If eventDirection equals to bridgeDirection, compare the event coordinates and check if it is on the bridge.
       * If the event happens outside of the bridge, hide the tooltip.
       */

      const tooltipRect = tooltip.getBoundingClientRect();
      const referenceRect = reference.getBoundingClientRect();

      if (bridgeDirection === Direction.Top || bridgeDirection === Direction.Bottom) {
        if (
          event.clientX <= Math.max(tooltipRect.left, referenceRect.left) ||
          event.clientX >= Math.min(tooltipRect.right, referenceRect.right)
        ) {
          hideTooltip();
        }
      } else {
        if (
          event.clientY <= Math.max(tooltipRect.top, referenceRect.top) ||
          event.clientY >= Math.min(tooltipRect.bottom, referenceRect.bottom)
        ) {
          hideTooltip();
        }
      }
    },
    [hideTooltip, isTooltipHoverable, trigger],
  );

  const onReferenceClick: MouseEventHandler<HTMLDivElement> = () => {
    if (trigger !== TooltipTrigger.Click) {
      return;
    }

    if (!isOpen) {
      showTooltip();
      return;
    }
    if (tooltipNodeRef.current) {
      if (tooltipNodeRef.current.style.visibility === 'hidden') {
        showTooltip();
      } else {
        hideTooltip();
      }
    }
  };

  const referenceWrapperAttributes: Partial<HTMLAttributes<HTMLDivElement>> = {
    className,
    onMouseEnter: onReferenceMouseEnter,
    onMouseLeave: onTooltipReferenceMouseLeave,
    ...(trigger === TooltipTrigger.Click ? { onClick: onReferenceClick, role: 'button' } : null),
  };

  const { modifiers: popperModifiers, ...restPopperProps } = popperProps || {};
  const modifiers = useMemo(() => ({ ...defaultPopperModifiers, ...popperModifiers }), [popperModifiers]);
  const popper = useMemo(() => {
    return (
      <Popper
        innerRef={(node) => {
          tooltipNodeRef.current = node as HTMLDivElement;
        }}
        positionFixed={true}
        placement={placement}
        {...restPopperProps}
        modifiers={modifiers}
      >
        {({ style, ...popperChildrenProps }) => {
          if (disabled || (unmountOnHide && !isOpen)) {
            return null;
          }

          tooltipPlacementRef.current = popperChildrenProps.placement;
          scheduleUpdateRef.current = popperChildrenProps.scheduleUpdate;

          return disabled
            ? null
            : children({
                ...popperChildrenProps,
                onTooltipReferenceMouseLeave,
                style: { ...style, visibility: isOpen ? 'visible' : 'hidden' },
              });
        }}
      </Popper>
    );
  }, [placement, restPopperProps, modifiers, disabled, unmountOnHide, isOpen, children, onTooltipReferenceMouseLeave]);

  const renderPopper = () => {
    if (!portalId) {
      return popper;
    }

    const portalContainer = document.getElementById(portalId);
    return portalContainer && createPortal(popper, portalContainer);
  };

  const renderTarget = () => (typeof target === 'function' ? target({ isOpen }) : target);

  return (
    <Manager>
      <Reference innerRef={referenceNodeRef}>
        {({ ref }) => (
          <TooltipReference data-test-id="TooltipReference" ref={ref} {...referenceWrapperAttributes}>
            {renderTarget()}
          </TooltipReference>
        )}
      </Reference>
      {renderPopper()}
    </Manager>
  );
};
