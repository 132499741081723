import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TicketsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M9 9H7v2h2V9zM7 13h2v2H7v-2z" />
      <path
        fillRule="evenodd"
        d="M2 14a2 2 0 1 0 0-4V4h20v16H2v-6zm18-8H9v1H7V6H4v2.535C5.196 9.227 6 10.52 6 12c0 1.48-.804 2.773-2 3.465V18h3v-1h2v1h11V6z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Tickets = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TicketsSvg} {...props} />
));
Tickets.displayName = 'Tickets';
export default Tickets;
