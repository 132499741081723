import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const OrganizationFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M3 16.5A2.5 2.5 0 0 1 5.5 14h13a2.5 2.5 0 0 1 2.5 2.5V22h-4v-4h-2v4h-2v-4h-2v4H9v-4H7v4H3v-5.5zM13 4.833 18 9H6l5-4.167V2h2v2.833zM6 13v-3h12v3H6z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const OrganizationFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={OrganizationFilledSvg} {...props} />
));
OrganizationFilled.displayName = 'OrganizationFilled';
export default OrganizationFilled;
