import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DeskColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#6210CC"
        d="M23 11.947c0-2.325-1.929-4.21-4.308-4.21v9.09c0 2.247-1.863 4.068-4.16 4.068H9.537V23h4.993c3.488 0 6.315-2.764 6.315-6.172v-1.233c1.288-.728 2.154-2.09 2.154-3.648z"
      />
      <path
        fill="#6210CC"
        d="M2 16.158c0 1.744 1.446 3.158 3.23 3.158h8.616c1.784 0 3.23-1.414 3.23-3.158V3H5.232C3.446 3 2 4.414 2 6.158v10z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const DeskColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DeskColoredSvg} {...props} />
));
DeskColored.displayName = 'DeskColored';
export default DeskColored;
