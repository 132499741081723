import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const WarningTwotoneSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path id="Color_02" d="M12.05 1.85 1 20.9h22.1L12.05 1.85z" />
      <path id="Color_01" d="M11 9v6h2V9h-2zM12 16a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const WarningTwotone = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={WarningTwotoneSvg} {...props} />
));
WarningTwotone.displayName = 'WarningTwotone';
export default WarningTwotone;
