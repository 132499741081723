import type { ReactNode } from 'react';

import type { PropGetters } from 'downshift';
import styled from 'styled-components';

import { Checkbox } from '@feather/components/checkbox';
import * as Icons from '@feather/components/icons';
import cssVariables from '@feather/theme/cssVariables';
import { Body, Subtitles } from '@feather/typography';

import type { TreeNodeItem } from '.';

const TreeNodeListItem = styled.li<{ level: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  padding-left: ${({ level }) => 4 + level * 40}px;
`;

const TreeNodeFlatListItem = styled.li<{ level?: number }>`
  padding: 6px 16px;

  ${Subtitles['subtitle-01']};
  color: ${cssVariables('neutral-10')};

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background: ${cssVariables('neutral-1')};
  }
`;

const TreeNodeCheckbox = styled(Checkbox)`
  margin-right: 8px;

  label {
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const TreeNodeText = styled.span`
  ${Body['body-short-01']};
  color: ${cssVariables('content-1')};
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 8px;
  margin-left: 8px;
`;

type TreeNodeProps = {
  node: TreeNodeItem;
  isSelected: boolean;
  isIndeterminate: boolean;
  isExpanded: boolean;
  disabled?: boolean;
  notCheckable?: boolean;
  onSelect: (selectedNode: TreeNodeItem) => void;
  onExpand: (selectedNodeKey: string) => void;
  nodeToElement?: (node: TreeNodeItem) => ReactNode;

  // FIXME: this is not general function
  getItemProps?: PropGetters<TreeNodeItem>['getItemProps'];
};

export const TreeNode = ({
  node,
  isSelected,
  isIndeterminate,
  isExpanded,
  disabled = false,
  notCheckable = false,
  onSelect,
  onExpand,
  nodeToElement,
  getItemProps,
}: TreeNodeProps) => {
  const handleNodeWrapperClick = () => {
    onSelect(node);
  };

  const handleExpandIconClick = (event) => {
    event.stopPropagation();
    onExpand(node.key);
  };

  const Container = node.children || node.level > 0 ? TreeNodeListItem : TreeNodeFlatListItem;
  const ExpandIcon = isExpanded ? Icons.InputArrowUp : Icons.InputArrowDown;

  return (
    <Container
      level={node.level}
      {...getItemProps?.({ item: node, onClick: notCheckable ? undefined : handleNodeWrapperClick })}
      data-test-id="TreeNodeListItem"
    >
      {node.children && (
        <ExpandIcon
          size={20}
          data-test-id="ExpandIcon"
          onClick={handleExpandIconClick}
          style={{ marginLeft: 4, cursor: 'pointer' }}
        />
      )}
      {notCheckable && <TreeNodeText>{nodeToElement ? nodeToElement(node) : node.label}</TreeNodeText>}
      {!notCheckable && (
        <TreeNodeCheckbox
          label={nodeToElement ? nodeToElement(node) : node.label}
          checked={isSelected}
          readOnly={true}
          disabled={disabled}
          indeterminate={isIndeterminate}
          data-test-id="TreeNodeCheckbox"
        />
      )}
    </Container>
  );
};
