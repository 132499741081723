import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TextBoldSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M3.854 13.76V2.24h4.785c2.236 0 3.58 1.102 3.58 2.866v.016c0 1.198-.915 2.275-2.087 2.467v.136c1.609.167 2.681 1.27 2.681 2.77v.016c0 2.02-1.526 3.25-4.067 3.25H3.854zM8.21 3.701H5.702v3.441h2.194c1.634 0 2.484-.59 2.484-1.708v-.016c0-1.086-.8-1.717-2.17-1.717zm.058 4.806H5.702V12.3h2.673c1.666 0 2.55-.662 2.55-1.9v-.016c0-1.237-.917-1.876-2.657-1.876z" />
    </svg>
  )),
  { width: 16, height: 16 },
);
const TextBold = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TextBoldSvg} {...props} />
));
TextBold.displayName = 'TextBold';
export default TextBold;
