import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const MicFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M12 .996a4.25 4.25 0 0 0-4.25 4.25v6.5a4.25 4.25 0 0 0 8.5 0v-6.5A4.25 4.25 0 0 0 12 .996z" />
      <path d="M17.5 12a5.5 5.5 0 1 1-11 0h-2a7.501 7.501 0 0 0 6.5 7.434V21H8v2h8v-2h-3v-1.566A7.501 7.501 0 0 0 19.5 12h-2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const MicFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={MicFilledSvg} {...props} />
));
MicFilled.displayName = 'MicFilled';
export default MicFilled;
