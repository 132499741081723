import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UploadSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M7.707 9.121 11 5.828v10.647h2V5.828l3.293 3.293 1.414-1.414L12 2 6.293 7.707l1.414 1.414z" />
      <path d="M4 19.5V15H2v4.5A2.5 2.5 0 0 0 4.5 22h15a2.5 2.5 0 0 0 2.5-2.5V15h-2v4.5a.5.5 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Upload = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UploadSvg} {...props} />
));
Upload.displayName = 'Upload';
export default Upload;
