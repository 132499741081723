import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const FeaturesSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="m12 1.156 9.5 5.48V17.6L12 23.08 2.5 17.6V6.636l9.5-5.48zM5.504 7.212l2.483 1.434-.99 1.715a.635.635 0 1 0 1.098.635l.99-1.715L12 10.963l1.182-.682a2.635 2.635 0 0 1 4.563-2.635l.751-.434L12 3.464 5.504 7.212zM19.5 8.942v7.502l-6.5 3.75v-.865a2.635 2.635 0 0 0 0-5.269v-1.365l2.914-1.682-.99-1.716a.635.635 0 1 1 1.099-.634l.99 1.715L19.5 8.942zM11 12.695l-1.182-.683a2.635 2.635 0 0 1-4.563-2.634L4.5 8.942v7.502l6.5 3.75v-2.865h1.98a.635.635 0 1 0 0-1.269H11v-3.365z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Features = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={FeaturesSvg} {...props} />
));
Features.displayName = 'Features';
export default Features;
