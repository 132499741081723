import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const MuteSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="m16.586 18 5 5L23 21.586 2.418 1.004 1.004 2.418l1.168 1.168A2.494 2.494 0 0 0 2 4.5V22l4-4h10.586zm-2-2L4 5.414v11.758L5.17 16h9.415z"
        clipRule="evenodd"
      />
      <path d="M22 15.5a2.49 2.49 0 0 1-.623 1.65l-1.429-1.428A.498.498 0 0 0 20 15.5v-11a.5.5 0 0 0-.5-.5H8.227l-2-2H19.5A2.5 2.5 0 0 1 22 4.5v11z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Mute = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={MuteSvg} {...props} />
));
Mute.displayName = 'Mute';
export default Mute;
