import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DeletedChannelFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M6 15.5v1A2.5 2.5 0 0 0 8.5 19h10.086L22 22.414V9a2.5 2.5 0 0 0-2.5-2.5H19v2h.5a.5.5 0 0 1 .5.5v8.586L19.414 17H8.5a.5.5 0 0 1-.5-.5v-1H6z" />
      <path
        fillRule="evenodd"
        d="M2 4.5A2.5 2.5 0 0 1 4.5 2h11A2.5 2.5 0 0 1 18 4.5V12a2.5 2.5 0 0 1-2.5 2.5H5.414L2 17.914V4.5zm10.959 5.298-1.545-1.546 1.545-1.545-1.414-1.414L10 6.838 8.454 5.293 7.04 6.707l1.545 1.545L7.04 9.798l1.414 1.414L10 9.667l1.545 1.545 1.414-1.414z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const DeletedChannelFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DeletedChannelFilledSvg} {...props} />
));
DeletedChannelFilled.displayName = 'DeletedChannelFilled';
export default DeletedChannelFilled;
