import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const SuccessTwotoneSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        id="Color_02"
        fillRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
        clipRule="evenodd"
      />
      <path id="Color_01" d="m16.343 8.247-6.218 5.426-2.467-2.154-1.315 1.507 3.782 3.301 7.533-6.574-1.315-1.506z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const SuccessTwotone = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={SuccessTwotoneSvg} {...props} />
));
SuccessTwotone.displayName = 'SuccessTwotone';
export default SuccessTwotone;
