import parseToRgb from 'polished/lib/color/parseToRgb';
import type { RgbaColor } from 'polished/lib/types/color';
import { css } from 'styled-components';

import { transitions } from '@feather/animation';
import cssVariables from '@feather/theme/cssVariables';
import { FeatherThemeEnum } from '@feather/themes';
import type {
  ButtonContainerStyleGenerator,
  ButtonSizeStyleGenerator,
  GetContainerGeneratorOptions,
} from '@feather/types';

const buttonSizeStyleMap = {
  xsmall: { minWidth: 56, horizontalPadding: 4, height: 24, fontSize: 12, lineHeight: 12 },
  small: { minWidth: 80, horizontalPadding: 16, height: 32, fontSize: 14, lineHeight: 20 },
  medium: { minWidth: 80, horizontalPadding: 16, height: 40, fontSize: 14, lineHeight: 20 },
  large: { minWidth: 132, horizontalPadding: 16, height: 48, fontSize: 18, lineHeight: 24 },
};

export const generateButtonSizeStyle: ButtonSizeStyleGenerator = ({ size, isGhostButton = false }) => {
  const {
    minWidth: defaultMinWidth,
    horizontalPadding: horizontalPaddingDefault,
    height,
    fontSize,
    lineHeight,
  } = buttonSizeStyleMap[size];
  const minWidth = isGhostButton ? defaultMinWidth + 8 : defaultMinWidth;
  const horizontalPadding = isGhostButton ? horizontalPaddingDefault - 4 : horizontalPaddingDefault;

  return css`
    min-width: ${minWidth}px;
    padding-left: ${horizontalPadding}px;
    padding-right: ${horizontalPadding}px;
    padding-top: 0;
    padding-bottom: 0;
    height: ${height}px;
    font-size: ${fontSize}px;
    line-height: ${lineHeight}px;
  `;
};

export const getContainerGeneratorOptions: GetContainerGeneratorOptions = ({
  theme = FeatherThemeEnum.Default,
  buttonType,
  variant,
}) => {
  switch (variant) {
    case 'ghost': {
      const sharedProperties = {
        activeBgColor: buttonType === 'danger' ? cssVariables('bg-negative-light') : cssVariables('bg-overlay-2'),
        bgColor: 'transparent',
        borderColor: 'transparent',
        disabledBgColor: 'transparent',
        disabledBorderColor: 'transparent',
        disabledContentColor: cssVariables('content-disabled'),
        hoverBgColor: cssVariables('bg-overlay-1'),
      };

      const colorMap = {
        primary: cssVariables('primary'),
        secondary: cssVariables('content-1'),
        tertiary: cssVariables('content-2'),
        danger: cssVariables('red-5'),
        support: undefined,
      };

      const contentColor = (buttonType && colorMap[buttonType]) || colorMap.tertiary;

      return {
        ...sharedProperties,
        contentColor,
        hoverContentColor: contentColor,
        activeContentColor: contentColor,
      };
    }

    default:
      switch (buttonType) {
        case 'primary':
          switch (theme) {
            case FeatherThemeEnum.Neutral:
              return {
                contentColor: cssVariables('neutral-10'),
                hoverContentColor: cssVariables('neutral-10'),
                activeContentColor: cssVariables('neutral-10'),
                disabledContentColor: cssVariables('neutral-6'),
                disabledBgColor: cssVariables('neutral-8'),
                borderColor: 'transparent',
                bgColor: 'white',
                hoverBgColor: cssVariables('neutral-2'),
                disabledBorderColor: 'transparent',
                activeBgColor: cssVariables('neutral-3'),
              };
            default: {
              const contentColor = cssVariables('content-inverse-1');

              return {
                contentColor,
                hoverContentColor: contentColor,
                activeContentColor: contentColor,
                disabledContentColor: cssVariables('content-disabled'),
                bgColor: cssVariables('bg-primary'),
                hoverBgColor: cssVariables('bg-primary-hover'),
                activeBgColor: cssVariables('bg-primary-pressed'),
                disabledBgColor: cssVariables('bg-disabled'),
                borderColor: 'transparent',
                disabledBorderColor: 'transparent',
              };
            }
          }
        case 'support':
          return {
            contentColor: 'white',
            hoverContentColor: 'white',
            activeContentColor: 'white',
            disabledContentColor: cssVariables('neutral-5'),
            disabledBgColor: cssVariables('neutral-2'),
            borderColor: 'transparent',
            bgColor: cssVariables('green-5'),
            hoverBgColor: cssVariables('green-6'),
            disabledBorderColor: 'transparent',
            activeBgColor: cssVariables('green-7'),
          };

        case 'secondary':
          switch (theme) {
            case FeatherThemeEnum.Neutral:
              return {
                contentColor: 'white',
                hoverContentColor: cssVariables('neutral-3'),
                activeContentColor: cssVariables('neutral-3'),
                disabledContentColor: cssVariables('neutral-6'),
                bgColor: 'transparent',
                hoverBgColor: 'transparent',
                activeBgColor: cssVariables('neutral-8'),
                disabledBgColor: 'transparent',
                borderColor: 'white',
                hoverBorderColor: cssVariables('neutral-5'),
                activeBorderColor: cssVariables('neutral-5'),
                disabledBorderColor: cssVariables('neutral-7'),
              };

            default: {
              const hoverBgColor = cssVariables('bg-primary');
              const activeBgColor = cssVariables('bg-primary-pressed');

              return {
                contentColor: cssVariables('content-primary'),
                hoverContentColor: cssVariables('content-inverse-1'),
                activeContentColor: cssVariables('content-inverse-1'),
                disabledContentColor: cssVariables('content-disabled'),
                bgColor: 'transparent',
                hoverBgColor,
                activeBgColor,
                disabledBgColor: 'transparent',
                borderColor: cssVariables('border-primary'),
                hoverBorderColor: hoverBgColor,
                activeBorderColor: activeBgColor,
                disabledBorderColor: cssVariables('border-2'),
              };
            }
          }

        case 'danger': {
          const contentColor = cssVariables('content-inverse-1');

          return {
            contentColor,
            hoverContentColor: contentColor,
            activeContentColor: contentColor,
            disabledContentColor: cssVariables('content-disabled'),
            bgColor: cssVariables('bg-negative'),
            hoverBgColor: cssVariables('bg-negative-hover'),
            activeBgColor: cssVariables('bg-negative-pressed'),
            disabledBgColor: cssVariables('bg-disabled'),
            borderColor: 'transparent',
            disabledBorderColor: 'transparent',
          };
        }
        case 'dangerTeritary': {
          const contentColor = cssVariables('red-5');

          return {
            contentColor,
            hoverContentColor: 'white',
            activeContentColor: 'white',
            disabledContentColor: cssVariables('content-disabled'),
            bgColor: 'transparent',
            hoverBgColor: contentColor,
            activeBgColor: cssVariables('bg-negative-pressed'),
            disabledBgColor: 'transparent',
            borderColor: contentColor,
            activeBorderColor: 'white',
            disabledBorderColor: cssVariables('border-2'),
          };
        }

        case 'tertiary':
        default: {
          const hoverContentColor = cssVariables('primary');
          const hoverBorderColor = cssVariables('border-primary');

          return {
            contentColor: cssVariables('content-2'),
            hoverContentColor,
            activeContentColor: hoverContentColor,
            disabledContentColor: cssVariables('content-disabled'),
            bgColor: 'transparent',
            activeBgColor: cssVariables('bg-primary-light'),
            disabledBgColor: 'transparent',
            borderColor: cssVariables('border-2'),
            hoverBorderColor,
            activeBorderColor: hoverBorderColor,
            disabledBorderColor: cssVariables('border-2'),
          };
        }
      }
  }
};

export const generateButtonContainerStyle: ButtonContainerStyleGenerator = ({
  contentColor,
  hoverContentColor = contentColor,
  activeContentColor = contentColor,
  pressedContentColor = activeContentColor,
  disabledContentColor,
  bgColor,
  hoverBgColor = bgColor,
  activeBgColor,
  pressedBgColor = activeBgColor,
  disabledBgColor,
  borderColor,
  hoverBorderColor = borderColor,
  activeBorderColor = borderColor,
  pressedBorderColor = activeBorderColor,
  disabledBorderColor,
  focusOutlineColor = cssVariables('purple-7'),
}) => {
  const isFocusOutlineUnseparated =
    bgColor === focusOutlineColor &&
    (borderColor === focusOutlineColor ||
      borderColor === 'transparent' ||
      (parseToRgb(borderColor) as RgbaColor).alpha === 0);

  return css`
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    color: ${contentColor};
    background-color: ${bgColor};
    border: 1px solid ${borderColor};
    border-radius: 4px;

    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;

    cursor: pointer;
    user-select: none;
    outline: 0;
    transition: ${transitions({ duration: 0.2, properties: ['color', 'background-color', 'border', 'box-shadow'] })};

    &:not([data-is-loading='true']) {
      &[aria-pressed='true'] {
        color: ${pressedContentColor};
        background-color: ${pressedBgColor};
        border: 1px solid ${pressedBorderColor};
      }

      &:hover {
        color: ${hoverContentColor};
        background-color: ${hoverBgColor};
        border: 1px solid ${hoverBorderColor};
      }

      &:active {
        color: ${activeContentColor};
        background-color: ${activeBgColor};
        border: 1px solid ${activeBorderColor};
      }

      &:focus:not(:active) {
        ${isFocusOutlineUnseparated && 'border: 1px solid #fff;'}
        box-shadow: 0 0 0 2px ${focusOutlineColor};
      }

      &:disabled {
        color: ${disabledContentColor};
        background-color: ${disabledBgColor};
        border: 1px solid ${disabledBorderColor};
      }
    }

    &:disabled {
      color: ${disabledContentColor};
      cursor: default;

      // workaround for the issue where mouseleave events not being fired on disabled buttons
      // https://github.com/facebook/react/issues/4251#issuecomment-267004045
      pointer-events: none;
    }
  `;
};
