import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CustomFieldsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M9.002 4h-1.5V2h5v2h-1.5v16h1.5v2h-5v-2h1.5V4zM2.004 6h4.998v2H4.004v7.54a.5.5 0 0 0 .5.5h2.498v2H4.504a2.5 2.5 0 0 1-2.5-2.5V6zM19.5 6H13v2h6.5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-6.498v2H19.5a2.5 2.5 0 0 0 2.5-2.5v-7A2.5 2.5 0 0 0 19.5 6z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const CustomFields = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CustomFieldsSvg} {...props} />
));
CustomFields.displayName = 'CustomFields';
export default CustomFields;
