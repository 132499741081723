import type { ReactNode } from 'react';

import styled from 'styled-components';

import type { FeatherIconComponent } from '@feather/components/icons/types';
import cssVariables from '@feather/theme/cssVariables';
import { Body, Subtitles, Typography } from '@feather/typography';

export enum EmptyStateSize {
  Large = 'large',
  Small = 'small',
}

export type EmptyStateProps = {
  size: EmptyStateSize;
  className?: string;
  icon: FeatherIconComponent;
  title: ReactNode;
  description: ReactNode;
  withoutMarginBottom?: boolean;
};

const Title = styled.div`
  margin-bottom: 4px;
`;

const Description = styled.div``;

const Container = styled.div<{ size: EmptyStateSize; withoutMarginBottom: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${cssVariables('neutral-7')};

  max-width: ${({ size }) => (size === EmptyStateSize.Large ? 640 : 480)}px;
  margin-bottom: ${({ withoutMarginBottom }) => (withoutMarginBottom ? 0 : 48)}px;

  > svg {
    margin-bottom: 12px;
  }

  ${Title} {
    ${({ size }) => Subtitles[size === EmptyStateSize.Large ? 'subtitle-03' : 'subtitle-02']};
  }

  ${Description} {
    ${({ size }) => (size === EmptyStateSize.Large ? Body['body-short-01'] : Typography['caption-01'])};
  }
`;

export const EmptyState = styled(
  ({ className, icon: Icon, size, title, description, withoutMarginBottom = false }: EmptyStateProps) => {
    return (
      <Container className={className} size={size} withoutMarginBottom={withoutMarginBottom}>
        <Icon size={size === EmptyStateSize.Small ? 64 : 80} color={cssVariables('neutral-5')} />
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Container>
    );
  },
)``;
