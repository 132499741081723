import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ThumbsUpSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="m10.035 2.445-.455-.303-.386.387-4.138 4.138H1.332v7c0 .92.746 1.666 1.667 1.666h8.176c.665 0 1.266-.394 1.53-1.004l1.823-4.207c.09-.21.137-.435.137-.663V8.333c0-.92-.746-1.666-1.666-1.666h-2.46l.58-2.13a1.667 1.667 0 0 0-.684-1.825l-.4-.267zM6 14h5.176a.333.333 0 0 0 .306-.2l1.824-4.208a.334.334 0 0 0 .027-.133V8.333A.333.333 0 0 0 12.999 8H8.793l.23-.842.81-2.971a.333.333 0 0 0-.087-.325L5.999 7.61V14zm-1.334 0V8h-2v5.667c0 .184.15.333.334.333h1.666z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 16, height: 16 },
);
const ThumbsUp = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ThumbsUpSvg} {...props} />
));
ThumbsUp.displayName = 'ThumbsUp';
export default ThumbsUp;
