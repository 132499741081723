import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrWellnessSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-we-a)">
        <path d="M3.003 19.5h18V21h-18v-1.5z" />
        <path d="M12.025 3.01c.42.6.84 1.15 1.2 1.719.95 1.489 1.63 3.088 1.8 4.867.14 1.439-.12 2.818-.65 4.157-.54 1.379-1.31 2.618-2.24 3.767-.04.05-.08.09-.17.18-.35-.49-.71-.94-1.03-1.419-.87-1.299-1.53-2.698-1.85-4.237-.41-1.929-.08-3.768.71-5.536.52-1.18 1.2-2.259 2.01-3.258.05-.06.11-.12.22-.25v.01zM10.225 18c-1.32-.15-2.53-.44-3.69-.96-2.38-1.089-3.61-3.057-4.17-5.526-.18-.79-.24-1.599-.36-2.408-.01-.08 0-.16 0-.31.53.05 1.03.09 1.53.16 1.27.18 2.49.53 3.63 1.15.15.08.23.17.24.359.02 2.179.74 4.137 1.83 5.986.3.51.64.99 1 1.549h-.01zM22.005 8.886c-.07.59-.12 1.19-.22 1.779-.21 1.319-.61 2.568-1.31 3.717-1.01 1.65-2.53 2.599-4.34 3.138-.6.18-1.23.29-1.85.42-.16.03-.32.03-.55.06.21-.31.38-.55.55-.8 1.15-1.728 1.98-3.587 2.23-5.666.04-.35.04-.71.09-1.059.01-.11.07-.26.15-.3 1.59-.88 3.31-1.239 5.1-1.329.03 0 .06.02.15.05v-.01zM5.575 4.04c.66.399 1.26.759 1.85 1.139.27.17.53.37.77.58.07.06.14.219.11.299-.24.82-.49 1.629-.75 2.438 0 .02-.03.03-.06.06-.34-.14-.69-.29-1.05-.42-.38-.14-.77-.24-1.15-.38-.09-.03-.21-.15-.21-.229.01-1.15.19-2.269.49-3.488zM16.485 8.506c-.28-.82-.56-1.649-.83-2.468-.02-.06 0-.17.05-.21.82-.67 1.7-1.24 2.73-1.729.12.7.25 1.35.34 1.999.06.45.09.91.11 1.369 0 .09-.06.24-.12.26-.77.28-1.54.53-2.28.78z" />
      </g>
      <defs>
        <clipPath id="br-we-a">
          <path d="M0 0H24V24H0z" transform="translate(.003)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrWellness = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrWellnessSvg} {...props} />
));
BrWellness.displayName = 'BrWellness';
export default BrWellness;
