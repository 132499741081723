import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ShowSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="m.873 12 .256.491c.026.05.085.159.176.314a18.311 18.311 0 0 0 2.592 3.392C5.691 18.04 8.433 20 12 20c3.566 0 6.309-1.96 8.103-3.802a18.308 18.308 0 0 0 2.592-3.393c.047-.08.093-.162.139-.245.018-.033.036-.066.051-.1l.242-.46-.24-.462c.016.03.01.022-.001 0l-.052-.098a16.196 16.196 0 0 0-.671-1.09 18.31 18.31 0 0 0-2.06-2.548C18.309 5.961 15.566 4 12 4 8.433 4 5.69 5.96 3.897 7.802a18.314 18.314 0 0 0-2.592 3.393c-.09.155-.15.264-.176.314L.873 12zm2.28 0a16.321 16.321 0 0 0 2.176 2.802C6.946 16.461 9.204 18 12 18c2.798 0 5.056-1.54 6.67-3.198A16.314 16.314 0 0 0 20.847 12a16.312 16.312 0 0 0-2.176-2.802C17.055 7.539 14.797 6 12 6 9.203 6 6.945 7.54 5.33 9.198A16.32 16.32 0 0 0 3.154 12z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Show = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ShowSvg} {...props} />
));
Show.displayName = 'Show';
export default Show;
