import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CallVideoP2PFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M15.297 2.41 13.883.996l-3.707 3.707 3.707 3.707 1.414-1.414-1.293-1.293h5.172l-1.293 1.293 1.414 1.414 3.707-3.707L19.297.996 17.883 2.41l1.293 1.293h-5.172l1.293-1.293z" />
      <path d="M7.347 4.703 8.051 4H1v13.5A2.5 2.5 0 0 0 3.5 20h10a2.5 2.5 0 0 0 2.5-2.5v-2.504l5 4.125V9.536l-1.703 1.703-2.707-2.707-2.707 2.707-6.536-6.536z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const CallVideoP2PFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CallVideoP2PFilledSvg} {...props} />
));
CallVideoP2PFilled.displayName = 'CallVideoP2PFilled';
export default CallVideoP2PFilled;
