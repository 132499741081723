import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DocumentDuotoneSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g id="Icon" fillRule="evenodd" strokeWidth={1}>
        <g id="Icon-/-File-/-document-/-duotone-/-24" fillRule="nonzero">
          <path
            id="Color_02"
            d="M13.975 1h-7.9C4.36 1 3 2.372 3 4.032v15.936C3 21.628 4.36 23 6.075 23h11.85C19.64 23 21 21.628 21 19.968V8.016a1.01 1.01 0 0 0-.297-.711l-5.926-5.976A1.13 1.13 0 0 0 13.975 1z"
          />
          <path
            id="Color_01"
            d="M16.037 16c.532 0 .963.449.963 1.002 0 .553-.431 1.002-.963 1.002H7.963c-.532 0-.963-.449-.963-1.002 0-.553.431-1.002.963-1.002zm0-4c.532 0 .963.449.963 1.002 0 .553-.431 1.002-.963 1.002H7.963c-.532 0-.963-.449-.963-1.002 0-.553.431-1.002.963-1.002zm-5.035-4a1.002 1.002 0 0 1 0 2.004h-3a1.002 1.002 0 1 1 0-2.004z"
          />
        </g>
      </g>
    </svg>
  )),
  { width: 24, height: 24 },
);
const DocumentDuotone = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DocumentDuotoneSvg} {...props} />
));
DocumentDuotone.displayName = 'DocumentDuotone';
export default DocumentDuotone;
