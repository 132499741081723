import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CopySvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M16 2v3.499h-2V4H4.5a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h1.994v2H4.5A2.5 2.5 0 0 1 2 14.5v-10A2.5 2.5 0 0 1 4.5 2H16z" />
      <path
        fillRule="evenodd"
        d="M22 7v12.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 8 19.5v-10A2.5 2.5 0 0 1 10.5 7H22zm-2 12.5V9h-9.5a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Copy = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CopySvg} {...props} />
));
Copy.displayName = 'Copy';
export default Copy;
