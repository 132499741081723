import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const RefreshSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M8.668 5.873A7.19 7.19 0 0 1 13.1 5.07a7.14 7.14 0 0 1 4.028 1.986l.011.01L19.22 9H17v2h6V5h-2v2.925l-2.476-2.302a9.14 9.14 0 0 0-5.146-2.534 9.19 9.19 0 0 0-5.666 1.027A9.04 9.04 0 0 0 3.8 8.31a8.908 8.908 0 0 0-.576 5.682 8.983 8.983 0 0 0 2.995 4.878 9.18 9.18 0 0 0 10.913.631 9.016 9.016 0 0 0 3.55-4.498l-1.884-.674a7.016 7.016 0 0 1-2.763 3.5 7.179 7.179 0 0 1-4.35 1.16 7.158 7.158 0 0 1-4.182-1.653 6.983 6.983 0 0 1-2.33-3.791 6.908 6.908 0 0 1 .447-4.407 7.04 7.04 0 0 1 3.047-3.264z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Refresh = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={RefreshSvg} {...props} />
));
Refresh.displayName = 'Refresh';
export default Refresh;
