import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import copy from 'copy-to-clipboard';
import Prism from 'prismjs';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-gradle';
import 'prismjs/components/prism-http';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-kotlin';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-objectivec';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-swift';
import 'prismjs/components/prism-typescript';
import styled, { css } from 'styled-components';

import { transitionDefault } from '@feather/animation';
import * as Icons from '@feather/components/icons';
import cssVariables from '@feather/theme/cssVariables';
import { FeatherThemeContext, FeatherThemeEnum } from '@feather/themes';
import { Headings } from '@feather/typography';

import { ScrollBar } from '../ScrollBar';
import { IconButton } from '../button';

export enum CodeSnippetTheme {
  Light = 'light',
  Dark = 'dark',
}

/** @knipignore */
export enum Language {
  Bash = 'bash',
  C = 'c',
  CSharp = 'csharp',
  Gradle = 'gradle',
  Http = 'http',
  Java = 'java',
  JavaScript = 'javascript',
  Json = 'json',
  Kotlin = 'kotlin',
  Markup = 'markup',
  Objectivec = 'objectivec',
  Python = 'python',
  Ruby = 'ruby',
  Swift = 'swift',
  TypeScript = 'typescript',
}

export interface CodeSnippetData {
  label?: string;
  language?: Language;
  code: string;
}

type CodeSnippetProps = {
  data: string | CodeSnippetData[];
  onClickTab?: (index: number) => void;
  onChangeTheme?: (currentTheme: CodeSnippetTheme, nextTheme: CodeSnippetTheme) => void;
  activeIndex?: number;
  defaultActiveIndex?: number;
  theme?: CodeSnippetTheme;
  hasLineNumber?: boolean;
  startLineNumber?: number;
  className?: string;
  rows?: number;
  lineWrap?: boolean;
  title?: string; // show title text in the tab area
  alwaysVisibleScrollbar?: boolean;
};

const defaultTheme: CodeSnippetTheme = CodeSnippetTheme.Light;
const fontStyle = { fontSize: 13, lineHeight: 20 };
const codeBlockTopPadding = 16;

const CodeTheme = {
  light: css`
    color: ${cssVariables('neutral-10')};
    .token.selector,
    .token.attr-name,
    .token.string,
    .token.char,
    .token.inserted {
      color: ${cssVariables('purple-7')};
    }

    .token.atrule,
    .token.attr-value,
    .token.keyword {
      color: ${cssVariables('purple-9')};
    }

    .token.function {
      color: ${cssVariables('blue-7')};
    }

    .token.class-name {
      color: ${cssVariables('blue-5')};
    }

    .token.property {
      color: ${cssVariables('neutral-10')};
    }

    .token.boolean {
      color: ${cssVariables('green-5')};
    }

    .token.punctuation {
      color: ${cssVariables('red-4')};
      &.annotation {
        color: ${cssVariables('green-4')};
      }
    }

    .token.operator {
      color: ${cssVariables('red-6')};
    }

    .token.comment,
    .token.prolog,
    .token.doctype,
    .token.cdata {
      color: ${cssVariables('neutral-6')};
    }

    .token.tag,
    .token.number,
    .token.constant,
    .token.symbol,
    .token.deleted {
      color: ${cssVariables('orange-5')};
    }

    .token.builtin {
      color: ${cssVariables('neutral-9')};
    }

    .token.entity,
    .token.url,
    .language-css .token.string,
    .style .token.string {
      color: white;
    }

    .token.regex,
    .token.important,
    .token.variable {
      color: ${cssVariables('neutral-6')};
    }
  `,
  dark: css`
    color: white;
    .token.selector,
    .token.attr-name,
    .token.string,
    .token.char,
    .token.inserted {
      color: ${cssVariables('purple-3')};
    }

    .token.atrule,
    .token.attr-value,
    .token.keyword {
      color: #b4a4ff;
    }

    .token.function {
      color: ${cssVariables('blue-4')};
    }

    .token.class-name {
      color: ${cssVariables('blue-3')};
    }

    .token.property {
      color: ${cssVariables('violet-3')};
    }

    .token.boolean {
      color: ${cssVariables('green-5')};
    }

    .token.punctuation {
      color: ${cssVariables('red-4')};
      &.annotation {
        color: ${cssVariables('green-3')};
      }
    }

    .token.operator {
      color: ${cssVariables('red-5')};
    }

    .token.comment,
    .token.prolog,
    .token.doctype,
    .token.cdata {
      color: ${cssVariables('neutral-6')};
    }

    .token.tag,
    .token.number,
    .token.constant,
    .token.symbol,
    .token.deleted {
      color: ${cssVariables('orange-4')};
    }

    .token.builtin {
      color: ${cssVariables('neutral-6')};
    }

    .token.entity,
    .token.url,
    .language-css .token.string,
    .style .token.string {
      color: white;
    }

    .token.regex,
    .token.important,
    .token.variable {
      color: ${cssVariables('neutral-6')};
    }
  `,
};

const themeMap: Record<CodeSnippetTheme, FeatherThemeEnum> = {
  dark: FeatherThemeEnum.Neutral,
  light: FeatherThemeEnum.Gray,
};

const transitionWithProperties = (properties: string) => css`
  transition-property: ${properties};
  transition-duration: 0.2s;
  transition-timing-function: ${transitionDefault};
`;

const CodeContent = styled.pre<{ theme: CodeSnippetTheme; lineWrap: boolean }>`
  font-family: 'Roboto Mono', monospace;
  display: inline-block;
  text-align: left;
  white-space: pre;
  width: 100%;

  tab-size: 4;
  hyphens: none;

  > span {
    vertical-align: baseline;
  }

  ${({ lineWrap }) =>
    lineWrap &&
    css`
      white-space: pre-wrap;

      span {
        word-break: break-all;
      }
    `}

  .namespace {
    opacity: 0.7;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }

  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }

  ${({ theme }) => CodeTheme[theme]};
  ${transitionWithProperties('color')};
`;

const IconContent = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  margin-left: auto;
`;

const ScrollbarWrapper = styled.div<{ hasLineNumber: boolean; rows?: number }>`
  @import url('https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: ${codeBlockTopPadding}px;
  padding-bottom: 0;
  ${({ hasLineNumber }) =>
    hasLineNumber &&
    css`
      padding-left: 0;
    `}

  font-size: ${fontStyle.fontSize}px;
  letter-spacing: -0.3px;
  line-height: ${fontStyle.lineHeight}px;

  ${({ rows }) => {
    if (rows) {
      return rows === 1
        ? css`
            height: 40px;
            padding: 0 16px;
            align-items: center;
            ${CodeContent} {
              display: flex;
              align-items: center;
              height: 40px;
              flex: 1;
              overflow-x: auto;
            }
            ${IconContent} {
              margin-left: 36px;
            }
          `
        : css`
            height: ${fontStyle.lineHeight * rows + codeBlockTopPadding}px;
          `;
    }
  }}

  -webkit-overflow-scrolling: touch;

  ${transitionWithProperties('background')};
`;

ScrollbarWrapper.displayName = 'ScrollbarWrapper';

const LineNumber = styled.span.attrs({ 'data-test-id': 'LineNumber' })<{ $theme: CodeSnippetTheme }>`
  position: sticky;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 0;
  left: 0;
  min-width: 36px;
  padding-left: 8px;
  margin-right: 1rem;
  border-right: 1px solid
    ${(props) => (props.$theme === CodeSnippetTheme.Light ? cssVariables('neutral-3') : cssVariables('neutral-8'))};

  font-family: 'Roboto Mono', monospace;
  letter-spacing: -1px;
  ${transitionWithProperties('background, border-right')};

  user-select: none;
`;

LineNumber.displayName = 'LineNumber';

const LineNumberRow = styled.span.attrs({ 'data-test-id': 'LineNumberRow' })`
  pointer-events: none;
  display: block;
  color: ${cssVariables('neutral-6')};
  display: block;
  padding-right: 0.8em;
  text-align: right;
`;

LineNumberRow.displayName = 'LineNumberRow';

const ScrollContainer = styled.div<{ lineWrap: boolean }>`
  display: flex;
`;

const Tabs = styled.div.attrs({ 'data-test-id': 'Tabs' })<{ $theme: CodeSnippetTheme }>`
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid
    ${(props) =>
      props.$theme === CodeSnippetTheme.Light ? cssVariables('border-3') : cssVariables('border-inverse-2')};
  padding: 0 16px;
  padding-right: 8px;
  border-radius: 4px 4px 0 0;

  ${transitionWithProperties('border-bottom')};
`;

Tabs.displayName = 'Tabs';

const Tab = styled.span.attrs({ 'data-test-id': 'Tab' })<{ isActive: boolean; $theme: CodeSnippetTheme }>`
  display: inline-block;
  position: relative;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  cursor: pointer;

  color: ${(props) =>
    props.$theme === CodeSnippetTheme.Light ? cssVariables('content-3') : cssVariables('content-inverse-2')};

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: transparent;
    transition: background 0.2s ${transitionDefault};
  }

  ${({ isActive, $theme }) =>
    isActive &&
    css`
      color: ${$theme === CodeSnippetTheme.Light ? cssVariables('content-primary') : cssVariables('purple-4')};
      &::before {
        background: ${$theme === CodeSnippetTheme.Light ? cssVariables('bg-primary') : cssVariables('purple-4')};
      }
    `}

  ${transitionWithProperties('background')};

  & + & {
    margin-left: 24px;
  }
`;

Tab.displayName = 'Tab';

const CodeSnippetTitle = styled.label<{ $theme: CodeSnippetTheme }>`
  ${Headings['heading-01']};
  ${({ $theme }) =>
    $theme === CodeSnippetTheme.Light
      ? css`
          color: ${cssVariables('content-1')};
        `
      : css`
          color: ${cssVariables('content-inverse-1')};
        `};
`;

const Container = styled.div.attrs({ 'data-test-id': 'CodeSnippetContainer' })<{ $theme: CodeSnippetTheme }>`
  background: ${({ $theme }) => ($theme === CodeSnippetTheme.Light ? cssVariables('bg-3') : cssVariables('bg-black'))};
  border-radius: 4px;
  ${transitionWithProperties('background')};

  ${LineNumber} {
    background: ${({ $theme }) =>
      $theme === CodeSnippetTheme.Light ? cssVariables('bg-3') : cssVariables('bg-black')};
  }
`;

export const CodeSnippet = ({
  data: dataProp = [],
  onClickTab,
  onChangeTheme,
  activeIndex: activeIndexFromProps,
  defaultActiveIndex,
  startLineNumber = 1,
  hasLineNumber = false,
  className,
  theme = defaultTheme,
  rows,
  lineWrap = false,
  title,
  alwaysVisibleScrollbar = false,
}: CodeSnippetProps) => {
  const scrollbarRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const codeRef = useRef<HTMLPreElement>(null);
  const [isCopied, setIsCopied] = useState(false);
  const timeoutRef = useRef(0);

  const isControlled = typeof activeIndexFromProps !== 'undefined';
  const hasDefaultValue = typeof defaultActiveIndex !== 'undefined';
  const [internalActiveIndex, setInternalActiveIndex] = useState(hasDefaultValue ? defaultActiveIndex : 0);
  const activeIndex = (isControlled ? activeIndexFromProps : internalActiveIndex) as number;

  const data = useMemo(() => {
    if (typeof dataProp === 'string') {
      return [{ code: dataProp }];
    }
    return dataProp;
  }, [dataProp]);

  const activeLanguage = data[activeIndex]?.language || '';
  const activeCode = data[activeIndex]?.code || '';

  const highlightCode = useCallback(() => {
    if (codeRef.current && activeCode && Prism) {
      Prism.highlightElement(codeRef.current);
    }
  }, [activeCode]);

  useEffect(() => {
    highlightCode();
  }, [theme, activeLanguage, activeCode, highlightCode]);

  useEffect(() => {
    /*
     ** For Next.js apps, we have to re-highlight the code right after the document has finished its loading.
     */
    const documentReadyStateChangeListener = () => {
      if (document.readyState === 'complete') {
        highlightCode();
      }
    };

    document.addEventListener('readystatechange', documentReadyStateChangeListener);
    return () => {
      document.removeEventListener('readystatechange', documentReadyStateChangeListener);
    };
  }, [highlightCode]);

  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  });

  const handleClickTab = useCallback(
    (index: number) => () => {
      onClickTab?.(index);

      if (!isControlled) {
        setInternalActiveIndex(index);
      }
    },
    [isControlled, onClickTab],
  );

  const handleClickToggleTheme = useCallback(() => {
    onChangeTheme?.(theme, theme === CodeSnippetTheme.Light ? CodeSnippetTheme.Dark : CodeSnippetTheme.Light);
  }, [theme, onChangeTheme]);

  const handleClickCopy = useCallback(() => {
    copy(data[activeIndex].code);
    setIsCopied(true);
    timeoutRef.current = window.setTimeout(() => setIsCopied(false), 2500);
  }, [data, activeIndex]);

  const renderIconContent = useMemo(() => {
    return (
      <IconContent>
        {onChangeTheme && (
          <IconButton
            icon={theme === CodeSnippetTheme.Light ? Icons.ThemeDark : Icons.ThemeLight}
            buttonType="secondary"
            size="xsmall"
            onClick={handleClickToggleTheme}
            title={theme === CodeSnippetTheme.Light ? 'Dark theme skin' : 'Light theme skin'}
            data-test-id="ThemeButton"
          />
        )}
        <IconButton
          icon={Icons.Copy}
          buttonType="secondary"
          size="xsmall"
          onClick={handleClickCopy}
          title={isCopied ? 'Copied!' : 'Copy'}
        />
      </IconContent>
    );
  }, [onChangeTheme, theme, handleClickToggleTheme, handleClickCopy, isCopied]);

  const lineNumbers = useMemo(
    () =>
      hasLineNumber && (
        <LineNumber aria-hidden="true" className="line-numbers-rows" $theme={theme}>
          {Array.from(Array(activeCode.trim().split('\n').length)).map((_, index) => (
            <LineNumberRow key={`line-number-${index}`}>{startLineNumber + index}</LineNumberRow>
          ))}
        </LineNumber>
      ),
    [activeCode, hasLineNumber, startLineNumber, theme],
  );

  return (
    <FeatherThemeContext.Provider value={themeMap[theme]}>
      <Container ref={containerRef} className={className} $theme={theme}>
        {rows === 1 ? (
          <ScrollbarWrapper hasLineNumber={false} rows={rows}>
            <CodeContent
              ref={codeRef}
              className={data[activeIndex].language && `language-${data[activeIndex].language}`}
              lineWrap={lineWrap}
              theme={theme}
            >
              {activeCode}
            </CodeContent>
            {renderIconContent}
          </ScrollbarWrapper>
        ) : (
          <>
            <Tabs $theme={theme}>
              {title && data.length === 1 ? <CodeSnippetTitle $theme={theme}>{title}</CodeSnippetTitle> : ''}
              {data.length > 1 &&
                data.map((item, index) => (
                  <Tab
                    key={`${item.label}-${index}`}
                    isActive={index === activeIndex}
                    $theme={theme}
                    onClick={handleClickTab(index)}
                  >
                    {item.label}
                  </Tab>
                ))}
              {renderIconContent}
            </Tabs>
            <ScrollbarWrapper className={hasLineNumber ? 'line-numbers' : ''} hasLineNumber={hasLineNumber} rows={rows}>
              <ScrollBar style={{ paddingBottom: 16 }} theme={theme} alwaysVisible={alwaysVisibleScrollbar}>
                <ScrollContainer ref={scrollbarRef} lineWrap={lineWrap}>
                  {lineNumbers}
                  <CodeContent
                    ref={codeRef}
                    className={data[activeIndex].language && `language-${data[activeIndex].language}`}
                    lineWrap={lineWrap}
                    theme={theme}
                  >
                    {activeCode}
                  </CodeContent>
                </ScrollContainer>
              </ScrollBar>
            </ScrollbarWrapper>
          </>
        )}
      </Container>
    </FeatherThemeContext.Provider>
  );
};
