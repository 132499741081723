import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const PushPinSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="m19.361 11.191 1.83 1.83 1.415-1.414L11.999 1l-1.414 1.415 1.83 1.83L7.9 8.761c-1.724-.08-3.45.497-4.74 1.785l-.707.708 4.243 4.242-4.95 4.95L3.16 21.86l4.95-4.95 4.243 4.243.707-.707c1.289-1.289 1.866-3.015 1.786-4.74l4.515-4.515zM13.83 5.66l4.118 4.118-5.187 5.187.058.483c.12.994-.069 1.97-.554 2.789l-6.893-6.894c.818-.485 1.794-.674 2.789-.554l.483.058 5.186-5.187z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const PushPin = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={PushPinSvg} {...props} />
));
PushPin.displayName = 'PushPin';
export default PushPin;
