import type { ChangeEvent as ReactChangeEvent, ReactNode } from 'react';
import { cloneElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

import cssVariables from '@feather/theme/cssVariables';
import type {
  ButtonProps,
  OverflowMenuProps,
  SortOrder,
  TableColumnProps,
  TableContentDensity,
  TableProps,
} from '@feather/types';
import { Headings } from '@feather/typography';
import { useOnChangeTrigger } from '@feather/utils/useOnChangeTrigger';

import { OverflowMenu } from '../OverflowMenu';
import { ScrollBar } from '../ScrollBar';
import { Button } from '../button';
import { Checkbox } from '../checkbox';
import { SortOrderIndicator } from '../sortOrderIndicator';
import { SpinnerFull } from '../spinner';

const sortDirections: SortOrder[] = ['ascend', 'descend'];
const getReverseSortOrder = (order: SortOrder) => sortDirections[(sortDirections.indexOf(order) + 1) % 2];

type AnyColumnProps = TableColumnProps<any>;

const selectionColumnKey = 'selection-column';
const headerRowHeight = 40;
const footerHeight = 56;
const rowHeight = 64;

const hideScrollbarStyles = css`
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
`;

const ColumnTitle = styled.span``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 0;

  background-color: #fff;

  font-size: 14px;
  line-height: 20px;
  color: ${cssVariables('neutral-10')};
`;

const StyledSortOrderIndicator = styled(SortOrderIndicator)`
  margin-left: 4px;
  ${(props) =>
    props.order === undefined &&
    css`
      opacity: 0;
    `}
`;

const RowActionsContainer = styled.td`
  &&&& {
    // Use hack to increase specificity weight
    position: absolute;
    right: 0;
    top: 6px;
    display: grid;
    grid-gap: 4px;
    grid-auto-flow: column;
    align-content: start;
    padding: 0 8px;
    margin: 0 !important;
  }
`;

const FooterWrapper = styled.div`
  bottom: 0;
  left: 0;
  right: 0;

  flex: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${footerHeight}px;
  background: #fff;
  border-top: 1px solid ${cssVariables('neutral-3')};
  padding-left: 14px;
  padding-right: 14px;
`;

const tableRowPadding = (props: { density: TableContentDensity }) => {
  switch (props.density) {
    case 'compact':
      return 8;
    case 'large':
      return 16;
    default:
      return 12;
  }
};

const TableElement = styled.table<{ density: TableContentDensity; hasFooter: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;

  // If Table has footer, stretch the table element vertically.
  ${(props) =>
    props.hasFooter &&
    css`
      flex: 1;
    `}

  width: 100%;
  min-height: 0;

  border-collapse: collapse;

  thead,
  tbody {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: nowrap;
  }

  tr {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    flex-wrap: nowrap;
  }

  th,
  td {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 8px;

    &[data-is-selection-column='true'] {
      flex: initial;
      width: 22px;
      text-align: center;
      justify-content: center;
      margin-left: 8px;
      margin-right: 12px;
    }

    &:hover ${StyledSortOrderIndicator} {
      opacity: 1;
    }
  }

  thead {
    top: 0;
    z-index: 1;

    tr {
      height: ${headerRowHeight}px;
      border-bottom: 1px solid ${cssVariables('neutral-3')};
    }

    th,
    td {
      align-items: center;
      padding: 12px 0;
      font-size: 13px;
      font-weight: 600;
      line-height: 16px;
      height: ${headerRowHeight - 1}px;
      overflow: hidden;
      color: ${cssVariables('neutral-10')};
    }
  }

  tbody {
    tr {
      box-shadow: 0px -1px 0px 0px ${cssVariables('neutral-3')} inset;

      &:hover {
        margin-top: -1px;
        background-color: ${cssVariables('neutral-1')};
        border-top: 1px solid ${cssVariables('neutral-1')};
        box-shadow: 0px -1px 0px 0px ${cssVariables('neutral-1')} inset;
      }

      &:last-of-type {
        border-bottom: 1px solid ${cssVariables('neutral-3')};
        box-shadow: none;
      }
    }

    td {
      padding-top: ${tableRowPadding}px;
      padding-bottom: ${tableRowPadding}px;
    }
  }
`;

const TableRow = styled.tr<{ isSelected: boolean; disabled: boolean; styles: SimpleInterpolation }>`
  position: relative;
  ${(props) => props.isSelected && `background-color: ${cssVariables('neutral-2')};`}
  ${(props) =>
    props.disabled &&
    css`
      td {
        color: ${cssVariables('neutral-5')};
      }
    `}
  ${(props) =>
    props.styles &&
    css`
      &&& {
        ${props.styles}
      }
    `}
`;

const Cell = styled.td<{
  align: AnyColumnProps['align'];
  width: AnyColumnProps['width'];
  flex: AnyColumnProps['flex'];
  isClickable?: boolean;
  styles?: SimpleInterpolation;
}>`
  min-width: 0;
  text-overflow: ellipsis;
  overflow: scroll;
  ${hideScrollbarStyles}

  text-align: ${(props) => props.align};
  ${(props) => props.isClickable && 'cursor: pointer;'}
  ${({ flex, width }) => {
    if (flex !== undefined) {
      return css`
        flex: ${flex} 0;
      `;
    }
    if (typeof width === 'number') {
      return css`
        width: ${width}px;
      `;
    }
    if (width !== undefined) {
      return css`
        width: ${width};
      `;
    }
    return css`
      flex: 1 0;
    `;
  }}

  ${(props) =>
    props.styles &&
    css`
      &&& {
        ${props.styles}
      }
    `}
`;

const EmptyViewWrapper = styled.tr`
  border-bottom: 0 !important;
  background-color: transparent !important;

  td {
    width: 100%;
    height: initial !important;
    min-height: ${rowHeight - 1}px;
  }
`;

const BatchActionsContainer = styled.td`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -4px;

  button {
    min-width: 0;
    margin-right: 8px;
  }
`;

const SelectedRowCount = styled.div`
  margin-right: 18px;
  ${Headings['heading-02']}
`;

function isColumnsEqual(aColumn?: TableColumnProps<any>, otherColumn?: TableColumnProps<any>) {
  if (!aColumn || !otherColumn) {
    return false;
  }
  if (aColumn.key && otherColumn.key) {
    return aColumn.key === otherColumn.key;
  }
  if (aColumn.dataIndex && otherColumn.dataIndex) {
    return aColumn.dataIndex === otherColumn.dataIndex;
  }
  if (aColumn.title && otherColumn.title) {
    return aColumn.title === otherColumn.title;
  }
  return false;
}

export function Table<TableRecord, RowKey extends string | number = string>({
  columns,
  dataSource = [],
  rowKey = 'key',
  rowSelection,
  loading = false,
  rowStyles,
  emptyView,
  onRow,
  onSortByUpdated,
  className,
  showScrollbars = false,
  scrollBarRef,
  footer,
  rowActions: propRowActions = () => [],
  density = 'default',
  batchActions = [],
  onMouseEnterTableRow,
  onMouseLeaveTableRow,
}: TableProps<TableRecord, RowKey>) {
  const [internalSelectedRowKeys, setInternalSelectedRowKeys] = useState<RowKey[]>([]);
  const [sortOrder, setSortOrder] = useState<SortOrder | undefined>(undefined);
  const [sortColumn, setSortColumn] = useState<TableColumnProps<TableRecord> | undefined>(undefined);
  const tableElementRef = useRef<HTMLTableElement>(null);

  /**
   * True if `rowSelection.selectedRowKeys` is defined.
   * if it is true, Table doesn't use `selectedRowKeys` state but `rowSelection.selectedRowKeys` prop
   */
  const isSelectionControlled = !!(rowSelection && rowSelection.selectedRowKeys);

  const selectedRowKeys = isSelectionControlled ? rowSelection!.selectedRowKeys! : internalSelectedRowKeys;

  /**
   * If a compare function is not given for a column, use this function for the column
   *
   * @param a value of the column of a row
   * @param b value of the column of another row
   * @param {SortOrder} sortOrder sort order
   * @returns positive number if a precedes b, negative number if a succeeds b
   */
  const defaultCompareFn = (a: any, b: any, sortOrder?: SortOrder) => {
    const directionFactor = sortOrder === 'descend' ? -1 : 1;

    if (typeof a === 'number' && typeof b === 'number') {
      return (a - b) * directionFactor;
    }
    if (typeof a === 'boolean' && typeof b === 'boolean') {
      return (Number(b) - Number(a)) * directionFactor;
    }
    return String(a).localeCompare(String(b)) * directionFactor;
  };

  /** Columns with `defaultSortOrder` defined */
  const defaultSortedColumns = columns.filter((column) => column.defaultSortOrder !== undefined);

  const sortBy = useMemo(() => ({ sortColumn, sortOrder }), [sortColumn, sortOrder]);
  useOnChangeTrigger(sortBy, ({ sortColumn, sortOrder }) => onSortByUpdated?.(sortColumn, sortOrder));

  useEffect(() => {
    if (sortOrder && sortColumn) {
      return;
    }

    if (defaultSortedColumns.length > 0) {
      setSortOrder(defaultSortedColumns[0].defaultSortOrder);
      setSortColumn(defaultSortedColumns[0]);
    }
  }, [defaultSortedColumns, sortColumn, sortOrder]);

  const sortedDataSource = useMemo(() => {
    if (!sortOrder || !sortColumn || onSortByUpdated != null) {
      return dataSource;
    }
    const sortColumnKey = sortColumn.dataIndex;
    if (!sortColumnKey) {
      return dataSource;
    }

    return [...dataSource].sort((a: TableRecord, b: TableRecord) => {
      // compare the values of the sort columns of two rows
      const compareFnReturnValue =
        typeof sortColumn.sorter === 'function'
          ? sortColumn.sorter(a, b, sortOrder)
          : defaultCompareFn((a as any)[sortColumnKey], (b as any)[sortColumnKey], sortOrder);

      if (compareFnReturnValue !== 0) {
        return compareFnReturnValue;
      }

      // if two values are equal, compare the rest columns from the first one
      for (let i = 0; i < columns.length; i++) {
        const column = columns[i];
        const compareFnReturnValue =
          typeof column.sorter === 'function'
            ? column.sorter(a, b, column.defaultSortOrder)
            : defaultCompareFn((a as any)[sortColumnKey], (b as any)[sortColumnKey], column.defaultSortOrder);
        if (compareFnReturnValue !== 0) {
          return compareFnReturnValue;
        }
      }

      return 0;
    });
  }, [sortOrder, sortColumn, onSortByUpdated, dataSource, columns]);

  /**
   * Get the key of a table record
   *
   * @param {TableRecord} record table record
   * @returns the key of the record
   */
  const getRowKey = useCallback(
    (record: TableRecord, index?: number) => {
      const recordKey: RowKey = (record as any)[rowKey];
      if (recordKey !== undefined) {
        return recordKey;
      }
      return `rowKey#${index}` as RowKey;
    },
    [rowKey],
  );

  const notifyNewSelection = useCallback(
    (selectedRowKeys: RowKey[]) => {
      if (!rowSelection) {
        return;
      }

      if (!isSelectionControlled) {
        setInternalSelectedRowKeys(selectedRowKeys);
      }
      if (rowSelection.onChange) {
        let records: ReadonlyArray<TableRecord>;
        if (selectedRowKeys.length === 0) {
          records = [];
        } else if (selectedRowKeys.length === dataSource.length) {
          records = dataSource;
        } else {
          records = selectedRowKeys
            .map((rowKey) => dataSource.find((record) => rowKey === getRowKey(record)))
            .filter((record) => record) as ReadonlyArray<TableRecord>;
        }
        rowSelection.onChange(selectedRowKeys, records);
      }
    },
    [dataSource, getRowKey, isSelectionControlled, rowSelection],
  );

  const onRecordSelectionChange = useCallback(
    (rowKey: RowKey) =>
      ({ target }: ReactChangeEvent<HTMLInputElement>) => {
        if (!rowSelection) {
          return;
        }

        notifyNewSelection(
          target.checked ? [...selectedRowKeys, rowKey] : selectedRowKeys.filter((key) => key !== rowKey),
        );
      },
    [notifyNewSelection, rowSelection, selectedRowKeys],
  );

  const renderCheckbox = (record: TableRecord) => {
    const rowKey = getRowKey(record);
    const checked = selectedRowKeys.includes(rowKey);
    const checkboxProps = rowSelection && rowSelection.getCheckboxProps ? rowSelection.getCheckboxProps(record) : null;

    return <Checkbox {...checkboxProps} checked={checked} onChange={onRecordSelectionChange(rowKey)} />;
  };

  /**
   * Get visible table columns. If selection is enabled, insert a checkbox column at first.
   */
  const getColumns = () => {
    const allColumns: TableColumnProps<TableRecord>[] = [...columns];
    if (rowSelection) {
      allColumns.unshift({
        key: selectionColumnKey,
        render: renderCheckbox,
      });
    }
    return allColumns;
  };

  /**
   * Returns if a table column is a checkbox column
   *
   * @param column table column
   * @returns True if the column is a checkbox column
   */
  const isSelectionColumn = (column: TableColumnProps<TableRecord>) =>
    rowSelection && column.key === selectionColumnKey;

  const renderBodyCell = (column: TableColumnProps<TableRecord>, record: TableRecord, index: number) => {
    const { key, dataIndex, render, align, width, flex, colSpan, onCell } = column;
    const data = record[dataIndex as keyof typeof record];
    const text = dataIndex && data != null ? `${data}` : '';
    const content = typeof render === 'function' ? render(record, index) : text;

    return (
      <Cell
        key={key || dataIndex}
        align={align}
        width={width}
        flex={flex}
        colSpan={colSpan}
        data-is-selection-column={isSelectionColumn(column)}
        styles={column.styles}
        {...(onCell ? onCell(record, index) : null)}
        data-is-last-column={columns[columns.length - 1] === column}
      >
        {content}
      </Cell>
    );
  };

  const renderRow = (record: TableRecord, index: number) => {
    const currentRowKey = getRowKey(record, index);
    const disabled = rowSelection && rowSelection.disabled?.(record);
    const isSelectedRow = selectedRowKeys.includes(currentRowKey);
    const rowActions = propRowActions(record).map((action) => {
      if (action.type === OverflowMenu) {
        const props = action.props as OverflowMenuProps;
        const isBoundariesElementGiven = !!(
          props.popperProps &&
          props.popperProps.modifiers &&
          props.popperProps.modifiers.flip &&
          props.popperProps.modifiers.flip.boundariesElement
        );

        if (isBoundariesElementGiven) {
          return action;
        }

        const boundariesElement = tableElementRef.current || undefined;

        const popperProps: OverflowMenuProps['popperProps'] = (() => {
          const defaultPopperProps = {
            positionFixed: true,
            modifiers: {
              preventOverflow: { boundariesElement },
              flip: { boundariesElement },
            },
          };
          const { popperProps = defaultPopperProps } = props;

          if (popperProps.modifiers) {
            return {
              ...popperProps,
              modifiers: {
                ...popperProps.modifiers,
                flip: {
                  ...popperProps.modifiers.flip,
                  // Fixed option
                  boundariesElement,
                },
                preventOverflow: {
                  ...popperProps.modifiers.preventOverflow,
                  // Fixed option
                  boundariesElement,
                },
              },
            };
          }

          return popperProps;
        })();

        // Set default boundariesElement to flip dropdown menu properly.
        return cloneElement(action, { ...props, popperProps });
      }
      return action;
    });

    return (
      <TableRow
        key={currentRowKey}
        disabled={disabled === undefined ? false : disabled}
        isSelected={isSelectedRow}
        styles={rowStyles ? rowStyles(record, index) : null}
        {...(onRow ? onRow(record, index) : null)}
        onMouseEnter={() => {
          onMouseEnterTableRow?.(record);
        }}
        onMouseLeave={() => {
          onMouseLeaveTableRow?.(record);
        }}
      >
        {getColumns().map((column) => renderBodyCell(column, record, index))}
        {rowActions.length > 0 && <RowActionsContainer>{rowActions}</RowActionsContainer>}
      </TableRow>
    );
  };

  const onColumnTitleClick = useCallback(
    (column: TableColumnProps<TableRecord>) => () => {
      const { sorter, sortableOrder = 'both' } = column;
      if (!sorter) {
        return;
      }

      const isAlreadySortedByThisColumn = isColumnsEqual(sortColumn, column);
      const getSortOrder = () => {
        if (sortableOrder !== 'both') {
          return sortableOrder;
        }
        return isAlreadySortedByThisColumn && sortOrder ? getReverseSortOrder(sortOrder) : 'ascend';
      };

      if (!isAlreadySortedByThisColumn) {
        setSortColumn(column);
      }
      setSortOrder(getSortOrder());
    },
    [sortColumn, sortOrder],
  );

  const selectableRecords = useMemo(() => {
    if (!rowSelection) {
      return [];
    }
    const { getCheckboxProps } = rowSelection;
    if (!getCheckboxProps) {
      return dataSource;
    }

    // filter out rows with disabled checkboxes
    return dataSource.filter((record) => !getCheckboxProps(record).disabled);
  }, [dataSource, rowSelection]);

  const onSelectionAllChange = useCallback(() => {
    notifyNewSelection(selectedRowKeys.length > 0 ? [] : selectableRecords.map((record) => getRowKey(record)));
  }, [selectedRowKeys, selectableRecords, notifyNewSelection, getRowKey]);

  const renderTitleCell = (column: TableColumnProps<TableRecord>) => {
    const { title, dataIndex, sorter, key, align = 'left', width, flex } = column;
    let content: ReactNode = '';

    if (sorter) {
      content = (
        <>
          <ColumnTitle>{title}</ColumnTitle>
          <StyledSortOrderIndicator order={isColumnsEqual(sortColumn, column) ? sortOrder : undefined} />
        </>
      );
    } else {
      content = <ColumnTitle>{title}</ColumnTitle>;
    }

    return (
      <Cell
        as="th"
        key={key || dataIndex}
        align={align}
        width={width}
        flex={flex}
        data-is-selection-column={isSelectionColumn(column)}
        onClick={onColumnTitleClick(column)}
        isClickable={!!column.sorter}
        styles={column.styles}
      >
        {content}
      </Cell>
    );
  };

  const renderBatchActions = ({ columnLength }: { columnLength: number }) => {
    const selectedCount = selectedRowKeys.length;
    return (
      <BatchActionsContainer colSpan={columnLength}>
        <SelectedRowCount>{selectedCount} selected</SelectedRowCount>
        {batchActions &&
          batchActions.map((batchAction, index) => {
            const { label, ...buttonProps } = batchAction;
            const defaultBatchActionButtonProps: ButtonProps = {
              buttonType: 'primary',
              variant: 'ghost',
              size: 'small',
            };

            return (
              <Button {...defaultBatchActionButtonProps} {...buttonProps} key={index}>
                {label}
              </Button>
            );
          })}
      </BatchActionsContainer>
    );
  };

  const tableHeader = () => {
    if (rowSelection) {
      if (selectedRowKeys.length > 0) {
        return renderBatchActions({ columnLength: getColumns().length - 1 });
      }
      return getColumns().slice(1).map(renderTitleCell);
    }

    return getColumns().map(renderTitleCell);
  };

  const tableBody =
    sortedDataSource.length > 0 ? (
      sortedDataSource.map(renderRow)
    ) : (
      <EmptyViewWrapper>
        <td colSpan={columns.length}>{emptyView}</td>
      </EmptyViewWrapper>
    );

  return (
    <Container className={className}>
      <TableElement ref={tableElementRef} density={density} hasFooter={!!footer}>
        <thead>
          <tr>
            {rowSelection && (
              <Cell as="th" data-is-selection-column={true} align="center" width={undefined} flex={undefined}>
                <Checkbox
                  checked={selectableRecords.length > 0 && selectedRowKeys.length === selectableRecords.length}
                  disabled={dataSource.length === 0}
                  indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < selectableRecords.length}
                  onChange={onSelectionAllChange}
                />
              </Cell>
            )}
            {tableHeader()}
          </tr>
        </thead>
        {showScrollbars ? (
          <ScrollBar ref={scrollBarRef} as="tbody">
            {tableBody}
          </ScrollBar>
        ) : (
          <tbody>{tableBody}</tbody>
        )}
      </TableElement>
      {loading && <SpinnerFull transparent={true} zIndex={10} />}
      {footer && <FooterWrapper>{footer}</FooterWrapper>}
    </Container>
  );
}
