import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ToneAdjustmentFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 2a9 9 0 0 0-5.152 16.38A8.01 8.01 0 0 0 4.398 22h2.146a6.025 6.025 0 0 1 2.265-2.582A8.98 8.98 0 0 0 12 20a8.98 8.98 0 0 0 3.19-.582A6.024 6.024 0 0 1 17.457 22h2.146a8.01 8.01 0 0 0-2.45-3.62A9 9 0 0 0 12 2zm0 14a5.002 5.002 0 0 1-4.9-4h2.07a3.001 3.001 0 0 0 5.659 0h2.07a5.002 5.002 0 0 1-4.9 4zm-4-6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm8 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ToneAdjustmentFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ToneAdjustmentFilledSvg} {...props} />
));
ToneAdjustmentFilled.displayName = 'ToneAdjustmentFilled';
export default ToneAdjustmentFilled;
