import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ClevertapColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#F04444"
        d="M13.273 22c-.83 0-1.66-.096-2.457-.288a10.694 10.694 0 0 1-6.697-4.784C.994 11.89 2.526 5.257 7.564 2.132a.843.843 0 0 1 1.18.287.843.843 0 0 1-.287 1.18C4.215 6.246 2.94 11.796 5.556 16.036a9.011 9.011 0 0 0 5.613 4.019 9.01 9.01 0 0 0 6.826-1.117.843.843 0 0 1 1.18.287.843.843 0 0 1-.288 1.18C17.23 21.457 15.252 22 13.274 22h-.001zM21.2 22a.893.893 0 1 0 0-1.786.893.893 0 0 0 0 1.786z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ClevertapColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ClevertapColoredSvg} {...props} />
));
ClevertapColored.displayName = 'ClevertapColored';
export default ClevertapColored;
