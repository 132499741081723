import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UsersSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M13.004 7a4 4 0 1 1-8 0 4 4 0 0 1 8 0zm-4 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
        clipRule="evenodd"
      />
      <path d="M9.004 14.5a6 6 0 0 0-6 6H3v.5H1v-.5h.004a8 8 0 1 1 16 0v.5h-2v-.5a6 6 0 0 0-6-6zM13.667 10.771a4 4 0 1 0 0-7.542c.43.532.772 1.14 1.001 1.8a2 2 0 1 1 0 3.944 5.99 5.99 0 0 1-1.001 1.798zM17.332 14.968A10.058 10.058 0 0 0 15 12.5h.003a8 8 0 0 1 8 8v.5h-2v-.5a6.002 6.002 0 0 0-3.672-5.532z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Users = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UsersSvg} {...props} />
));
Users.displayName = 'Users';
export default Users;
