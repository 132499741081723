import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TextStrikethroughSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M3.807 10.815C3.971 12.682 5.565 14 8.034 14c2.515 0 4.164-1.334 4.164-3.479v-.007c0-.75-.192-1.358-.62-1.847h1.755V7.333h-4.57l-1.096-.25c-1.532-.342-2.149-.961-2.149-1.875v-.007c0-1.2 1.086-1.875 2.492-1.883 1.47-.008 2.407.739 2.563 1.748l.015.103h1.407l-.008-.111C11.87 3.35 10.299 2 8.049 2 5.721 2 4.09 3.342 4.081 5.24v.008c0 .852.257 1.544.812 2.085H2.666v1.334h5.522l.377.084c1.547.349 2.195 1 2.195 1.953v.008c0 1.168-1.117 1.97-2.648 1.97-1.625 0-2.797-.723-2.898-1.867l-.008-.087H3.799l.008.087z" />
    </svg>
  )),
  { width: 16, height: 16 },
);
const TextStrikethrough = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TextStrikethroughSvg} {...props} />
));
TextStrikethrough.displayName = 'TextStrikethrough';
export default TextStrikethrough;
