import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const FileDuotoneSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g id="Icon" fillRule="evenodd" strokeWidth={1}>
        <g id="Icon-/-File-/-file/-duotone-/-24" fillRule="nonzero">
          <path
            id="Color_02"
            d="M13.975 1h-7.9C4.36 1 3 2.372 3 4.032v15.936C3 21.628 4.36 23 6.075 23h11.85C19.64 23 21 21.628 21 19.968V8.016a1.01 1.01 0 0 0-.297-.711l-5.926-5.976A1.13 1.13 0 0 0 13.975 1z"
          />
          <path
            id="Color_01"
            d="m13.337 3.341 5.318 5.318a.2.2 0 0 1-.142.341h-5.017a.5.5 0 0 1-.5-.5V3.483a.2.2 0 0 1 .341-.142z"
          />
        </g>
      </g>
    </svg>
  )),
  { width: 24, height: 24 },
);
const FileDuotone = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={FileDuotoneSvg} {...props} />
));
FileDuotone.displayName = 'FileDuotone';
export default FileDuotone;
