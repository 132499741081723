import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const SparklesGradient = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      width="32"
      height="32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}

      <path
        d="m25.475 10.666 1.053-2.333 2.334-1.054a.667.667 0 0 0 0-1.213l-2.333-1.053-1.054-2.347a.667.667 0 0 0-1.213 0L23.209 5l-2.347 1.054a.667.667 0 0 0 0 1.213l2.333 1.053 1.053 2.347c.24.52.987.52 1.227 0zm-10.613 2-2.12-4.667c-.467-1.04-1.96-1.04-2.427 0l-2.12 4.667-4.666 2.12c-1.04.48-1.04 1.96 0 2.427l4.666 2.12 2.12 4.666c.48 1.04 1.96 1.04 2.427 0l2.12-4.666 4.667-2.12c1.04-.48 1.04-1.96 0-2.427l-4.667-2.12zm9.386 8.667-1.053 2.333-2.333 1.053a.667.667 0 0 0 0 1.214l2.333 1.053 1.053 2.347c.24.52.974.52 1.214 0l1.053-2.334 2.347-1.053a.667.667 0 0 0 0-1.213l-2.333-1.054-1.054-2.346a.677.677 0 0 0-1.227 0z"
        fill="url(#a)"
      />
      <defs>
        <linearGradient id="a" x1="16" y1="2.276" x2="16" y2="29.723" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6210CC" />
          <stop offset="1" stopColor="#7092FB" />
        </linearGradient>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const Sparkles = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={SparklesGradient} {...props} />
));
Sparkles.displayName = 'SparklesGradient';
export default SparklesGradient;
