import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UpgradeColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="#6210CC" fillRule="evenodd" d="M12 1.586 22.414 12H16.5v6h-9v-6H1.586L12 1.586z" clipRule="evenodd" />
      <path fill="#0D0D0D" fillRule="evenodd" d="M17 22H7v-2h10v2z" clipRule="evenodd" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const UpgradeColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UpgradeColoredSvg} {...props} />
));
UpgradeColored.displayName = 'UpgradeColored';
export default UpgradeColored;
