import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ErrorDuotoneSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g id="Icon" fillRule="evenodd" strokeWidth={1}>
        <g id="Icon-/-Status-/-error-/-duotone-/-24" fillRule="nonzero">
          <path
            id="Color_02"
            d="m13.36 1.075 9.555 9.555a1.93 1.93 0 0 1 0 2.73l-9.555 9.555a1.93 1.93 0 0 1-2.73 0L1.075 13.36a1.93 1.93 0 0 1 0-2.73l9.555-9.555a1.93 1.93 0 0 1 2.73 0z"
          />
          <path
            id="Color_01"
            d="M13 13c0 1.333-2 1.333-2 0V8c0-1.333 2-1.333 2 0v5zm0 4c0-1.333-2-1.333-2 0s2 1.333 2 0z"
          />
        </g>
      </g>
    </svg>
  )),
  { width: 24, height: 24 },
);
const ErrorDuotone = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ErrorDuotoneSvg} {...props} />
));
ErrorDuotone.displayName = 'ErrorDuotone';
export default ErrorDuotone;
