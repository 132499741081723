import type { HTMLAttributes, MouseEventHandler, ReactNode, Ref } from 'react';
import { forwardRef, useLayoutEffect, useRef, useState } from 'react';

import type { Interpolation, ThemedStyledProps } from 'styled-components';
import styled, { css } from 'styled-components';

import type { AvatarProps } from '@feather/components/avatar';
import { Avatar } from '@feather/components/avatar';
import * as Icons from '@feather/components/icons';
import type { FeatherIconComponent } from '@feather/components/icons/types';
import type { TooltipProps, TooltipRef } from '@feather/components/tooltip';
import { Tooltip, TooltipVariant } from '@feather/components/tooltip';
import cssVariables from '@feather/theme/cssVariables';
import { Typography } from '@feather/typography';

export enum TagVariant {
  Light = 'light',
  Dark = 'dark',
}

type TagSize = 'large' | 'medium' | 'small';

export type TagProps = {
  maxWidth?: number;
  variant?: TagVariant;
  size?: TagSize;
  icon?: FeatherIconComponent;
  rounded?: boolean;
  onRemove?: MouseEventHandler<HTMLButtonElement>;
  tooltipMaxWidth?: number;
  tooltipProps?: Partial<Omit<TooltipProps, 'content' | 'children'>> & { ref?: Ref<TooltipRef> };

  /**
   * If true, the tooltip won't be displayed even if the content is ellipsized.
   */
  disableTooltip?: boolean;

  /**
   * If true, this tag will change its color when hovered.
   */
  isInteractive?: boolean;
} & HTMLAttributes<HTMLDivElement>;

type AvatarTagProps = Omit<TagProps, 'icon'> & { avatar: AvatarProps };

type TemplateProps = {
  variant: TagVariant;
  size: TagSize;
  icon?: ReactNode;
} & Omit<TagProps, 'icon'>;

type ContainerProps = TooltipProps &
  Pick<TagProps, 'tooltipMaxWidth' | 'size' | 'rounded' | 'isInteractive'> & {
    withRemoveButton: boolean;
    tagVariant: TagVariant;
  };

const iconSizes = { small: 12, medium: 16, large: 20 };
const TagIcon = styled(
  ({ icon: Icon, size, className }: { icon: FeatherIconComponent; size: TagSize; className?: string }) => {
    return (
      <Icon
        className={className}
        size={iconSizes[size]}
        color={cssVariables('neutral-6')}
        style={{
          flex: 'none', // prevent icon from being shrinked
        }}
      />
    );
  },
)``;

const Remove = styled(
  ({ size, className, onClick }: { size: TagSize; onClick: TagProps['onRemove']; className?: string }) => {
    const sizes = { small: 12, medium: 12, large: 16 };
    return (
      <button type="button" className={className} onClick={onClick}>
        <Icons.Close size={sizes[size]} color={cssVariables('neutral-6')} />
      </button>
    );
  },
)`
  flex: none; // prevent button from being shrinked
  padding: 2px;
  outline: 0;
  border: 0;
  cursor: pointer;
  border-radius: 2px;
  background: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AvatarIcon = styled(Avatar)`
  flex: none; // prevent icon from being shrinked
`;

const Text = styled.span`
  flex: 1;
  min-width: 0; // ignore intrinsic content size to ellipsize text if necessary
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${Typography['label-02']};
  line-height: 16px; // prevent tails (of g, y, p, q) from being clipped

  text-align: center;
`;

const Wrapper = styled.div<{ maxWidth: number }>`
  display: inline-flex;
  align-items: center;
  outline: none;
  max-width: ${({ maxWidth }) => maxWidth}px;

  & + & {
    margin-left: 4px;
  }
`;

const themes: Record<TagVariant, Interpolation<ThemedStyledProps<ContainerProps, {}>>> = {
  [TagVariant.Light]: css<ContainerProps>`
    border: 1px solid ${cssVariables('border-2')};
    color: ${cssVariables('content-2')};

    ${({ isInteractive }) =>
      isInteractive &&
      css`
        &:hover {
          border-color: ${cssVariables('purple-7')};
        }
      `}

    ${Remove} {
      &:hover,
      &:focus {
        background-color: ${cssVariables('neutral-2')};
      }
    }
  `,
  [TagVariant.Dark]: css<ContainerProps>`
    background: ${cssVariables('neutral-2')};
    border: 1px solid ${cssVariables('neutral-2')};
    color: ${cssVariables('neutral-7')};

    ${({ isInteractive }) =>
      isInteractive &&
      css`
        &:hover {
          background: ${cssVariables('neutral-3')};
          border-color: ${cssVariables('neutral-3')};
        }
      `}

    ${Remove} {
      &:hover,
      &:focus {
        background: ${cssVariables('neutral-3')};
      }
    }
  `,
};

const TooltipContainer = styled(Tooltip)<ContainerProps>`
  display: inline-flex;
  align-items: center;
  padding: 0px 7px 1px;

  // if the parent has a specific width, must be placed inside it.
  max-width: 100%;
  min-height: 20px;

  ${(props) => themes[props.tagVariant]}

  ${Wrapper}:focus & {
    background-color: ${cssVariables('purple-2')};
    border-color: ${cssVariables('purple-7')};
  }

  ${TagIcon} {
    margin-right: 4px;
    margin-left: -3px;
  }

  ${AvatarIcon} {
    margin-right: 4px;
    margin-left: -6px;
  }

  ${(props) =>
    props.rounded
      ? css`
          &,
          ${Remove} {
            border-radius: 20px;
          }
        `
      : css`
          border-radius: ${props.withRemoveButton ? 3 : 2}px;
        `}

  ${Remove} {
    margin-right: -6px;
    margin-left: 4px;
  }
`;

const ContentWrapper = styled.div`
  max-width: 100%;
`;

const TagTemplate = forwardRef<HTMLDivElement, TemplateProps>(
  (
    {
      children,
      size,
      icon,
      variant,
      maxWidth = 240,
      tooltipMaxWidth = maxWidth,
      disableTooltip = false,
      tooltipProps,
      rounded,
      onRemove,
      className,
      isInteractive: isInteractiveProp = false,
      ...props
    },
    ref,
  ) => {
    const withRemoveButton = !!onRemove;
    const isInteractive = isInteractiveProp || !!props.onClick;

    const [width, setWidth] = useState(-1);
    const contentRef = useRef<HTMLDivElement>(null);

    /* track content width */
    useLayoutEffect(() => {
      const contentWidth = contentRef.current?.getBoundingClientRect().width ?? -1;
      if (width !== contentWidth) {
        setWidth(contentWidth);
      }
    }, [width, children, icon, size]);

    const isOverflow = width >= maxWidth - 16;

    return (
      <Wrapper tabIndex={isInteractive ? 0 : undefined} ref={ref} maxWidth={maxWidth} {...props}>
        <ContentWrapper ref={contentRef}>
          <TooltipContainer
            className={className}
            variant={TooltipVariant.Light}
            tagVariant={variant}
            size={size}
            rounded={rounded}
            withRemoveButton={withRemoveButton}
            disabled={disableTooltip || !isOverflow}
            content={children}
            isInteractive={isInteractive}
            placement="top"
            popperProps={{ modifiers: { offset: { offset: '0, 10' } } }}
            {...tooltipProps}
            tooltipContentStyle={css`
              max-width: ${tooltipMaxWidth}px;

              // make long tag contents without whitespaces wrap
              word-break: break-word;

              ${tooltipProps?.tooltipContentStyle}
            `}
          >
            {icon}
            <Text>{children}</Text>
            {withRemoveButton && <Remove size={size} onClick={onRemove} />}
          </TooltipContainer>
        </ContentWrapper>
      </Wrapper>
    );
  },
);

export const Tag = styled(
  forwardRef<HTMLDivElement, TagProps>(
    ({ children, size = 'small', variant = TagVariant.Light, icon, ...props }, ref) => {
      return (
        <TagTemplate
          {...props}
          size={size}
          variant={variant}
          icon={icon && <TagIcon icon={icon} size={size} />}
          ref={ref}
        >
          {children}
        </TagTemplate>
      );
    },
  ),
)``;

export const AvatarTag = styled(
  forwardRef<HTMLDivElement, AvatarTagProps>(
    ({ children, size = 'medium', rounded = true, variant = TagVariant.Dark, avatar, ...props }, ref) => {
      return (
        <TagTemplate
          {...props}
          size={size}
          variant={variant}
          rounded={rounded}
          icon={<AvatarIcon {...avatar} />}
          ref={ref}
        >
          {children}
        </TagTemplate>
      );
    },
  ),
)``;
