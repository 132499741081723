import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TableViewSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M1.998 3v18H19.5a2.5 2.5 0 0 0 2.5-2.5V3H1.998zm2 5V5H20v3h-4V5h-2v3h-4V5H8v3H3.998zm0 2v9H8v-9H3.998zM10 19h4v-9h-4v9zm6 0h3.5a.5.5 0 0 0 .5-.5V10h-4v9z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const TableView = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TableViewSvg} {...props} />
));
TableView.displayName = 'TableView';
export default TableView;
