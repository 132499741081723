import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const InstagramSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M7 12a5 5 0 1 1 10 0 5 5 0 0 1-10 0zm1.875 0A3.13 3.13 0 0 0 12 15.125 3.13 3.13 0 0 0 15.125 12 3.129 3.129 0 0 0 12 8.875 3.129 3.129 0 0 0 8.875 12z"
        clipRule="evenodd"
      />
      <path d="M17 8.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5z" />
      <path
        fillRule="evenodd"
        d="M8.25 2h7.5A6.25 6.25 0 0 1 22 8.25v7.5A6.25 6.25 0 0 1 15.75 22h-7.5A6.25 6.25 0 0 1 2 15.75v-7.5A6.25 6.25 0 0 1 8.25 2zm7.5 18.125a4.38 4.38 0 0 0 4.375-4.375v-7.5a4.38 4.38 0 0 0-4.375-4.375h-7.5A4.38 4.38 0 0 0 3.875 8.25v7.5a4.38 4.38 0 0 0 4.375 4.375h7.5z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Instagram = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={InstagramSvg} {...props} />
));
Instagram.displayName = 'Instagram';
export default Instagram;
