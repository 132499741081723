import type { ChangeEvent, ChangeEventHandler, Dispatch, FormEventHandler, RefObject, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { InputSelectItem } from '.';
import type { Dropdown } from '../dropdown';
import type { HTMLToggleElement } from '../toggle';

type FieldValue = string | boolean | InputSelectItem;
type FieldElement = HTMLInputElement | HTMLTextAreaElement | HTMLToggleElement | typeof Dropdown;

enum FieldRefValueType {
  Text,
  Checkbox,
  Toggle,
  Else,
}

interface FormError {
  hasError: boolean;
  message: string;
}

export type Field<T extends FieldValue, E extends FieldElement = HTMLInputElement> = {
  isControlled: boolean;
  ref: RefObject<E>;
  name: string;
  placeholder?: string;
  value: T;
  error: FormError;
  updatable: boolean;
  setUpdatable: Dispatch<SetStateAction<boolean>>;
  setInitialValue: Dispatch<SetStateAction<T>>;
  setValidationError: Dispatch<SetStateAction<FormError>>;
  setServerError: Dispatch<SetStateAction<string>>;
  getValue: () => T;
  updateValue: (value: T) => void;
  validate?: (value: T) => string;
  reset: () => void;

  /**
   * Pass this property as onChange prop of form control elements so that ChangeEvent will be passed as an argument of
   * the function. Don't pass this property as onChange prop of either a Dropdown or a Toggle component because it
   * passes the selected value or null as the first argument. Use updateValue property for them.
   */
  onChange: ChangeEventHandler<E>;
};

export type Form = {
  onSubmit: FormEventHandler<HTMLFormElement>;
  addField: <T extends FieldValue, E extends FieldElement = HTMLInputElement>(field: Field<T, E>) => void;
  reset: () => void;
  disabled: boolean;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  onSuccess: () => void;
};

type UseFieldOptions<T extends FieldValue> = {
  defaultValue: T;
  placeholder?: string;
  validate?: Field<T, any>['validate'];
  isControlled?: boolean;
  onChange?: (value: T) => void;
};

const isChangeEvent = (event: any): event is ChangeEvent<HTMLInputElement | HTMLTextAreaElement> => {
  return event && event.target && typeof event.target.value === 'string';
};

const isEventTargetCheckbox = (eventTarget: any): eventTarget is HTMLInputElement => {
  return eventTarget.type === 'checkbox';
};

export const useField = <T extends FieldValue, E extends FieldElement = HTMLInputElement>(
  name: string,
  form: Form,
  options: UseFieldOptions<T>,
): Field<T, E> => {
  const { defaultValue, placeholder, validate, isControlled = false, onChange: optionsOnChange } = options;

  const [initialValue, setInitialValue] = useState<T>(defaultValue);
  const [value, setValue] = useState<T>(defaultValue);
  const [updatable, setUpdatable] = useState(false);
  const [serverError, setServerError] = useState('');
  const [validationError, setValidationError] = useState<FormError>({
    hasError: false,
    message: '',
  });

  const ref = useRef<E>(null);
  const onServerErrorChange = useRef<() => void>(() => {});

  const setRefValue = useCallback((value: T) => {
    if (!ref.current) {
      return;
    }

    if (typeof value === 'boolean') {
      if (typeof (ref.current as HTMLInputElement).getAttribute('checked') === 'boolean') {
        (ref.current as HTMLInputElement).setAttribute('checked', value.toString());
      }
      if ((ref.current as HTMLToggleElement).getAttribute('role') === 'switch') {
        (ref.current as HTMLToggleElement).setAttribute('aria-checked', value.toString());
      }
    } else {
      (ref.current as HTMLInputElement | HTMLTextAreaElement).value = value as string;
    }
  }, []);

  const refValueType: FieldRefValueType = (() => {
    if (ref.current && (ref.current as HTMLToggleElement).getAttribute('role') === 'switch') {
      return FieldRefValueType.Toggle;
    }

    if (typeof value === 'boolean') {
      return FieldRefValueType.Checkbox;
    }

    if (typeof value === 'string') {
      return FieldRefValueType.Text;
    }

    return FieldRefValueType.Else;
  })();

  const getValue = useCallback(() => {
    const getRefValue = () => {
      let currentRefValue;

      if (!ref.current) {
        return refValueType === FieldRefValueType.Toggle || refValueType === FieldRefValueType.Checkbox ? false : '';
      }

      if (refValueType === FieldRefValueType.Toggle) {
        currentRefValue = (ref.current as HTMLToggleElement).getAttribute('aria-checked') === 'true';
      }

      if (refValueType === FieldRefValueType.Checkbox) {
        currentRefValue = (ref.current as HTMLInputElement).getAttribute('checked');
      }

      if (refValueType === FieldRefValueType.Text) {
        currentRefValue = (ref.current as HTMLInputElement | HTMLTextAreaElement).value;
      }

      return currentRefValue;
    };

    return isControlled ? value : getRefValue();
  }, [isControlled, value, refValueType]);

  useEffect(() => {
    setRefValue(defaultValue);
    // setValue(defaultValue);
    // setUpdatable(false);
    // setInitialValue(defaultValue);
  }, [setRefValue, defaultValue]);

  // when serverError occurs, updatable should be reverted to its previous value (right before form.onSubmit)
  useEffect(() => {
    onServerErrorChange.current = () => {
      const prevUpdatable = defaultValue !== getValue();
      setUpdatable(prevUpdatable);
    };
  }, [defaultValue, getValue]);

  useEffect(() => {
    onServerErrorChange.current();
  }, [serverError]);

  const error: FormError = useMemo(
    () =>
      validationError || {
        hasError: !!serverError,
        message: serverError || '',
      },
    [serverError, validationError],
  );

  const field: Field<T, E> = useMemo(() => {
    const validateOnChange = (value: T) => {
      const error = validate ? validate(value) : null;
      if (error) {
        if (validationError.message !== error || !validationError.hasError) {
          setValidationError({ hasError: true, message: error });
        }
      } else {
        if (validationError.hasError) {
          setValidationError({ ...validationError, hasError: false });
        }
      }
    };

    const onChange: Field<T, E>['onChange'] = (event) => {
      if (!isChangeEvent(event)) {
        return;
      }

      const newValue = (isEventTargetCheckbox(event.target) ? event.target.checked : event.target.value) as T;

      const nextUpdatable = initialValue !== newValue;
      if (nextUpdatable !== updatable) {
        setUpdatable(nextUpdatable);
      }

      if (isControlled) {
        setValue(newValue);
      }

      optionsOnChange && optionsOnChange(newValue);
      validateOnChange(newValue);
    };

    return {
      isControlled,
      ref,
      name,
      value,
      placeholder,
      error,
      updatable,
      setUpdatable,
      setInitialValue,
      setValidationError,
      setServerError,
      getValue,
      validate,
      reset: () => {
        if (isControlled) {
          setValue(defaultValue);
        }
        setRefValue(defaultValue);
        setUpdatable(false);
        setValidationError({ hasError: false, message: '' });
        setServerError('');
      },
      onChange,
      updateValue: (value) => {
        if (isControlled) {
          setValue(value);
        }
        setRefValue(value);

        if (!!value && typeof value === 'object') {
          const nextUpdatable = (value as InputSelectItem).value !== (initialValue as InputSelectItem).value;
          if (nextUpdatable !== updatable) {
            setUpdatable(nextUpdatable);
          }
        } else {
          const nextUpdatable = initialValue !== value;
          if (nextUpdatable !== updatable) {
            setUpdatable(nextUpdatable);
          }
        }

        validateOnChange(value);
      },
    };
  }, [
    defaultValue,
    error,
    getValue,
    initialValue,
    isControlled,
    name,
    optionsOnChange,
    placeholder,
    updatable,
    validate,
    validationError,
    value,
    setRefValue,
  ]);

  form.addField(field);
  return field;
};

export const useForm = (options: {
  onSubmit: (formData: { [key: string]: any }) => void;
  onReset?: () => void;
}): Form => {
  const { onSubmit, onReset } = options;
  const onSubmitRef = useRef<typeof onSubmit>(onSubmit);
  const onResetRef = useRef<typeof onReset>(onReset);
  const fields = useRef<Field<any, any>[]>([]);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    onSubmitRef.current = onSubmit;
  }, [onSubmit]);

  useEffect(() => {
    onResetRef.current = onReset;
  }, [onReset]);

  return useMemo(() => {
    return {
      addField: (field) => fields.current.push(field),
      onSubmit: (e) => {
        e && e.preventDefault();

        const [formData, firstInvalidField] = fields.current.reduce<
          [{ [key: string]: string }, Field<any, any> | null]
        >(
          ([accFormData, accFirstInvalidField], field) => {
            const value = field.getValue();
            accFormData[field.name] = value;

            const error = field.validate ? field.validate(value) : null;
            if (error) {
              field.setValidationError({ hasError: true, message: error });
              return [accFormData, accFirstInvalidField || field];
            }

            return [accFormData, accFirstInvalidField];
          },
          [{}, null],
        );

        if (firstInvalidField) {
          if (firstInvalidField.ref.current) {
            firstInvalidField.ref.current.focus();
          }
          return;
        }

        onSubmitRef.current(formData);
      },
      onSuccess: () => {
        fields.current.forEach((field) => {
          field.setInitialValue(field.getValue());
          field.setUpdatable(false);
        });
      },
      reset: () => {
        fields.current.forEach((field) => field.reset());
        onResetRef.current && onResetRef.current();
      },
      disabled,
      setDisabled,
    };
  }, [disabled, fields]);
};
