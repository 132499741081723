import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AttachSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12.665 4.43a4.882 4.882 0 1 1 6.905 6.905l-5.686 5.686-2.038-2.038a3 3 0 0 1 0-4.243l3.547-3.548 1.415 1.415-3.548 3.547a1 1 0 0 0 0 1.414l.624.624 4.272-4.272a2.882 2.882 0 1 0-4.076-4.076L5.844 14.08a2.882 2.882 0 1 0 4.076 4.076l1.575-1.575 1.415 1.414-1.575 1.575a4.882 4.882 0 1 1-6.905-6.905l8.236-8.235z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Attach = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AttachSvg} {...props} />
));
Attach.displayName = 'Attach';
export default Attach;
