import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const VendorWordpressSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M14.311 6.55a7.188 7.188 0 0 1-2.696 9.667l2.198-6.352c.41-1.026.547-1.847.547-2.576 0-.265-.018-.51-.049-.74zm-5.32.077c.433-.022.823-.068.823-.068.388-.046.342-.615-.046-.593 0 0-1.165.092-1.917.092-.706 0-1.894-.092-1.894-.092-.388-.022-.433.57-.046.593 0 0 .367.046.755.068l1.12 3.07-1.574 4.722-2.619-7.792c.433-.022.823-.068.823-.068.387-.046.342-.615-.046-.593 0 0-1.165.092-1.917.092-.135 0-.294-.003-.463-.009A7.184 7.184 0 0 1 8 2.807c1.872 0 3.577.716 4.857 1.889-.031-.002-.061-.006-.093-.006-.707 0-1.208.615-1.208 1.276 0 .593.342 1.095.706 1.687.274.48.593 1.095.593 1.984 0 .615-.236 1.33-.547 2.325l-.717 2.397-2.6-7.732zM8 17.193a7.188 7.188 0 0 1-2.032-.293l2.158-6.271 2.21 6.057a.676.676 0 0 0 .052.099A7.177 7.177 0 0 1 8 17.193zM.807 10c0-1.043.224-2.033.623-2.927l3.431 9.4A7.193 7.193 0 0 1 .807 10zM8 2c-4.411 0-8 3.589-8 8 0 4.41 3.589 8 8 8 4.41 0 8-3.59 8-8 0-4.411-3.59-8-8-8z" />
    </svg>
  )),
  { width: 16, height: 20 },
);
const VendorWordpress = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={VendorWordpressSvg} {...props} />
));
VendorWordpress.displayName = 'VendorWordpress';
export default VendorWordpress;
