import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrModerateSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-mo-a)">
        <path d="M3 19.5H17V21H3z" />
        <path d="m8.718 18.324 2.12-2.121-.706-.707 2.475-2.475 7.983 7.983 1.414-1.414-7.983-7.983 2.475-2.475.707.707 2.121-2.121L12.607 1l-2.122 2.121.707.707-6.364 6.364-.707-.707L2 11.607l6.718 6.717z" />
      </g>
      <defs>
        <clipPath id="br-mo-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrModerate = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrModerateSvg} {...props} />
));
BrModerate.displayName = 'BrModerate';
export default BrModerate;
