import { forwardRef, useContext, useMemo } from 'react';

import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

import { transitionDefault } from '@feather/animation';
import type { FeatherIconComponent } from '@feather/components/icons/types';
import cssVariables from '@feather/theme/cssVariables';
import { FeatherThemeContext, FeatherThemeEnum } from '@feather/themes';
import type { ButtonProps, ButtonSize, ButtonType, ButtonVariant } from '@feather/types';

import { Spinner } from '../spinner';
import * as hideOutlineEventListeners from './hideOutlineEventListeners';
import { generateButtonContainerStyle, generateButtonSizeStyle, getContainerGeneratorOptions } from './styleGenerators';

type StyledButtonProps = {
  isLoading: boolean;
  size: ButtonSize;
  variant: ButtonVariant;
  buttonType?: ButtonType;
  hasIcon?: boolean;
  iconPosition?: 'left' | 'right';
  styles?: SimpleInterpolation;
  theme?: FeatherThemeEnum;
};

type ButtonWithIconStyles = {
  [key in ButtonSize]: { size: number; iconOppositeSideMargin: number; iconSidePadding: number };
};

interface GetSpinnerSize {
  (size: ButtonSize): number;
}

const buttonSizeIconStyleMap: ButtonWithIconStyles = {
  xsmall: { size: 12, iconOppositeSideMargin: 2, iconSidePadding: 4 },
  small: { size: 20, iconOppositeSideMargin: 8, iconSidePadding: 12 },
  medium: { size: 20, iconOppositeSideMargin: 8, iconSidePadding: 12 },
  large: { size: 24, iconOppositeSideMargin: 8, iconSidePadding: 16 },
} as const;

function getButtonWithIconStyles(options: { size: ButtonSize; isGhostButton?: boolean }) {
  const { size, isGhostButton = false } = options;
  const { iconSidePadding, iconOppositeSideMargin, size: iconSize } = buttonSizeIconStyleMap[size];
  return {
    size: iconSize,
    iconGap: isGhostButton ? 4 : iconOppositeSideMargin,
    iconSidePadding: iconSidePadding - (isGhostButton ? 4 : 0),
  };
}

const getSpinnerSize: GetSpinnerSize = (size) => {
  return size === 'large' ? 24 : 20;
};

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled.button<StyledButtonProps>`
  &:disabled {
    cursor: ${(props) => (props.disabled && !props.isLoading ? 'not-allowed' : 'progress')};
  }

  ${(props) => generateButtonSizeStyle({ size: props.size, isGhostButton: props.variant === 'ghost' })}
  ${({ theme, variant, buttonType }) =>
    generateButtonContainerStyle(getContainerGeneratorOptions({ theme, variant, buttonType }))}
  ${(props) =>
    props.isLoading &&
    css`
      color: transparent !important;

      ${IconWrapper} svg {
        fill: transparent !important;
      }
    `}
  
  ${(props) => props.styles};

  ${({ theme, size, hasIcon, iconPosition, variant, buttonType }) => {
    if (hasIcon) {
      const {
        size: iconSize,
        iconGap,
        iconSidePadding,
      } = getButtonWithIconStyles({
        size,
        isGhostButton: variant === 'ghost',
      });
      const { contentColor, hoverContentColor, activeContentColor, disabledContentColor } =
        getContainerGeneratorOptions({
          theme,
          variant,
          buttonType,
        });

      return css`
        justify-content: space-between;
        ${iconPosition === 'left'
          ? css`
              padding-left: ${iconSidePadding}px;
            `
          : css`
              padding-right: ${iconSidePadding}px;
            `}

        ${IconWrapper} {
          ${iconPosition === 'left'
            ? css`
                margin-right: ${iconGap}px;
              `
            : css`
                margin-left: ${iconGap}px;
              `}
          width: ${iconSize}px;
          height: ${iconSize}px;

          svg {
            fill: ${contentColor};
            transition: fill 0.2s ${transitionDefault};
          }
        }

        :hover ${IconWrapper} svg {
          fill: ${hoverContentColor};
        }

        :active ${IconWrapper} svg {
          fill: ${activeContentColor};
        }

        :disabled ${IconWrapper} svg {
          fill: ${disabledContentColor};
        }
      `;
    }
  }}
`;

const getSpinnerStroke = (options: { theme: FeatherThemeEnum; buttonType: ButtonType; variant: ButtonVariant }) => {
  const { buttonType, theme, variant } = options;
  switch (buttonType) {
    case 'primary':
      switch (theme) {
        case FeatherThemeEnum.Neutral:
          return cssVariables('content-2');
        default:
          return variant === 'ghost' ? cssVariables('bg-primary') : 'white';
      }
    case 'secondary':
      switch (theme) {
        case FeatherThemeEnum.Neutral:
          return 'white';
        default:
          return cssVariables('bg-primary');
      }
    case 'danger':
      return variant === 'ghost' ? cssVariables('bg-primary') : 'white';
    case 'dangerTeritary':
      return cssVariables('red-5');
    default:
      return cssVariables('bg-primary');
  }
};

/**
 * An icon component is defined using `forwardRef` which results in an object tagged with
 * `Symbol.for('react.forward_ref')`.
 * @see https://overreacted.io/why-do-react-elements-have-typeof-property/
 * @see https://github.com/facebook/react/pull/4832
 *
 * @returns true if `icon` is a valid icon component
 */
const isIconComponent = (icon: ButtonProps['icon']): icon is FeatherIconComponent =>
  !!icon && icon['$$typeof'] === Symbol.for('react.forward_ref');
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      buttonType,
      variant = 'default',
      size = 'medium',
      icon,
      iconPosition = 'right',
      styles,
      isLoading = false,
      children,
      type = 'button',
      ...rest
    },
    ref,
  ) => {
    const theme = useContext(FeatherThemeContext);
    const spinnerStroke = getSpinnerStroke({ buttonType, theme, variant });

    // Ghost buttons only support medium and small sizes. Large ghost buttons fall back to medium sizes.
    const safeSize = variant === 'ghost' && size === 'large' ? 'medium' : size;

    const iconNode = useMemo(() => {
      if (!icon) {
        return null;
      }

      const { size: iconSize } = getButtonWithIconStyles({ size: safeSize, isGhostButton: variant === 'ghost' });

      if (isIconComponent(icon)) {
        const IconComponent = icon;
        return <IconComponent size={iconSize} />;
      }

      return icon;
    }, [icon, safeSize, variant]);

    return (
      <StyledButton
        type={type}
        theme={theme}
        size={safeSize}
        buttonType={buttonType}
        variant={variant}
        styles={styles}
        hasIcon={!!iconNode}
        iconPosition={iconPosition}
        isLoading={isLoading}
        data-is-loading={isLoading}
        {...hideOutlineEventListeners}
        {...rest}
        ref={ref}
      >
        {isLoading && (
          <SpinnerWrapper>
            <Spinner size={getSpinnerSize(safeSize)} stroke={spinnerStroke} />
          </SpinnerWrapper>
        )}
        <>
          {iconNode && iconPosition === 'left' && <IconWrapper>{iconNode}</IconWrapper>}
          {children}
          {iconNode && iconPosition === 'right' && <IconWrapper>{iconNode}</IconWrapper>}
        </>
      </StyledButton>
    );
  },
);

export * from './IconButton';
export * from './overlayButton';
export * from './styleGenerators';
