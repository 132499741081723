import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrCareersSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-ca-a)">
        <path d="M21.004 5h-4.5v4h-4.5v4h-4.5v3.5h-4.5V21h18V5z" />
        <path d="M10.947 4.496H9.013V3H13.5v4.51h-1.496V5.554L4.058 13.5 3 12.442l7.947-7.946z" />
      </g>
      <defs>
        <clipPath id="br-ca-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrCareers = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrCareersSvg} {...props} />
));
BrCareers.displayName = 'BrCareers';
export default BrCareers;
