import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LikeFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M16.736 3c-.82 0-1.63.157-2.389.463a6.274 6.274 0 0 0-2.031 1.324L12 5.095l-.316-.308A6.334 6.334 0 0 0 7.264 3c-1.653 0-3.244.64-4.42 1.786A6.064 6.064 0 0 0 1 9.13c0 1.634.666 3.196 1.844 4.343L12 22l9.156-8.527a6.12 6.12 0 0 0 1.364-1.989 6.016 6.016 0 0 0 0-4.708 6.122 6.122 0 0 0-1.364-1.99 6.273 6.273 0 0 0-2.031-1.323A6.382 6.382 0 0 0 16.735 3z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const LikeFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LikeFilledSvg} {...props} />
));
LikeFilled.displayName = 'LikeFilled';
export default LikeFilled;
