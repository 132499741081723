import type { HTMLAttributes, ReactNode } from 'react';

export enum SideMenuItemType {
  Default = 'default',
  WithCount = 'withCount',
  Expandable = 'expandable',
  Sub = 'sub',
}

export interface SideMenuItemBase {
  type: SideMenuItemType;
  key: string | number;
  label: string;
  labelSuffixNode?: ReactNode;
  href?: string;
  useReactRouterLink?: boolean;
  replace?: boolean;
  onClick?: () => void;
}

export interface SideMenuItemDefaultInterface extends SideMenuItemBase {
  type: SideMenuItemType.Default;
}

export interface SideMenuItemWithCountInterface extends SideMenuItemBase {
  type: SideMenuItemType.WithCount;
  count: number;
}

export interface SideMenuItemExpandableInterface extends SideMenuItemBase {
  type: SideMenuItemType.Expandable;
  parentKey?: string | number;
  isExpanded: boolean;
}

export interface SideMenuItemSubInterface extends SideMenuItemBase {
  type: SideMenuItemType.Sub;
  parentKey: string | number;
}

export type SideMenuItem =
  | SideMenuItemDefaultInterface
  | SideMenuItemWithCountInterface
  | SideMenuItemExpandableInterface
  | SideMenuItemSubInterface;

export type CreateSideMenuItem<T> = (params: Omit<T, 'type'>) => T;

export interface SideMenuProps extends Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'style'> {
  title?: string;
  titleAction?: ReactNode;
  items: SideMenuItem[];
  activeItemKey?: string | number;
}
