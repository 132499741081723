import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const HuaweiSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="m14.438 18.494 7.388.257c-1.659 2.83-3.565 2.186-3.565 2.186-1.032-.291-3.174-1.78-3.879-2.284-.043-.035-.028-.1-.028-.1.018-.05.084-.058.084-.058zm-4.887-.005c0 .003.078.003.097.069 0 0 .019.04-.036.09-.724.52-2.916 2.042-3.886 2.282-1.403.337-2.752-.765-3.551-2.185l7.376-.256zM.21 12.275s6.28 3.338 9.52 5.514c0 0 .063.051.025.109 0 0-.024.05-.073.05-1.072.005-4.885.02-5.464.006l-.071-.003s-.706-.02-1.411-.309c0 0-1.528-.509-2.328-2.193 0 0-.77-1.416-.198-3.174zm23.576-.017c.56 1.684-.163 3.124-.163 3.124-.812 1.766-2.376 2.26-2.376 2.26-.7.269-1.269.292-1.269.292-.111.019-4.507.015-5.666.014-.046-.001-.077-.058-.077-.058-.02-.068.04-.106.04-.106 3.24-2.177 9.511-5.526 9.511-5.526zM3.675 6.172s3.857 4.65 6.678 10.78c.02.054-.021.087-.021.087-.031.04-.085.024-.099.017-1.029-.507-5.46-2.748-7.293-4.524 0 0-1.135-.89-1.215-2.393-.15-1.985 1.95-3.967 1.95-3.967zm16.65 0s2.077 1.903 1.945 3.958c-.06 1.481-1.212 2.402-1.212 2.402-1.822 1.767-6.22 3.995-7.278 4.516-.048.03-.098-.009-.098-.009-.054-.046-.022-.116-.022-.116 2.82-6.12 6.665-10.751 6.665-10.751zM13.757 3c.597.085 1.16.226 1.16.226 1.957.474 2.438 2.266 2.438 2.266.34.939-.01 2.33-.01 2.33-.65 2.867-3.84 7.57-4.545 8.59-.002 0-.04.068-.109.04 0 0-.049-.013-.056-.081C11.57 5.802 13.757 3 13.757 3zm-3.509 0s2.175 2.801 1.114 13.364c0 0 .005.07-.07.09 0 0-.04.018-.087-.032-.69-.992-3.9-5.723-4.55-8.6 0 0-.367-1.22-.01-2.33 0 0 .461-1.764 2.415-2.266 0 0 .866-.197 1.188-.226z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Huawei = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={HuaweiSvg} {...props} />
));
Huawei.displayName = 'Huawei';
export default Huawei;
