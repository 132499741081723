import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrServerStatusSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-se-a)">
        <path d="M12.754 19.5V18h-1.5v1.5h-8.25V21h18v-1.5h-8.25z" />
        <path
          fillRule="evenodd"
          d="M3.004 3h18v4.5a2 2 0 0 1-2 2h-16V3zm4.25 4.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5zM3.004 10.5h18V15a2 2 0 0 1-2 2h-16v-6.5zm4.25 4.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="br-se-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrServerStatus = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrServerStatusSvg} {...props} />
));
BrServerStatus.displayName = 'BrServerStatus';
export default BrServerStatus;
