import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AichatbotHorizontalSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M46.688 13.718c0-3.017 2.273-5.29 5.27-5.29 2.541 0 4.69 1.736 4.959 4.071h-2.955c-.207-.744-1.033-1.281-2.005-1.281-1.384 0-2.417 1.054-2.417 2.5 0 1.447 1.033 2.48 2.417 2.48.972 0 1.819-.579 2.026-1.364h2.955c-.228 2.417-2.397 4.153-4.98 4.153-2.996 0-5.27-2.252-5.27-5.269zM67.868 13.036v5.745h-2.913v-5.373c0-1.322-.806-2.19-2.005-2.19-1.198 0-2.004.868-2.004 2.19v5.373h-2.914V4.759h2.914v5.198c.64-.971 1.59-1.529 2.748-1.529 2.604 0 4.174 1.735 4.174 4.608zM79.915 8.635V18.78h-2.914v-1.446c-.702 1.054-1.798 1.653-3.223 1.653-2.624 0-4.712-2.376-4.712-5.29 0-2.913 2.088-5.27 4.712-5.27 1.426 0 2.521.6 3.223 1.654V8.636l2.914-.001zm-2.914 5.062c0-1.405-1.116-2.5-2.541-2.5-1.426 0-2.542 1.116-2.542 2.5 0 1.385 1.136 2.5 2.542 2.5S77 15.124 77 13.698zM85.514 11.404v3.203c0 1.095.744 1.57 1.757 1.57.248 0 .516-.02.744-.082v2.686a6.365 6.365 0 0 1-1.406.166c-2.397 0-4.008-1.571-4.008-4.299v-3.244H80.72V8.635h1.88V5.97h2.914v2.666h2.5v2.769h-2.5zM100.041 13.697c0 2.914-2.087 5.29-4.711 5.29-1.405 0-2.5-.578-3.203-1.632v1.426h-2.914V4.759h2.914v5.302c.703-1.054 1.798-1.633 3.203-1.633 2.624 0 4.711 2.355 4.711 5.27zm-2.851 0a2.5 2.5 0 0 0-2.521-2.5c-1.406 0-2.542 1.095-2.542 2.5 0 1.406 1.116 2.5 2.542 2.5 1.426 0 2.52-1.094 2.52-2.5zM111.24 13.718c0 3.017-2.293 5.27-5.269 5.27s-5.27-2.253-5.27-5.27c0-3.017 2.273-5.29 5.27-5.29 2.996 0 5.269 2.273 5.269 5.29zm-7.687 0c0 1.405 1.033 2.48 2.418 2.48 1.384 0 2.418-1.075 2.418-2.48s-1.055-2.5-2.418-2.5c-1.364 0-2.418 1.054-2.418 2.5zM116.2 11.404v3.203c0 1.095.744 1.57 1.756 1.57.249 0 .517-.02.744-.082v2.686a6.358 6.358 0 0 1-1.405.166c-2.397 0-4.009-1.571-4.009-4.299v-3.244h-1.881V8.635h1.881V5.97h2.914v2.666h2.5v2.769h-2.5zM34.725 16.437h-5.58l-.93 2.344h-3.244L30.716 4.76h2.48L38.96 18.78h-3.327l-.91-2.344zm-1.013-2.544-1.756-4.487-1.798 4.487h3.554zM42.824 4.759V18.78h-3.058V4.76h3.058z" />
      <path
        fillRule="evenodd"
        d="M10.848 1.83a2.265 2.265 0 0 0-.904 4.342v1.539h1.808V6.172a2.265 2.265 0 0 0-.904-4.341zM.227 15.938v-5.424h1.807v5.424H.227zm19.435-5.424v5.424h1.808v-5.424h-1.808zm-3.164-2.802H2.938v10.396a2.26 2.26 0 0 0 2.26 2.26h11.3a2.26 2.26 0 0 0 2.26-2.26V9.97a2.26 2.26 0 0 0-2.26-2.26zm-7.654 4.045v2.938a.904.904 0 0 1-1.808 0v-2.938a.904.904 0 0 1 1.808 0zm5.861 0v2.938a.904.904 0 0 1-1.808 0v-2.938a.904.904 0 0 1 1.808 0z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 121, height: 24 },
);
const AichatbotHorizontal = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AichatbotHorizontalSvg} {...props} />
));
AichatbotHorizontal.displayName = 'AichatbotHorizontal';
export default AichatbotHorizontal;
