import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const EditFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m21.997 6.027-3.3-3.3a2.5 2.5 0 0 0-3.535 0L5.37 12.52l-1.367 6.434 6.434-1.367 11.56-11.56zM20 20H4v2h16v-2z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const EditFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={EditFilledSvg} {...props} />
));
EditFilled.displayName = 'EditFilled';
export default EditFilled;
