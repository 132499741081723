import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DocumentDeactivatedSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M11 13V7h2v6h-2zM12 17a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5z" />
      <path
        fillRule="evenodd"
        d="M4 2h10.51L20 7.657V19.5a2.5 2.5 0 0 1-2.5 2.5h-11A2.5 2.5 0 0 1 4 19.5V2zm2 2v15.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V8.468L13.665 4H6z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const DocumentDeactivated = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DocumentDeactivatedSvg} {...props} />
));
DocumentDeactivated.displayName = 'DocumentDeactivated';
export default DocumentDeactivated;
