import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const OpenInNewSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M17.586 5H14V3h7v7h-2V6.414l-8 8L9.586 13l8-8z" />
      <path d="M2.002 4h7.38v2h-5.38v13.5a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-4.576h2V19.5a2.5 2.5 0 0 1-2.5 2.5h-13a2.5 2.5 0 0 1-2.5-2.5V4z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const OpenInNew = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={OpenInNewSvg} {...props} />
));
OpenInNew.displayName = 'OpenInNew';
export default OpenInNew;
