import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LiveEventsFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path fillRule="evenodd" d="M4 20V5H2v17h17v-2H4z" clipRule="evenodd" />
      <path fillRule="evenodd" d="M22 4a2 2 0 0 0-2-2H6v16h16V4zm-10 9V7l5 3-5 3z" clipRule="evenodd" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const LiveEventsFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LiveEventsFilledSvg} {...props} />
));
LiveEventsFilled.displayName = 'LiveEventsFilled';
export default LiveEventsFilled;
