import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrChatSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-ch-a)">
        <path d="M4 3h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6l-4 4V5a2 2 0 0 1 2-2z" />
        <path d="m18.622 16.5 1.878 1.879V10c0-.653-.417-1.294-1-1.5V7c1.42.238 2.5 1.514 2.5 3v12l-4-4H5.996v-1.5h12.626z" />
      </g>
      <defs>
        <clipPath id="br-ch-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrChat = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrChatSvg} {...props} />
));
BrChat.displayName = 'BrChat';
export default BrChat;
