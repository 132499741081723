import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const JavascriptColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="#F7DF1E" d="M2 22h20V2H2v20z" />
      <path
        fill="#0D0D0D"
        d="M17.332 20.434c-1.745 0-2.874-.832-3.425-1.922l1.526-.887c.405.656.929 1.14 1.853 1.14.781 0 1.28-.39 1.28-.927 0-.645-.513-.872-1.37-1.246l-.47-.202c-1.356-.578-2.257-1.302-2.257-2.832 0-1.41 1.074-2.483 2.752-2.483 1.195 0 2.055.415 2.674 1.506l-1.463.939c-.323-.578-.67-.805-1.21-.805-.55 0-.9.348-.9.805 0 .564.35.793 1.157 1.141l.469.202c1.597.684 2.498 1.383 2.498 2.952 0 1.692-1.33 2.62-3.114 2.62zM12.892 17.597c0 1.947-1.141 2.833-2.807 2.833-1.507 0-2.375-.78-2.82-1.717l1.53-.924c.296.522.564.965 1.21.965.618 0 1.006-.242 1.006-1.18v-6.392h1.88v6.415z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const JavascriptColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={JavascriptColoredSvg} {...props} />
));
JavascriptColored.displayName = 'JavascriptColored';
export default JavascriptColored;
