import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UserAvatarFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M19.178 18.963A9.967 9.967 0 0 0 22 12c0-5.523-4.477-10-10-10S2 6.477 2 12a9.967 9.967 0 0 0 2.822 6.963A9.97 9.97 0 0 0 12 22a9.97 9.97 0 0 0 7.178-3.037zM5.963 17.25A7.981 7.981 0 0 1 12 14.5c2.41 0 4.57 1.065 6.037 2.75A7.981 7.981 0 0 1 12 20a7.981 7.981 0 0 1-6.037-2.75zM12 13a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const UserAvatarFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UserAvatarFilledSvg} {...props} />
));
UserAvatarFilled.displayName = 'UserAvatarFilled';
export default UserAvatarFilled;
